import React, { useState } from "react";
import TaskItem from "./TaskItem";
import { Box, Button, Grid2 } from "@mui/material";
import classNames from "classnames";
import { classes } from "../App.Styles";
import { ITaskInstance } from "../Interfaces/ITaskInstance";

interface IProps {
  tasks: ITaskInstance[];
  onChange: () => void;
}

const Tasks: React.FC<IProps> = ({ tasks, onChange }) => {
  const [initialTasksToShow, setInitialTasksToShow] = useState(10);
  const [tasksToFetch] = useState(10);

  if (tasks.length === 0) {
    return (
      <Box textAlign={"center"}>
        <p>No tasks to display.</p>
      </Box>
    );
  }

  return (
    <Grid2 style={{ maxHeight: 500, overflow: "auto" }}>
      {tasks.slice(0, initialTasksToShow).map((item) => (
        <TaskItem key={item.id} task={item} onChange={onChange} />
      ))}
      {tasks.length > initialTasksToShow && (
        <Grid2 style={{ marginBottom: 16, textAlign: "center" }}>
          <Button
            className={classNames(classes.button)}
            onClick={() =>
              setInitialTasksToShow(initialTasksToShow + tasksToFetch)
            }
          >
            Load More
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
};

export default Tasks;
