import { HippityResponse } from "hippity";
import { IRepository, IRepositoryRequest } from "../Interfaces/IRepository";
import { useClient } from "../../Hooks/useClient";

const apiPath = "Api/Repository";

export function useRepositoryService(): {
  $get: () => Promise<IRepository[]>;
  create: (repository: IRepositoryRequest) => Promise<HippityResponse>;
  del: (id: string) => Promise<HippityResponse>;
  update: (
    id: string,
    repository: IRepositoryRequest,
    force: boolean,
  ) => Promise<HippityResponse>;
  sync: (id: string, force: boolean) => Promise<HippityResponse>;
} {
  const client = useClient();

  async function $get(): Promise<IRepository[]> {
    return (await client.$get(apiPath)) as IRepository[];
  }

  async function create(
    repository: IRepositoryRequest,
  ): Promise<HippityResponse> {
    return await client.post(apiPath, repository);
  }

  async function update(
    id: string,
    repository: IRepositoryRequest,
    force: boolean,
  ): Promise<HippityResponse> {
    return await client.put(
      [`${apiPath}/{id}?force=${force}`, { id }],
      repository,
    );
  }

  async function del(id: string): Promise<HippityResponse> {
    return await client.del([`${apiPath}/{id}`, { id }]);
  }

  async function sync(id: string, force: boolean): Promise<HippityResponse> {
    return await client.post(
      [`${apiPath}/Sync/{id}?force=${force}`, { id }],
      {},
    );
  }

  return { $get, create, del, update, sync };
}
