import React, { useEffect, useRef } from "react";
import { Grid2, MenuItem } from "@mui/material";
import {
  isQuoted,
  removeQuotes,
} from "../../MappableTextField/MappableTextField";
import {
  EventType,
  EventTypeDisplayNames,
  parseEventType,
} from "../../../Common/Enums/EventType";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import ITriggerDefinition from "../../../Common/Interfaces/ITriggerDefinition";
import NonMappableSelect from "./NonMappableSelect";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import {
  AireFrameEventType,
  AireFrameEventTypeDisplayNames,
} from "../../../Common/Enums/AireFrameEventType";

interface IProps extends IReadOnlyComponentProps {
  onTriggerChange: (value: ITriggerDefinition) => void;
  triggerDefinition: ITriggerDefinition;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
  workflowDefinitionId: string;
  workflowVersion: number;
  parentTaskKey: string;
}

function RenderMenuItem(): JSX.Element[] {
  return Object.values(EventType).map((value) => (
    <MenuItem key={value} value={value}>
      {EventTypeDisplayNames[value]}
    </MenuItem>
  ));
}

const TriggerProperties: React.FC<IProps> = ({
  onTriggerChange,
  triggerDefinition,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  workflowDefinitionId,
  workflowVersion,
  parentTaskKey,
  readOnly,
}) => {
  const isMounted = useRef(false);

  const [selectedEvent, setSelectedEvent] = React.useState<string>(() => {
    if (triggerDefinition.selectedEvent !== "") {
      return parseEventType(triggerDefinition.selectedEvent);
    }

    return EventType.Custom.toString();
  });

  const gridLengthSize = (fitTwoItems: boolean) => {
    return fitTwoItems ? 6 : 12;
  };

  const [selectedEventKey, setSelectedEventKey] = React.useState<string>(
    triggerDefinition.selectedEventKey,
  );

  const [selectedTaskKey, setSelectedTaskKey] = React.useState<string>(
    triggerDefinition.selectedTaskKey,
  );

  const isCustomEvent = (): boolean => {
    return selectedEvent === EventType.Custom;
  };

  const isGroupEvent = (): boolean => {
    return selectedEvent === EventType.Group;
  };

  const isAireFrameEvent = (): boolean => {
    return selectedEvent === EventType.AireFrame;
  };

  const isTaskEvent = (): boolean => {
    return !isCustomEvent() && !isGroupEvent() && !isAireFrameEvent();
  };

  const [selectedTriggerVersion, setSelectedTriggerVersion] =
    React.useState<number>(
      isTaskEvent()
        ? triggerDefinition.selectedTriggerVersion == 0
          ? workflowVersion
          : triggerDefinition.selectedTriggerVersion
        : 0,
    );

  function findWorkflowBySelectedTaskKey() {
    for (const workflow of workflowDefinitions) {
      for (const version of workflow.versions) {
        for (const task of version.tasks) {
          if (
            task.taskKey === selectedTaskKey &&
            version.version === selectedTriggerVersion
          ) {
            return workflow.id;
          }
        }
      }
    }
  }
  const [selectedWorkflowId, setSelectedWorkflowID] = React.useState<string>(
    findWorkflowBySelectedTaskKey() ?? workflowDefinitionId,
  );

  const isCurrentWorkflow = (): boolean => {
    return workflowDefinitionId === selectedWorkflowId;
  };

  const getVersions = () => {
    return (
      workflowDefinitions.find((def) => def.id === selectedWorkflowId)
        ?.versions ?? []
    );
  };

  function versionSelectBox(): JSX.Element[] {
    return getVersions().map((item, index) => (
      <MenuItem key={`${item.version}_${index}`} value={item.version}>
        Version {item.version}
      </MenuItem>
    ));
  }

  const getTaskOptions = () => {
    const taskOptions = [];
    const versions =
      workflowDefinitions.find((def) => def.id === selectedWorkflowId)
        ?.versions ?? [];
    for (const version of versions) {
      if (
        version.version ===
        (isCurrentWorkflow() ? workflowVersion : selectedTriggerVersion)
      ) {
        for (const task of version.tasks) {
          if (task.taskKey !== parentTaskKey) {
            taskOptions.push(task);
          }
        }
      }
    }
    return taskOptions;
  };

  const workflowSelectBox = () => {
    return workflowDefinitions.map((def) => (
      <MenuItem key={def.id} value={def.id}>
        {def.name}
      </MenuItem>
    ));
  };

  const taskSelectBox = () => {
    return getTaskOptions()?.map((def) => (
      <MenuItem key={def.id} value={def.taskKey}>
        {isQuoted(def.name) ? removeQuotes(def.name) : def.name}
      </MenuItem>
    ));
  };

  const customEventSelectBox = () => {
    return eventDefinitions.map((def, index) => (
      <MenuItem key={`${def.eventKey}_${index}`} value={def.eventKey}>
        {def.name}
      </MenuItem>
    ));
  };

  const groupEventSelectBox = () => {
    return eventDefinitionGroups.map((def, index) => (
      <MenuItem key={`${def.groupKey}_${index}`} value={def.groupKey}>
        {def.name}
      </MenuItem>
    ));
  };

  const aireFrameEventSelectBox = () => {
    return Object.values(AireFrameEventType).map((value) => (
      <MenuItem key={value} value={value}>
        {AireFrameEventTypeDisplayNames[value]}
      </MenuItem>
    ));
  };

  const getKeyForRoutingKey = () => {
    if (isTaskEvent()) {
      return selectedTaskKey;
    }

    return selectedEventKey;
  };

  const eventTypeSetSelected = (selected: string) => {
    const eventType = parseEventType(selected);

    if (
      eventType != EventType.Custom &&
      eventType != EventType.Group &&
      eventType != EventType.AireFrame
    ) {
      setSelectedEventKey("");
    }

    setSelectedEvent(eventType);
  };

  const firstRender = useRef(true);

  useEffect(() => {
    // On first page render no need to update the triggers.
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const routingKey = `${selectedEvent ?? ""}.${getKeyForRoutingKey() ?? ""}.${
      (isTaskEvent() ? selectedTriggerVersion : 0) ?? ""
    }`;
    onTriggerChange({
      routingKey: routingKey,
      selectedEvent: selectedEvent,
      selectedEventKey: selectedEventKey,
      selectedTaskKey: selectedTaskKey,
      selectedTriggerVersion: selectedTriggerVersion,
    });
  }, [
    selectedEventKey,
    selectedEvent,
    selectedTaskKey,
    selectedTriggerVersion,
  ]);

  useEffect(() => {
    const version =
      isTaskEvent() && isCurrentWorkflow() && selectedTriggerVersion === 0
        ? workflowVersion
        : selectedTriggerVersion;
    setSelectedTriggerVersion(version);
  }, [selectedEvent]);

  useEffect(() => {
    // Check if it's not the initial render
    if (isMounted.current) {
      const version = isCurrentWorkflow()
        ? workflowVersion
        : selectedTriggerVersion;
      setSelectedTriggerVersion(version);
    } else {
      // If it's the initial render, set isMounted to true
      isMounted.current = true;
    }
  }, [selectedWorkflowId]);

  return (
    <React.Fragment>
      <Grid2 size={gridLengthSize(!isTaskEvent())}>
        <NonMappableSelect
          selectedValue={selectedEvent}
          label="Event Type"
          helpText="The type of event that should trigger this task"
          selectBox={RenderMenuItem()}
          setSelected={eventTypeSetSelected}
          readOnly={readOnly}
        />
      </Grid2>
      {isTaskEvent() && (
        <>
          <Grid2 size={6}>
            <NonMappableSelect
              selectedValue={selectedWorkflowId}
              label="Workflow Definition"
              helpText="The workflow definition that this trigger relates to"
              selectBox={workflowSelectBox()}
              setSelected={setSelectedWorkflowID}
              readOnly={readOnly}
            />
          </Grid2>
          <Grid2 size={6}>
            <NonMappableSelect
              selectedValue={selectedTriggerVersion.toString()}
              label="Version"
              helpText="The type of version that should trigger this task"
              selectBox={versionSelectBox()}
              setSelected={setSelectedTriggerVersion}
              readOnly={readOnly}
            />
          </Grid2>
          <Grid2 size={12}>
            <NonMappableSelect
              selectedValue={selectedTaskKey}
              label="Task"
              helpText="The task that should trigger this task"
              selectBox={taskSelectBox()}
              setSelected={setSelectedTaskKey}
              readOnly={readOnly}
            />
          </Grid2>
        </>
      )}
      {isCustomEvent() && (
        <Grid2 size={gridLengthSize(true)}>
          <NonMappableSelect
            selectedValue={selectedEventKey}
            label="Custom Event"
            helpText="The custom event that should trigger this task"
            selectBox={customEventSelectBox()}
            setSelected={setSelectedEventKey}
            readOnly={readOnly}
          />
        </Grid2>
      )}
      {isGroupEvent() && (
        <Grid2 size={gridLengthSize(true)}>
          <NonMappableSelect
            selectedValue={selectedEventKey}
            label="Group Event"
            helpText="The group event that should trigger this task"
            selectBox={groupEventSelectBox()}
            setSelected={setSelectedEventKey}
            readOnly={readOnly}
          />
        </Grid2>
      )}
      {isAireFrameEvent() && (
        <Grid2 size={gridLengthSize(true)}>
          <NonMappableSelect
            selectedValue={selectedEventKey}
            label="AireFrame Event"
            helpText="The AireFrame event that should trigger this task"
            selectBox={aireFrameEventSelectBox()}
            setSelected={setSelectedEventKey}
            readOnly={readOnly}
          />
        </Grid2>
      )}
    </React.Fragment>
  );
};

export default TriggerProperties;
