import { HippityResponse } from "hippity";
import { useClient } from "../../Hooks/useClient";

export default function useEventService(): {
  emit: (
    eventKey: string,
    subjectId: string,
    context: string,
    correlationId?: string | null,
  ) => Promise<HippityResponse>;
} {
  const client = useClient();

  async function emit(
    eventKey: string,
    subjectId: string,
    context: string,
    correlationId: string | null = null,
  ): Promise<HippityResponse> {
    const headers: any = {
      "Content-Type": "application/xml",
    };

    if (correlationId) {
      headers["X-Correlation-Id"] = correlationId;
    }

    return await client.post(
      [
        "/Api/Event/{eventKey}/{subjectId}",
        { eventKey: eventKey, subjectId: subjectId },
      ],
      context,
      {
        headers: headers,
      },
    );
  }

  return { emit };
}
