import React from "react";
import { Grid2, Button, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import { ITableEventDefinition } from "../../Common/Interfaces/ITableEventDefinition";
import useEventService from "../../Common/Hooks/useEventService";
import { useEventDefinitionService } from "../../Common/Hooks/useEventDefinitionService";
import { useState } from "react";
import { XmlEditor } from "../Editors";
import Loading from "../Loading/Loading";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../App.Styles";
import SnackbarAlert from "../SnackbarAlert";

interface IProps extends IReadOnlyComponentProps {
  eventDefinition: ITableEventDefinition;
}

const EventDefinitionDetail: React.FC<IProps> = ({ eventDefinition }) => {
  const eventService = useEventService();
  const definitionService = useEventDefinitionService();
  const [subjectId, setSubjectId] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [context, setContext] = useState(
    eventDefinition.exampleContext || "<xml></xml>",
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const onPublishEvent = async () => {
    try {
      setLoading(true);
      const response = await eventService.emit(
        eventDefinition.eventKey,
        subjectId,
        context,
        correlationId,
      );
      setSnackbarMessage(
        response.success
          ? `Event Published (${eventDefinition.eventKey})`
          : `Error: ${response.body}`,
      );
    } catch (e) {
      const error = e as Error;
      setSnackbarMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const onSaveContext = async () => {
    try {
      setLoading(true);
      const response = await definitionService.update({
        ...eventDefinition,
        exampleContext: context,
      });
      setSnackbarMessage(
        response.success ? `Example Context Saved` : `Error: ${response.body}`,
      );
    } catch (e) {
      const error = e as Error;
      setSnackbarMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container} id="event_definition_detail">
      <Loading visible={loading} />
      <SnackbarAlert
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        colour={snackbarMessage.includes("Error:") ? "error" : "success"}
        message={snackbarMessage}
      />
      <Grid2 container>
        <Grid2
          container
          spacing={2}
          className={classNames(classes.itemContainer, classes.extraPadding)}
          size={12}
        >
          <Grid2 size={12}>
            <Typography variant="h5">
              Fire Event{" "}
              <span className={classes.placeholderText}>
                ({eventDefinition.eventKey})
              </span>
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              This will call the api to fire your event, triggering any relevant
              tasks.
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <TextField
              id="subject_id"
              fullWidth
              variant="outlined"
              label="Subject ID"
              value={subjectId}
              onChange={(x) => setSubjectId(x.target.value)}
            />
          </Grid2>
          <Grid2 size={6}>
            <TextField
              id="correlation_id"
              fullWidth
              variant="outlined"
              label="Correlation ID"
              value={correlationId}
              onChange={(x) => setCorrelationId(x.target.value)}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="subtitle1" className={classes.subsubtitle}>
              Context
            </Typography>
            <XmlEditor value={context} onChange={setContext} />
          </Grid2>
        </Grid2>
        <Grid2
          className={classNames(
            classes.itemContainer,
            classes.buttonContainer,
            classes.transparentBorderless,
          )}
          size={12}
        >
          <Button
            className={classNames(classes.button, classes.buttonCancel)}
            onClick={() => onSaveContext()}
          >
            Save Example Context
          </Button>
          <Button
            className={classNames(classes.button, classes.buttonSave)}
            onClick={() => onPublishEvent()}
          >
            Publish Event
          </Button>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default EventDefinitionDetail;
