import {
  HippityMiddleware,
  RouteTemplate,
  jsonClient,
  HttpClient,
  HippityRequest,
  HippityResponse,
} from "hippity";
import { useAuthHeaders } from "./useAuthHeaders";

export function useClient(): HttpClient<
  HippityRequest<string | RouteTemplate>,
  HippityResponse
> {
  const { getHeaders } = useAuthHeaders();

  function authMiddleware(): HippityMiddleware {
    return async (req, next) => {
      const authHeaders = await getHeaders();

      req.headers = {
        ...req.headers,
        ...authHeaders,
      };

      return await next(req);
    };
  }

  const client = jsonClient.useAt(
    -1,
    authMiddleware() as HippityMiddleware<string | RouteTemplate | undefined>,
  );

  return client;
}
