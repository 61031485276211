import { Button, Grid2, Paper, Typography, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import AssigneeIcon from "@mui/icons-material/PersonOutline";
import PatientIcon from "@mui/icons-material/Person";
import PolylineIcon from "@mui/icons-material/PolylineOutlined";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import ContractIcon from "@mui/icons-material/ExpandLess";
import DueIcon from "@mui/icons-material/Alarm";
import CreatedIcon from "@mui/icons-material/Schedule";
import UpdatedIcon from "@mui/icons-material/Update";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import RetryIcon from "@mui/icons-material/Replay";
import EventIcon from "@mui/icons-material/Send";
import UrlIcon from "@mui/icons-material/Link";
import TaskInfo from "./TaskInfo";
import TaskState from "./State/TaskState";
import classNames from "classnames";
import TaskStatusSelect from "./TaskStatusSelect";
import { useTasksService } from "../Hooks/useTasksService";
import { ITaskInstance } from "../Interfaces/ITaskInstance";
import PopupTextEdit from "./PopupTextEdit/PopupTextEdit";
import Loading from "../Components/Loading/Loading";
import SnackbarAlert from "./SnackbarAlert";
import { classes } from "../App.Styles";
import { TaskType } from "../Common/Enums/TaskType";
import { formatUIDate } from "../Common/Helpers/DateHelper";
import { TaskTypeNameProvider } from "../Common/Providers/TaskTypeNameProvider";

interface Props {
  task: ITaskInstance;
  onChange: () => void;
}

const TaskItem: React.FC<Props> = ({ task, onChange }) => {
  const [propertiesOpen, setPropertiesOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { updateStatus, updateState, updateAssignee } = useTasksService();
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const onStatusUpdate: (status: string) => Promise<boolean> = async (
    status,
  ) => {
    setLoading(true);
    try {
      await updateStatus(task.id, status);
      onChange();
      setSnackbarMessage("Task Status Saved");
      return true;
    } catch (e) {
      const errorContent = getErrorContent(e);
      if (errorContent) setSnackbarMessage(`Error: ${errorContent}`);
      else setSnackbarMessage(`Error: ${e}`);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getErrorContent = (e: any) => {
    if (e instanceof Error) {
      // check if there is an error message
      const regex = /"body":\s*"([^"]+)"/;
      const match = e.message.match(regex);

      return match && match[1] ? match[1] : null;
    }
    return null;
  };

  const onStateUpdate: (
    state: { key: string; value: string }[],
  ) => void = async (state) => {
    setLoading(true);
    task.state = state;
    try {
      await updateState(task.id, state);
      setSnackbarMessage(`Task Saved`);
    } catch (e) {
      const errorContent = getErrorContent(e);
      if (errorContent) setSnackbarMessage(`Error: ${errorContent}`);
      else setSnackbarMessage(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const onAssigneeUpdate: (assigneeId: string) => void = async (assigneeId) => {
    setLoading(true);
    try {
      await updateAssignee(task.id, assigneeId).then(() => {
        onChange();
      });
    } catch (e) {
      const errorContent = getErrorContent(e);
      if (errorContent) setSnackbarMessage(`Error: ${errorContent}`);
      else setSnackbarMessage(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Loading visible={loading} />
      <Paper className={classes.taskContainer} elevation={0}>
        <div className={classes.taskHighlight} />
        <Grid2 container className={classes.taskMain}>
          <Grid2
            size={{
              xs: 3,
              md: 4,
            }}
          >
            <Typography className={classes.taskTitle}>{task.name}</Typography>
            <Typography className={classes.taskDescription}>
              {task.description}
            </Typography>
          </Grid2>
          <Grid2 container alignItems="flex-start" spacing={1} size={6}>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TaskInfo
                label={"Subject"}
                body={task.subjectId}
                icon={<PatientIcon className={classes.taskIcon} />}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TaskInfo
                label={"Type"}
                body={TaskTypeNameProvider(task.taskType)}
                icon={<CategoryIcon className={classes.taskIcon} />}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              {task.taskType === TaskType.Manual ? (
                <PopupTextEdit
                  name="Edit Assignee"
                  value={task.assigneeId}
                  onSave={(newName) => {
                    onAssigneeUpdate(newName);
                  }}
                  component={(onClick) => (
                    <Tooltip title="Edit Assignee">
                      <Button
                        onClick={onClick}
                        className={classes.editAssigneeButton}
                      >
                        <TaskInfo
                          label={
                            <span>
                              Assignee
                              <EditIcon
                                fontSize="inherit"
                                color="secondary"
                                sx={{ ml: 1 }}
                              />
                            </span>
                          }
                          body={task.assigneeId}
                          icon={<AssigneeIcon className={classes.taskIcon} />}
                        />
                      </Button>
                    </Tooltip>
                  )}
                />
              ) : (
                <TaskInfo
                  label="Assignee"
                  body={null}
                  icon={<AssigneeIcon className={classes.taskIcon} />}
                />
              )}
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TaskInfo
                label="Due"
                body={
                  task.activityContext.due
                    ? formatUIDate(task.activityContext.due)
                    : null
                }
                icon={<DueIcon className={classes.taskIcon} />}
              />
            </Grid2>
            {propertiesOpen && (
              <>
                <Grid2
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TaskInfo
                    label={"Created"}
                    body={formatUIDate(task.created)}
                    icon={<CreatedIcon className={classes.taskIcon} />}
                  />
                </Grid2>
                <Grid2
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TaskInfo
                    label={"Last Updated"}
                    body={formatUIDate(task.updated)}
                    icon={<UpdatedIcon className={classes.taskIcon} />}
                  />
                </Grid2>
                <Grid2
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TaskInfo
                    label={"Correlation ID"}
                    body={task.correlationId}
                    icon={<PolylineIcon className={classes.taskIcon} />}
                  />
                </Grid2>
                {task.activityContext.to && (
                  <Grid2
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TaskInfo
                      label="Email To"
                      body={task.activityContext.to}
                      icon={<EmailIcon className={classes.taskIcon} />}
                    />
                  </Grid2>
                )}
                {task.activityContext.url && (
                  <Grid2
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TaskInfo
                      label="Url"
                      body={task.activityContext.url}
                      icon={<UrlIcon className={classes.taskIcon} />}
                    />
                  </Grid2>
                )}
                {task.activityContext.retryCount && (
                  <Grid2
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TaskInfo
                      label="Retry Count"
                      body={task.activityContext.retryCount.toString()}
                      icon={<RetryIcon className={classes.taskIcon} />}
                    />
                  </Grid2>
                )}
                {task.activityContext.eventKey && (
                  <Grid2
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TaskInfo
                      label="Event Key"
                      body={task.activityContext.eventKey}
                      icon={<EventIcon className={classes.taskIcon} />}
                    />
                  </Grid2>
                )}
              </>
            )}
          </Grid2>
          <Grid2
            container
            direction="column"
            alignItems="flex-end"
            size={{
              xs: 3,
              md: 2,
            }}
          >
            <TaskStatusSelect
              taskId={task.id}
              taskStatus={task.activityContext.status}
              taskType={task.taskType}
              onUpdate={onStatusUpdate}
              setSnackbarMessage={setSnackbarMessage}
              setLoading={setLoading}
              lastRun={task.lastRun}
            />
            <Button
              className={classNames(
                classes.subjectTitle,
                classes.subjectTitleBottom,
                classes.taskStatusColumnItem,
              )}
              onClick={() => setPropertiesOpen(!propertiesOpen)}
            >
              {propertiesOpen ? (
                <>
                  Show Less <ContractIcon />
                </>
              ) : (
                <>
                  Show More <ExpandIcon />
                </>
              )}
            </Button>
          </Grid2>
          {propertiesOpen && Object.keys(task.state).length > 0 && (
            <TaskState
              task={task}
              onUpdate={onStateUpdate}
              onCancel={() => setPropertiesOpen(!propertiesOpen)}
            />
          )}
        </Grid2>
      </Paper>
      <SnackbarAlert
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        colour={snackbarMessage.includes("Error:") ? "error" : "success"}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default TaskItem;
