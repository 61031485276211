import { useMemo } from "react";
import { Grid2 } from "@mui/material";
import ITableMetrics from "../../Common/Interfaces/ITableMetrics";
import { BarChart, ChartsColorPalette, PieChart } from "@mui/x-charts";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { TaskStatusColours } from "../TaskStatusColours";

interface IProps extends IReadOnlyComponentProps {
  showOverdueTasksView: boolean;
  tableData?: ITableMetrics[];
}

const colors: ChartsColorPalette = [
  TaskStatusColours.New,
  TaskStatusColours.InProgress,
  TaskStatusColours.Failed,
  TaskStatusColours.Blocked,
  TaskStatusColours.Cancelled,
  TaskStatusColours.Completed,
];

const MetricsCharts: React.FC<IProps> = ({
  showOverdueTasksView,
  tableData,
}): JSX.Element => {
  const statusGraphData = useMemo(() => {
    if (!tableData) return [{ data: [] }];

    return [
      {
        data: [
          {
            id: 0,
            value: tableData.reduce((sum, current) => sum + current.new, 0),
            label: "New",
          },
          {
            id: 1,
            value: tableData.reduce(
              (sum, current) => sum + current.inProgress,
              0,
            ),
            label: "In Progress",
          },
          {
            id: 2,
            value: tableData.reduce((sum, current) => sum + current.failed, 0),
            label: "Failed",
          },
          {
            id: 3,
            value: tableData.reduce((sum, current) => sum + current.blocked, 0),
            label: "Blocked",
          },
          ...(!showOverdueTasksView
            ? [
                {
                  id: 4,
                  value: tableData.reduce(
                    (sum, current) => sum + current.cancelled,
                    0,
                  ),
                  label: "Cancelled",
                },
                {
                  id: 5,
                  value: tableData.reduce(
                    (sum, current) => sum + current.completed,
                    0,
                  ),
                  label: "Completed",
                },
              ]
            : []),
        ],
      },
    ];
  }, [tableData]);

  const workflowGraphData = useMemo(() => {
    if (!tableData) return [];

    return tableData.map((x) => {
      return {
        workflow: x.id,
        new: x.new,
        inProgress: x.inProgress,
        failed: x.failed,
        blocked: x.blocked,
        cancelled: showOverdueTasksView ? undefined : x.cancelled,
        completed: showOverdueTasksView ? undefined : x.completed,
      };
    });
  }, [tableData]);

  return (
    <Grid2 container margin={4}>
      <Grid2
        size={{
          xs: 6,
          md: 3,
        }}
      >
        <PieChart
          series={statusGraphData}
          title="Tasks by Status"
          colors={colors}
          margin={{ left: 150, right: 20 }}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "left" },
            },
          }}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 9,
        }}
      >
        <BarChart
          dataset={workflowGraphData}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "workflow",
              tickLabelStyle: {
                angle: 45,
                textAnchor: "start",
              },
            },
          ]}
          slotProps={{ legend: { hidden: true } }}
          series={[
            { dataKey: "new", label: "New" },
            { dataKey: "inProgress", label: "In Progress" },
            { dataKey: "failed", label: "Failed" },
            { dataKey: "blocked", label: "Blocked" },
            ...(!showOverdueTasksView
              ? [
                  { dataKey: "cancelled", label: "Cancelled" },
                  { dataKey: "completed", label: "Completed" },
                ]
              : []),
          ]}
          height={275}
          title="Workflows by Status"
          colors={colors}
        />
      </Grid2>
    </Grid2>
  );
};

export default MetricsCharts;
