import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Grid2,
  IconButton,
  Paper,
  styled,
  Switch,
  TableCell,
  TableFooter,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DataTable, Column } from "../Tables/DataTable";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import Loading from "../Loading/Loading";
import { classes } from "../../App.Styles";
import { useMetricsService } from "../../Common/Hooks/useMetricsService";
import { CellProps, ColumnInstance, Row } from "react-table";
import MetricsDetail from "./MetricsDetail";
import ITableMetrics from "../../Common/Interfaces/ITableMetrics";
import IMetricsResult from "../../Common/Interfaces/IMetricsResult";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useAbility } from "../Authorisation/AbilityContext";
import { formatUIDate } from "../../Common/Helpers/DateHelper";
import { Moment } from "moment";
import moment from "moment";
import MetricsCharts from "./MetricsCharts";
import { TaskStatusColours } from "../TaskStatusColours";
import ExpandedRowCell from "./ExpandedRowCell";
import { ISummaryTableRow } from "../../Interfaces/ISummaryTableRow";
import { IWorkflowTaskStatuses } from "../../Interfaces/IWorkflowTaskStatuses";
import {
  TaskStatusOrTotalString,
  ActiveTaskStatuses,
  AllTaskStatuses,
} from "./Utils/MetricsUtils";

const borderRightValue = "1px solid rgba(224, 224, 224, 1)";
const Totals = "Totals";
const total = "total";
const activeTotal = "activeTotal";

const Metrics = (): JSX.Element => {
  const ability = useAbility();
  const canViewTasks = ability.can("view", "task");
  const service = useMetricsService();
  const [metrics, setMetrics] = useState<IMetricsResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
    moment().startOf("day").subtract(6, "days"),
  );
  const [endDateFilter, setEndDateFilter] = useState<Moment | null>(null);
  const [showOverdueTasksView, setShowOverdueTasksView] =
    useState<boolean>(false);

  const setDateFilter = useCallback(
    (start: Moment | null, end: Moment | null) => {
      setStartDateFilter(start);
      setEndDateFilter(end);
    },
    [],
  );

  const [metricsFetchStatus, setMetricsFetchStatus] = useState<HttpStatus>(
    HttpStatus.Initial,
  );
  const [selectedWorkflowTaskStatuses, setSelectedWorkflowTaskStatuses] =
    useState<IWorkflowTaskStatuses[]>([]);

  const renderCell =
    (taskStatusOrTotal: TaskStatusOrTotalString) =>
    ({ value, row }: CellProps<ISummaryTableRow, string>): JSX.Element => {
      if (!row.isExpanded) {
        return nonExpandedRowCell(taskStatusOrTotal, value);
      }

      return expandedRowCell(taskStatusOrTotal, value, row);
    };

  const nonExpandedRowCell = (
    taskStatusOrTotal: TaskStatusOrTotalString,
    value: string,
  ): JSX.Element => {
    const isTotal = taskStatusOrTotal === total;
    const isActiveTotal = taskStatusOrTotal === activeTotal;
    return (
      <Box textAlign={"center"} width={"100%"}>
        {Wrapped(value, isTotal || isActiveTotal)}
      </Box>
    );
  };

  const expandedRowCell = (
    taskStatusOrTotal: TaskStatusOrTotalString,
    value: string,
    row: Row<ISummaryTableRow>,
  ): JSX.Element => {
    return (
      <ExpandedRowCell
        selectedWorkflowTaskStatuses={selectedWorkflowTaskStatuses}
        setSelectedWorkflowTaskStatuses={(
          updatedTaskStatuses: IWorkflowTaskStatuses[],
        ) => {
          setSelectedWorkflowTaskStatuses(updatedTaskStatuses);
        }}
        showOverdueTasksView={showOverdueTasksView}
        taskStatusOrTotal={taskStatusOrTotal}
        value={value}
        row={row}
      />
    );
  };

  const renderHeader = (
    header: string,
    column: ColumnInstance<ISummaryTableRow>,
    center = false,
    colour?: string,
  ) => {
    return (
      <div
        style={{
          textAlign: center ? "center" : "left",
          fontWeight: header === "Total" || header === "Active" ? 600 : 400,
        }}
      >
        <div
          style={{
            borderBottom: colour ? `5px solid ${colour}` : undefined,
            width: "100%",
            display: "block",
          }}
        >
          <span>{header}</span>
          <TableSortLabel
            style={{ display: column.isSorted ? "inline-flex" : "none" }}
            active={column.isSorted}
            direction={column.isSortedDesc ? "desc" : "asc"}
          />
        </div>
      </div>
    );
  };

  const getHeaderTextFromAccessor = (accessor: TaskStatusOrTotalString) => {
    if (accessor == activeTotal)
      return showOverdueTasksView ? "Total" : "Active";

    return accessor
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const lastActivityWidth = 135;
  const workflowKeyWidth = 145;
  const countWidth = 130;
  const actionsWidth = 65;

  const renderTaskCountColumn = (
    accessor: TaskStatusOrTotalString,
    colour?: string,
    borderRight = false,
  ): Column<ISummaryTableRow> => {
    const headerText = getHeaderTextFromAccessor(accessor);

    return {
      Header: ({ column }) => renderHeader(headerText, column, true, colour),
      accessor: accessor,
      width: countWidth,
      Cell: renderCell(accessor),
      borderRight: borderRight ? borderRightValue : undefined,
    };
  };

  const columns: Column<ISummaryTableRow>[] = [
    {
      Header: ({ column }) => renderHeader("Workflow Key", column),
      width: workflowKeyWidth,
      accessor: "id",
      Cell: ({ value }) => Wrapped(value),
    },
    {
      Header: ({ column }) => renderHeader("Last Activity", column),
      width: lastActivityWidth,
      accessor: "lastActivity",
      Cell: ({ value }) => Wrapped(formatUIDate(value)),
    },
    renderTaskCountColumn("new", TaskStatusColours.New),
    renderTaskCountColumn("inProgress", TaskStatusColours.InProgress),
    renderTaskCountColumn("failed", TaskStatusColours.Failed),
    renderTaskCountColumn("blocked", TaskStatusColours.Blocked),
    renderTaskCountColumn(activeTotal, undefined, true),
    ...(!showOverdueTasksView
      ? [
          renderTaskCountColumn("cancelled", TaskStatusColours.Cancelled),
          renderTaskCountColumn("completed", TaskStatusColours.Completed, true),
          renderTaskCountColumn(total),
        ]
      : []),
    ...(canViewTasks
      ? [
          {
            Header: "",
            id: "actions",
            width: actionsWidth,
            Cell: ({ row }: { row: Row<ISummaryTableRow> }) => {
              return (
                <span {...row.getToggleRowExpandedProps()}>
                  <IconButton className={classes.mediumGrey}>
                    {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </span>
              );
            },
          },
        ]
      : []),
  ];
  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const response = await service.$get(startDateFilter, endDateFilter);
      setMetrics(response);
      setMetricsFetchStatus(HttpStatus.Success);
    } catch {
      setMetricsFetchStatus(HttpStatus.Failure);
    }
    setLoading(false);
  };

  const getEmptyDataSourceMessage = (): string => {
    switch (metricsFetchStatus) {
      case HttpStatus.Success:
        return "No task counts to display.";
      case HttpStatus.Failure:
        return "Error fetching task counts, please try again.";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchMetrics();
    return () => {
      setMetrics(undefined);
      setMetricsFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, [startDateFilter, endDateFilter]);

  const processTableData = useCallback((): ITableMetrics[] => {
    if (!metrics) {
      return [];
    }

    const { workflowDefinitions = [] } = metrics;
    const totals: ITableMetrics[] = [];

    workflowDefinitions.forEach(({ key, versions }) => {
      versions.forEach(({ version, taskCounts, lastActivity }) => {
        totals.push({
          id: `${key} (v${version})`,
          key,
          version,
          lastActivity,
          new: showOverdueTasksView ? taskCounts.overdueNew : taskCounts.new,
          inProgress: showOverdueTasksView
            ? taskCounts.overdueInProgress
            : taskCounts.inProgress,
          failed: showOverdueTasksView
            ? taskCounts.overdueFailed
            : taskCounts.failed,
          blocked: showOverdueTasksView
            ? taskCounts.overdueBlocked
            : taskCounts.blocked,
          cancelled: taskCounts.cancelled,
          completed: taskCounts.completed,
          total: showOverdueTasksView
            ? taskCounts.overdueTotal
            : taskCounts.total,
          activeTotal: showOverdueTasksView
            ? taskCounts.overdueTotal
            : taskCounts.activeTotal,
        });
      });
    });

    return totals.sort((a, b) => a.id.localeCompare(b.id));
  }, [metrics, showOverdueTasksView]);

  const tableData = useMemo(processTableData, [metrics, showOverdueTasksView]);

  const onTaskChange = async () => {
    await fetchMetrics();
  };

  const renderDetail = (row: ITableMetrics) => {
    if (!canViewTasks) return <></>;
    const wts = selectedWorkflowTaskStatuses.find(
      (wts) =>
        wts.workflowKey === row.key &&
        wts.workflowVersion == row.version.toString(),
    );

    return (
      <MetricsDetail
        row={row}
        taskStatuses={
          wts?.taskStatuses ??
          (showOverdueTasksView ? ActiveTaskStatuses : AllTaskStatuses)
        }
        onTasksChange={onTaskChange}
        startDateFilter={startDateFilter}
        endDateFilter={endDateFilter}
        showOverdueTasksView={showOverdueTasksView}
      />
    );
  };

  const footerCell = (
    element: JSX.Element,
    width: number,
    key: string,
    borderRight = false,
  ) => {
    return (
      <TableCell
        width={width}
        style={{
          flex: `${width} 0 auto`,
          fontSize: "0.875rem",
          color: "black",
        }}
        sx={{ borderRight: borderRight ? borderRightValue : undefined }}
        key={`${key}-footer-cell`}
      >
        <Box display="flex" alignItems="center" height="100%">
          {element}
        </Box>
      </TableCell>
    );
  };

  const taskCountFooterCell = (
    count: number | undefined,
    key: string,
    borderRight = false,
  ) => {
    return footerCell(
      <Box textAlign={"center"} width={"100%"}>
        {Wrapped((count ?? 0).toString(), true)}
      </Box>,
      countWidth,
      key,
      borderRight,
    );
  };

  const renderTableFooter = useCallback(
    (rows: ITableMetrics[]) => {
      const taskCounts = {
        new: rows.reduce((sum, current) => sum + current.new, 0),
        inProgress: rows.reduce((sum, current) => sum + current.inProgress, 0),
        failed: rows.reduce((sum, current) => sum + current.failed, 0),
        blocked: rows.reduce((sum, current) => sum + current.blocked, 0),
        cancelled: rows.reduce((sum, current) => sum + current.cancelled, 0),
        completed: rows.reduce((sum, current) => sum + current.completed, 0),
        total: rows.reduce((sum, current) => sum + current.total, 0),
        activeTotal: rows.reduce(
          (sum, current) => sum + current.activeTotal,
          0,
        ),
      };

      return (
        <TableFooter>
          <TableRow style={{ display: "flex" }}>
            {footerCell(Wrapped(Totals, true), workflowKeyWidth, Totals)}
            {footerCell(Wrapped("", true), lastActivityWidth, "lastActivity")}
            {taskCountFooterCell(taskCounts.new, "new")}
            {taskCountFooterCell(taskCounts.inProgress, "inProgress")}
            {taskCountFooterCell(taskCounts.failed, "failed")}
            {taskCountFooterCell(taskCounts.blocked, "blocked")}
            {taskCountFooterCell(taskCounts.activeTotal, activeTotal, true)}
            {!showOverdueTasksView
              ? [
                  taskCountFooterCell(taskCounts.cancelled, "cancelled"),
                  taskCountFooterCell(taskCounts.completed, "completed", true),
                  taskCountFooterCell(taskCounts.total, total),
                ]
              : []}
            <TableCell
              width={actionsWidth}
              height={73}
              style={{ flex: `${actionsWidth} 0 auto` }}
            />
          </TableRow>
        </TableFooter>
      );
    },
    [showOverdueTasksView],
  );

  const renderCharts = useCallback(
    (rows: ITableMetrics[]) => (
      <MetricsCharts
        showOverdueTasksView={showOverdueTasksView}
        tableData={rows}
      />
    ),
    [showOverdueTasksView],
  );

  const customGlobalFilter = (
    rows: any[],
    columnIds: string[],
    filterValue: string,
  ): any[] => {
    if (!filterValue) {
      return rows;
    }
    return rows.filter((row) => {
      const workflowKeyValue = row.values[columnIds[0]] || "";
      const lastActivityValue = formatUIDate(row.values[columnIds[1]]) || "";
      return (
        workflowKeyValue.toLowerCase().includes(filterValue.toLowerCase()) ||
        lastActivityValue.toLowerCase().includes(filterValue.toLowerCase())
      );
    });
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: theme.palette.primary.main,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }));

  const getTableName = (): string => {
    return `${showOverdueTasksView ? "Overdue" : "All"} Task Counts`;
  };

  return (
    <Paper elevation={4} className={classes.table}>
      <Loading visible={loading} />
      <DataTable
        data={tableData}
        columns={columns}
        skipPageReset={true}
        renderDetail={renderDetail}
        name={getTableName()}
        aria-label={getTableName()}
        emptyDataSourceMessage={getEmptyDataSourceMessage()}
        setDateFilter={setDateFilter}
        renderTableFooter={renderTableFooter}
        renderPreHeader={renderCharts}
        total={tableData.length}
        customGlobalFilter={customGlobalFilter}
        customComponent={
          <Grid2
            component="label"
            container
            alignItems="center"
            justifyContent={"right"}
            marginRight={2}
            width={"100%"}
          >
            <Grid2>Overdue</Grid2>
            <Grid2>
              <CustomSwitch
                checked={!showOverdueTasksView}
                onChange={() => {
                  setShowOverdueTasksView(!showOverdueTasksView);
                  setSelectedWorkflowTaskStatuses([]);
                }}
                value="checked"
              />
            </Grid2>
            <Grid2>All</Grid2>
          </Grid2>
        }
      />
    </Paper>
  );
};

export default Metrics;
