import { EventGroupAction } from "./EventGroupAction";
import { DispatchActionType as DispatchActionType } from "./DispatchActionType";

export type AddEventGroupAction = {
  dispatchActionType: DispatchActionType.AddEventGroupAction;
  eventGroupAction: EventGroupAction;
};

export type RemoveEventGroupAction = {
  dispatchActionType: DispatchActionType.RemoveEventGroupAction;
  eventDefinitionGroupId: string;
};

export type DispatchAction = AddEventGroupAction | RemoveEventGroupAction;

const initialState: EventGroupAction[] = [];

export const EventGroupActionReducer = (
  state: EventGroupAction[] = initialState,
  dispatchAction: DispatchAction,
): EventGroupAction[] => {
  switch (dispatchAction.dispatchActionType) {
    case DispatchActionType.AddEventGroupAction:
      return [
        ...state.filter(
          (a) =>
            a.eventDefinitionGroupId !==
            dispatchAction.eventGroupAction.eventDefinitionGroupId,
        ),
        dispatchAction.eventGroupAction,
      ];
    case DispatchActionType.RemoveEventGroupAction:
      return state.filter(
        (item) =>
          item.eventDefinitionGroupId !== dispatchAction.eventDefinitionGroupId,
      );
    default:
      return state;
  }
};
