import React, { useContext, useEffect, useState } from "react";
import { useFlowTenantService } from "../Hooks/useFlowTenantService";
import IFlowTenant from "../Interfaces/IFlowTenant";

export enum FetchTenantStatus {
  Ok,
  NotFound,
  Error,
}

export interface ITenantContext {
  tenantKey: string | undefined;
  fetchTenantStatus: FetchTenantStatus;
}

interface ITenantContextState {
  tenantKey: string | undefined;
  fetchTenantStatus: FetchTenantStatus;
}

export const TenantContext = React.createContext<ITenantContext | undefined>(
  undefined,
);

interface IProps extends React.PropsWithChildren {
  onTenantContextSet: (tenantContextState: ITenantContext) => void;
}

export interface IErrorResponse {
  Request: IRequest;
  Response: IResponse;
}

export interface IResponse {
  body: string;
  status: string;
  message: string;
  headers: Record<string, string>;
}

export interface IRequest {
  method: string;
  url: string;
}

export const TenantContextProvider: React.FC<IProps> = ({
  children,
  onTenantContextSet,
}) => {
  const [tenantContextState, setTenantContextState] =
    useState<ITenantContextState>();
  const service = useFlowTenantService();

  useEffect(() => {
    async function fetchTenant() {
      let tenant;
      let fetchTenantStatus;
      const result = await service.get();
      if (result.success) {
        tenant = (result.body as IFlowTenant).tenantKey;
        fetchTenantStatus = FetchTenantStatus.Ok;
      } else if (result.status == 404) {
        fetchTenantStatus = FetchTenantStatus.NotFound;
      } else {
        fetchTenantStatus = FetchTenantStatus.Error;
      }

      setTenantContextState({
        tenantKey: tenant,
        fetchTenantStatus: fetchTenantStatus,
      });
      onTenantContextSet({
        tenantKey: tenant,
        fetchTenantStatus: fetchTenantStatus,
      });
    }

    fetchTenant();
  }, []);

  if (!tenantContextState) return <p>Loading</p>;

  const tenantContext: ITenantContext = {
    ...tenantContextState,
  };

  return (
    <TenantContext.Provider value={tenantContext}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenantContext = (): ITenantContext => {
  const tenantContext = useContext(TenantContext);

  if (!tenantContext) throw new Error("Tenant context has not been registered");

  return tenantContext;
};
