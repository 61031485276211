import { useState } from "react";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import ImportModal from "./ImportModal";
import { useEventDefinitionGroupsService } from "../../../Common/Hooks/useEventDefinitionGroupsService";

interface IProps {
  refresh: () => void;
  show: boolean;
  onClose: () => void;
}

const EventGroupImportModal: React.FC<IProps> = (props: IProps) => {
  const { refresh, show } = props;

  const [json, setJson] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const service = useEventDefinitionGroupsService();

  const onClose = () => {
    setError("");
    props.onClose();
  };

  const onSubmit = async () => {
    let eventDefinitionGroup: IEventDefinitionGroup;
    setLoading(true);

    try {
      eventDefinitionGroup = JSON.parse(json);
    } catch {
      setLoading(false);
      setError("Json Syntax Error");
      return;
    }

    try {
      const response = await service.importEventGroup(eventDefinitionGroup);
      switch (response.status) {
        case 200: {
          setJson("");
          onClose();
          setSnackbarOpen(true);
          refresh();
          break;
        }
        case 400: {
          let errorText = "";
          try {
            const errors = JSON.parse(response.body as string).errors.Name;
            errorText = errors.join(" ");
          } catch (e) {
            if (typeof response?.body == "string") {
              errorText = response?.body as string;
            } else {
              errorText =
                "Please ensure the groupKey and name values are unique";
            }
          }
          setError("The event group is invalid: " + errorText);
          break;
        }
        case 401: {
          setError("Your session has expired, please log in again");
          break;
        }
        case 403: {
          setError(
            "You don't have permission to import event groups, please contact an administrator",
          );
          break;
        }
        case 500: {
          setError("An Unexpected Error Occurred");
          break;
        }
        default:
          break;
      }
    } catch (e: any) {
      console.error(e);
      setError("An Unexpected Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ImportModal
        onClose={onClose}
        content={json}
        error={error}
        loading={loading}
        onChange={(input) => setJson(input)}
        onSubmit={onSubmit}
        show={show}
        snackbarOpen={snackbarOpen}
        snackbarOnClose={() => setSnackbarOpen(false)}
        snackbarMessage="Event Group sucessfully imported"
      />
    </>
  );
};

export default EventGroupImportModal;
