import { ISubjectTasks } from "../Interfaces/ISubjectTasks";
import { ITaskInstance } from "../Interfaces/ITaskInstance";

export class TableTaskHelper {
  public static async convert(
    instances: ITaskInstance[],
  ): Promise<ISubjectTasks[]> {
    const subjectToTasks: { [subjectId: string]: ITaskInstance[] } = {};

    for (const task of instances) {
      subjectToTasks[task.subjectId] = [
        ...(subjectToTasks[task.subjectId] ?? []),
        task,
      ];
    }

    return Object.keys(subjectToTasks).map((subjectId) => ({
      subjectId,
      tasks: subjectToTasks[subjectId],
    }));
  }
}
