import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { getAbilityFromString } from "./AbilityHelpers";
import { AppAbility } from "./AppAbilities";

export function defineAbilitiesFor(
  permissions: string | string[] | undefined,
): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

  if (!permissions) return build();

  if (Array.isArray(permissions)) {
    permissions.forEach((p) => {
      const ability = getAbilityFromString(p);
      can(ability[0], ability[1]);
    });
  } else if (permissions !== undefined) {
    const ability = getAbilityFromString(permissions);
    can(ability[0], ability[1]);
  }

  return build();
}
