import React from "react";
import { Grid2, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { ITableEventDefinitionGroup } from "../../Common/Interfaces/ITableEventDefinitionGroup";
import { useState } from "react";
import IEventDefinition from "../../Common/Interfaces/IEventDefinition";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../App.Styles";
import EventMultiSelect from "./EventMultiSelect";

interface IProps extends IReadOnlyComponentProps {
  eventDefinitionGroup: ITableEventDefinitionGroup;
  eventDefinitions: IEventDefinition[];
  onSave: (eventDefinitionGroup: ITableEventDefinitionGroup) => void;
  onCancel: (eventDefinitionGroupId: string) => void;
}

const EventDefinitionGroupDetail: React.FC<IProps> = ({
  eventDefinitionGroup,
  eventDefinitions,
  onSave,
  onCancel,
  readOnly,
}) => {
  const [selectedEventKeys, setSelectedEventKeys] = useState<string[]>(
    eventDefinitionGroup.eventKeys,
  );

  const onSaveAction = () => {
    onSave({
      ...eventDefinitionGroup,
      eventKeys: selectedEventKeys,
    });
  };

  const onCancelAction = () => {
    setSelectedEventKeys(eventDefinitionGroup.eventKeys);
    onCancel(eventDefinitionGroup.id);
  };

  return (
    <div className={classes.container} id="event_definition_group_detail">
      <Grid2 container>
        <Grid2
          container
          spacing={2}
          className={classNames(classes.itemContainer, classes.extraPadding)}
          size={12}
        >
          <Grid2 size={12}>
            <Typography variant="h5">
              Edit {eventDefinitionGroup.name}{" "}
              <span className={classes.mediumGrey}>
                ({eventDefinitionGroup.groupKey})
              </span>
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Select event definitions or type an event key and press enter to
              add events to this group.
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <EventMultiSelect
              eventDefinitions={eventDefinitions}
              selectedEvents={selectedEventKeys}
              onChange={(events) => setSelectedEventKeys(events)}
              readOnly={readOnly}
            />
          </Grid2>
          {!readOnly && (
            <Grid2
              className={classNames(
                classes.itemContainer,
                classes.buttonContainer,
                classes.transparentBorderless,
              )}
              size={12}
            >
              <Button
                className={classNames(classes.button, classes.buttonCancel)}
                onClick={onCancelAction}
              >
                Cancel
              </Button>
              <Button
                className={classNames(classes.button, classes.buttonSave)}
                onClick={onSaveAction}
              >
                Save
              </Button>
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </div>
  );
};

export default EventDefinitionGroupDetail;
