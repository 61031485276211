export {};

declare global {
  interface String {
    ellipsis(charCount: number): string;
  }
}

String.prototype.ellipsis = function (charCount: number) {
  if (this.length < charCount) {
    return this.toString();
  }
  return this.substring(0, charCount) + "...";
};
