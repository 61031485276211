import IWorkflowLog from "../Interfaces/IWorkflowLog";
import IPaged from "../Interfaces/IPaged";
import { useClient } from "../../Hooks/useClient";

export default function useWorkflowLogService(): {
  $get: (
    pageNum: number,
    pageSize: number,
    filter: string | undefined,
  ) => Promise<IPaged<IWorkflowLog>>;
} {
  const client = useClient();

  async function $get(
    pageNum: number,
    pageSize: number,
    filter: string | undefined,
  ): Promise<IPaged<IWorkflowLog>> {
    return (await client.$get(
      filter === undefined
        ? `Api/WorkflowLogs?pageSize=${pageSize}&page=${pageNum}`
        : `Api/WorkflowLogs?pageSize=${pageSize}&page=${pageNum}&filter=${filter}`,
    )) as IPaged<IWorkflowLog>;
  }
  return { $get };
}
