import React from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid2,
  Switch,
} from "@mui/material";
import MappableTextField, {
  isQuoted,
  removeQuotes,
  wrapInQuotes,
} from "../../MappableTextField/MappableTextField";
import QuillWrapper from "../../QuillWrapper/QuillWrapper";
import { HtmlEditor } from "../../Editors";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  context: string;
  to: string;
  subject: string;
  body: string;
}

// This comment is added to emails where the html has been directly edited. It is a workaround
// for a bug with react-quill where any html is removed as soon as the component is rendered.
export const htmlComment = "<!-- This is an HTML email -->";

const EmailActivityProperties: React.FC<IProps> = ({
  onPropertiesChange,
  to,
  subject,
  body,
  context,
  readOnly,
}) => {
  const [isHtmlMode, setIsHtmlMode] = React.useState<boolean>(
    body?.includes(htmlComment) ?? false,
  );

  const [isWarningOpen, setIsWarningOpen] = React.useState<boolean>(false);

  const onChange = (setValue: () => void) => {
    setValue();
    updateProperties();
  };

  const updateProperties = () => {
    onPropertiesChange({
      To: to,
      Subject: subject,
      Body: body,
    });
  };

  const onToggleHtml = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      setIsHtmlMode(true);
    } else {
      setIsWarningOpen(true);
    }
  };

  const onWarningCancel = () => {
    setIsWarningOpen(false);
  };

  const onWarningAccept = () => {
    setIsWarningOpen(false);
    setIsHtmlMode(false);
  };

  return (
    <>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="To"
          value={to}
          required
          placeholder="someone@somewhere.com"
          context={context}
          onChange={(value) => onChange(() => (to = value))}
          helpText="The recipient for the email"
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Subject"
          value={subject}
          placeholder="Subject"
          context={context}
          onChange={(value) => onChange(() => (subject = value))}
          helpText="The subject of the email"
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2 size={12}>
        {isHtmlMode ? (
          <HtmlEditor
            value={
              isQuoted(body)
                ? removeQuotes(body).replace(htmlComment, "")
                : body
            }
            onChange={(value) =>
              onChange(() => (body = wrapInQuotes(value + htmlComment)))
            }
            readOnly={readOnly}
          />
        ) : (
          <MappableTextField
            label="Body"
            value={body}
            placeholder="Body"
            context={context}
            onChange={(value) => onChange(() => (body = value))}
            helpText="The body of the email"
            literalComponent={(value, onChange) => (
              <QuillWrapper value={value} onChange={(x) => onChange(x)} />
            )}
            readOnly={readOnly}
          />
        )}
      </Grid2>
      <Grid2 size={12}>
        <Chip
          variant="outlined"
          label={
            <>
              Use HTML Editor
              <Switch
                inputProps={{ "aria-label": "Use HTML Editor" }}
                edge="end"
                checked={isHtmlMode}
                color="primary"
                onChange={onToggleHtml}
              />
            </>
          }
        />
      </Grid2>
      {!readOnly && (
        <Dialog open={isWarningOpen} onClose={onWarningCancel}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will lose any custom HTML or CSS by returning to the visual
              email editor.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={onWarningCancel}>
              Cancel
            </Button>
            <Button color="secondary" onClick={onWarningAccept}>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EmailActivityProperties;
