import React, { useState } from "react";
import { Alert, FormControlLabel, Checkbox } from "@mui/material";
import "../../../Common/Helpers/Extensions/string.extensions";
import { IPersistedItem } from "../../../Interfaces/IPersistedItem";
import { DefinitionType } from "../../../Common/Enums/DefinitionType";
import { EvaluateXPath } from "../../../Common/Helpers/PathEvaluators";
import { ClinicalSafetyAlert } from "../../ClinicalSafetyAlert";
import { classes } from "../../../App.Styles";

interface IProps {
  definition: IPersistedItem;
  open: boolean;
  onClose: () => void;
  action: () => void;
  definitionType: DefinitionType;
  context?: string;
}

const DefinitionDeleteAlert: React.FC<IProps> = ({
  definition,
  open,
  onClose,
  action,
  definitionType,
  context = "",
}) => {
  const [deleteWarningRead, setDeleteWarningRead] = useState<boolean>(false);
  let definitionTypeText;
  switch (definitionType) {
    case DefinitionType.Workflow:
      definitionTypeText = "workflow definition ";
      break;
    case DefinitionType.Task:
      definitionTypeText = "task definition ";
      break;
    case DefinitionType.EventGroup:
      definitionTypeText = "event definition group ";
      break;
  }

  const title =
    definitionType === DefinitionType.Task
      ? `Are you sure you want to delete "${EvaluateXPath(
          definition.name,
          context,
        )}"?`
      : `Are you sure you want to delete "${definition.name}"?`;

  const renderMainWarning = () => {
    if (definitionType === DefinitionType.EventGroup) {
      return (
        <>
          won’t remove it from any task triggers within workflow definitions,
          however any events in this group will no longer trigger those tasks.
        </>
      );
    }

    return (
      <>
        will prevent any currently created tasks for this {definitionTypeText}
        from being changed, and will stop any automated tasks for this{" "}
        {definitionTypeText} from being run.
      </>
    );
  };

  const renderSafetyRiskWarning = () => {
    return (
      <>
        {" "}
        This presents a range of potential risks to patient safety. Please
        contact <a href="mailto:support@airelogic.com">
          support@airelogic.com
        </a>{" "}
        and{" "}
        <a href="mailto:clinical.safety@airelogic.com">
          clinical.safety@airelogic.com
        </a>{" "}
        if you require support with this.
      </>
    );
  };

  return (
    <ClinicalSafetyAlert
      open={open}
      title={title}
      onClose={() => {
        onClose();
        setDeleteWarningRead(false);
      }}
      action={action}
      body={
        <>
          <Alert severity="warning" className={classes.lgAlert}>
            <span>Deleting this {definitionTypeText}</span>
            {renderMainWarning()}
            {renderSafetyRiskWarning()}
          </Alert>
          <span>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deleteWarningRead}
                  onChange={() => setDeleteWarningRead(!deleteWarningRead)}
                  color="primary"
                  required
                />
              }
              label="I have read and understood the above warning"
            />
          </span>
        </>
      }
      confirmationText="Confirm and delete"
      confirmDisabled={!deleteWarningRead}
    />
  );
};

export default DefinitionDeleteAlert;
