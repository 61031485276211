import { ListItemButton, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import WorkflowIcon from "@mui/icons-material/AccountTree";
import ApiIcon from "@mui/icons-material/Book";
import GraphqlIcon from "@mui/icons-material/BookOutlined";
import AireVaultIcon from "@mui/icons-material/LockOutlined";
import DocIcon from "@mui/icons-material/Help";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import RuntimeIcon from "@mui/icons-material/Dns";
import MetricsIcon from "@mui/icons-material/QueryStats";
import GroupIcon from "@mui/icons-material/ListAlt";
import StreamIcon from "@mui/icons-material/List";
import RepoIcon from "@mui/icons-material/FolderOpen";
import MenuIcon from "@mui/icons-material/Menu";
import EventLogIcon from "@mui/icons-material/BugReport";
import WorkflowLogsIcon from "@mui/icons-material/BugReportOutlined";
import React, { useState } from "react";
import useOnClickOutside from "use-onclickoutside";
import { AbilityContext, Can } from "../Authorisation/AbilityContext";
import { useAbility } from "@casl/react";
import BeforeUnloadCheckerLink from "../BeforeUnloadCheckerLink";
import { useAuth } from "../../Hooks/useAuth";
import { useTenantContext } from "../../Tenant/Tenant.Context";
import { useAppContext } from "../../App/App.Context";
import theme from "../../App/App.Theme";
import { classes } from "../../App.Styles";

const Navigation = (): JSX.Element => {
  const ref = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [drawerOpen, setOpen] = React.useState(false);
  const { signOut } = useAuth();
  const tenantContext = useTenantContext();
  const [tenantKey] = useState(tenantContext.tenantKey);
  const ability = useAbility(AbilityContext);

  const appContext = useAppContext();
  const aireVaultEnabled = appContext.aireVaultEnabled;
  const aireVaultManagementApiUri = appContext.aireVaultManagementApiUri;
  const authority = appContext.authority;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    handleClose();
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={classes.root}>
      <div className={classes.toolbar} />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            id="button_menu"
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <BeforeUnloadCheckerLink to="/" className={classes.link}>
            <img
              style={{ height: "22pt", paddingTop: "4pt" }}
              src="logo.svg"
              alt=""
            />
          </BeforeUnloadCheckerLink>
          <Typography style={{ flex: 1 }} />
          <IconButton onClick={handleMenu} color="inherit">
            <Typography variant="h6" id="tenant-key-header">
              {tenantKey}
            </Typography>
            <AccountCircle style={{ marginLeft: "10pt" }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={menuOpen}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout} id="log-out">
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Log Out</ListItemText>
            </MenuItem>
            <a
              href={`${authority}/dashboard/account`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
              aria-label="Account Settings"
            >
              <MenuItem id="change-password">
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Account Settings</ListItemText>
              </MenuItem>
            </a>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        ref={ref}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        PaperProps={{ onClick: handleDrawerClose }}
      >
        <div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        {(ability.can("view", "workflow") ||
          ability.can("view", "eventDefinition") ||
          ability.can("view", "eventDefinitionGroup") ||
          ability.can("view", "repository")) && (
          <DividerWithTitle>Management</DividerWithTitle>
        )}
        <List>
          {tenantKey && (
            <>
              <Can I="view" a="workflow">
                <BeforeUnloadCheckerLink
                  to="/WorkflowDefinitions"
                  className={classes.link}
                  aria-label="Workflow Definitions"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <WorkflowIcon />
                    </ListItemIcon>
                    <ListItemText primary="Workflow Definitions" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <Can I="view" an="eventDefinition">
                <BeforeUnloadCheckerLink
                  to="/EventDefinitions"
                  className={classes.link}
                  aria-label="Event Definitions"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <StreamIcon />
                    </ListItemIcon>
                    <ListItemText primary="Event Definitions" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <Can I="view" an="eventDefinitionGroup">
                <BeforeUnloadCheckerLink
                  to="/EventDefinitionGroups"
                  className={classes.link}
                  aria-label="Event Definition Groups"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Event Definition Groups" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <Can I="view" a="repository">
                <BeforeUnloadCheckerLink
                  to="/RepoManagement"
                  className={classes.link}
                  aria-label="Repositories"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <RepoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Repositories" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              {(ability.can("view", "event") ||
                ability.can("view", "workflowLog") ||
                ability.can("view", "task")) && (
                <DividerWithTitle>Runtime</DividerWithTitle>
              )}
              <Can I="view" a="task">
                <BeforeUnloadCheckerLink
                  to="/Tasks"
                  className={classes.link}
                  aria-label="Tasks"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <RuntimeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tasks" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <Can I="view" an="metrics">
                <BeforeUnloadCheckerLink
                  to="/Metrics"
                  className={classes.link}
                  aria-label="Metrics"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <MetricsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Metrics" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <Can I="view" an="event">
                <BeforeUnloadCheckerLink
                  to="/EventLog"
                  className={classes.link}
                  aria-label="Event Log"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <EventLogIcon />
                    </ListItemIcon>
                    <ListItemText primary="Event Log" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <Can I="view" a="workflowLog">
                <BeforeUnloadCheckerLink
                  to="/WorkflowLogs"
                  className={classes.link}
                  aria-label="Workflow Logs"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <WorkflowLogsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Workflow Logs" />
                  </ListItemButton>
                </BeforeUnloadCheckerLink>
              </Can>
              <DividerWithTitle>API</DividerWithTitle>
              <BeforeUnloadCheckerLink
                to="/Api-Documentation"
                className={classes.link}
                aria-label="REST API"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ApiIcon />
                  </ListItemIcon>
                  <ListItemText primary="REST API" />
                </ListItemButton>
              </BeforeUnloadCheckerLink>
              <a
                href="/Api/graphql/v2"
                className={classes.link}
                aria-label="GraphQL API"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <GraphqlIcon />
                  </ListItemIcon>
                  <ListItemText primary="GraphQL API" />
                </ListItemButton>
              </a>
              {aireVaultEnabled && (
                <a
                  href={aireVaultManagementApiUri}
                  className={classes.link}
                  aria-label="AireVault API"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <AireVaultIcon />
                    </ListItemIcon>
                    <ListItemText primary="AireVault API" />
                  </ListItemButton>
                </a>
              )}
              <DividerWithTitle>Documentation</DividerWithTitle>
            </>
          )}
          <a
            href="https://docs.aireflow.co.uk/"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
            aria-label="Docs"
          >
            <ListItemButton>
              <ListItemIcon>
                <DocIcon />
              </ListItemIcon>
              <ListItemText primary="Docs" />
            </ListItemButton>
          </a>
        </List>
      </Drawer>
    </div>
  );
};

const DividerWithTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <Divider />
    <ListItem>
      <Typography variant="h6">{children}</Typography>
    </ListItem>
  </>
);

export default Navigation;
