import React from "react";
import { Accordion, AccordionDetails, Grid2, TextField } from "@mui/material";
import MappableTextField from "../../MappableTextField/MappableTextField";
import { PlainTextEditor } from "../../Editors";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import AccordionSummaryWithDelete from "../../Delete/AccordionSummaryWithDelete";
import IHeaderDefinition from "../../../Common/Interfaces/IHeaderDefinition";
import ListIcon from "@mui/icons-material/List";
import Help from "../../Help/Help";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  context: string;
  url: string;
  contentType: string;
  body: string;
  headers: IHeaderDefinition[];
}

const HttpNotificationMethodProperties: React.FC<IProps> = ({
  onPropertiesChange,
  url,
  contentType,
  body,
  context,
  readOnly,
  headers = [],
}) => {
  const onChange = (setValue: () => void) => {
    setValue();
    updateProperties();
  };

  const updateProperties = () => {
    onPropertiesChange({
      Url: url,
      ContentType: contentType,
      Body: body,
      Headers: JSON.stringify(headers),
    });
  };

  const onAddHeader = () => {
    headers.push({
      key: "",
      value: "",
    });
    updateProperties();
  };

  const onHeaderValueChange = (index: number, headerValue: string) => {
    headers[index].value = headerValue;
    updateProperties();
  };

  const onHeaderKeyChange = (index: number, headerKey: string) => {
    headers[index].key = headerKey;
    updateProperties();
  };

  const onDeleteHeader = (index: number) => {
    headers.splice(index, 1);
    updateProperties();
  };

  return (
    <>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Url"
          value={url}
          required
          placeholder=""
          context={context}
          onChange={(value) => onChange(() => (url = value))}
          helpText="The url that will be POSTed to"
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Content Type"
          value={contentType}
          placeholder="e.g. application/json"
          context={context}
          onChange={(value) => onChange(() => (contentType = value))}
          helpText="The content-type of the data that will be POSTed"
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2 size={12}>
        <Grid2 container spacing={1} className={classes.itemChild}>
          <Grid2 size={12}>
            <ItemAccordion
              name="Headers"
              count={headers.length}
              icon={() => <ListIcon />}
              onAdd={onAddHeader}
              readOnly={readOnly}
            >
              {headers.map((header, index) => (
                <Accordion id="header" key={index}>
                  <AccordionSummaryWithDelete
                    name={header.key ?? ""}
                    extraText={header.value ?? ""}
                    placeholder="Header"
                    onDelete={() => onDeleteHeader(index)}
                    readOnly={readOnly}
                  />
                  <AccordionDetails>
                    <Grid2
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid2 size={6}>
                        <TextField
                          value={header.key}
                          label="Key"
                          required
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            onHeaderKeyChange(index, e.target.value)
                          }
                          slotProps={{
                            input: {
                              startAdornment: (
                                <Help text="The key of the header" />
                              ),
                            },
                            htmlInput: {
                              "aria-label": "HeaderKey",
                            },
                          }}
                          disabled={readOnly}
                        />
                      </Grid2>
                      <Grid2 size={6}>
                        <MappableTextField
                          label="Value"
                          value={header.value}
                          required
                          placeholder=""
                          context={context}
                          onChange={(updated) =>
                            onHeaderValueChange(index, updated)
                          }
                          helpText="The value of the header"
                          readOnly={readOnly}
                        />
                      </Grid2>
                    </Grid2>
                  </AccordionDetails>
                </Accordion>
              ))}
            </ItemAccordion>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <PlainTextEditor
          onChange={(value) => onChange(() => (body = value))}
          value={body}
          readOnly={readOnly}
        />
      </Grid2>
    </>
  );
};

export default HttpNotificationMethodProperties;
