import * as React from "react";
import {
  Popper,
  Fade,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import useOnClickOutside from "use-onclickoutside";
import classNames from "classnames";
import { classes } from "../../App.Styles";

interface IProps {
  required?: boolean;
  value: string;
  name: string;
  paperStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onSave: (name: string) => void;
  component: (
    listener: (event: React.MouseEvent<HTMLElement>) => void,
  ) => JSX.Element;
  validate?: (value: string) => boolean;
}

const PopupTextEdit: React.FC<IProps> = ({
  required,
  value,
  name,
  paperStyle,
  inputStyle,
  onSave,
  component,
  validate,
}) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>();
  const [editedName, setEditedName] = React.useState(value);

  const isError = () => {
    if (required && !editedName) return true;
    return validate ? !validate(editedName) : false;
  };

  React.useEffect(() => {
    setEditedName(value);
  }, [value]);

  const ref = React.useRef(null);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClickOutside = () => {
    setAnchor(undefined);
  };

  const onCancelClick = () => {
    setAnchor(undefined);
    setEditedName(value);
  };

  const onSaveClick = () => {
    setAnchor(undefined);
    onSave(editedName);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <React.Fragment>
      {component(onClick)}
      <Popper
        style={{ zIndex: 1500 }}
        open={anchor !== undefined}
        anchorEl={anchor}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              ref={ref}
              className={classes.popupPaper}
              elevation={6}
              style={paperStyle}
            >
              <Typography variant="h6" color="secondary">
                {name}
              </Typography>
              <TextField
                error={isError()}
                className={classes.popupTextField}
                slotProps={{
                  htmlInput: { style: inputStyle },
                }}
                value={editedName}
                onChange={(event) => setEditedName(event.target.value)}
                variant="standard"
              />
              <div className={classes.buttonContainer}>
                <Button
                  onClick={onCancelClick}
                  className={classNames(classes.button, classes.buttonCancel)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isError()}
                  onClick={onSaveClick}
                  className={classNames(classes.button, classes.buttonSave)}
                >
                  Save
                </Button>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default PopupTextEdit;
