import { useTheme } from "@mui/material";
import React from "react";

interface IProps {
  className?: string;
}

const IconOutput: React.FC<IProps> = ({ className }) => {
  const theme = useTheme();
  return (
    <>
      <svg
        className={className}
        width="22"
        height="22"
        viewBox="-2 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          d="M2 8V5C2 3.34 3.34 2 5 2H19C20.66 2 22 3.34 22 5V19C22 20.66 20.66 22 19 22H5C3.34 22 2 20.66 2 19V16"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={theme.palette.primary.main}
          d="M2 8V5C2 3.34 3.34 2 5 2H19C20.66 2 22 3.34 22 5V19C22 20.66 20.66 22 19 22H5C3.34 22 2 20.66 2 19V16"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={theme.palette.primary.main}
          d="M10 7L5 12L10 17"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={theme.palette.primary.main}
          d="M18 12H5"
        ></path>
      </svg>
    </>
  );
};

export default IconOutput;
