import React, { useCallback, useEffect } from "react";
import { EvaluateXPath } from "../../Common/Helpers/PathEvaluators";
import { Tooltip, Chip, Switch, Typography } from "@mui/material";
import { EditingMode } from "../../Common/Enums/EditingMode";
import CodeIcon from "@mui/icons-material/Code";
import LiteralIcon from "@mui/icons-material/TextFormat";
import debounce from "lodash.debounce";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  mode: EditingMode;
  expression: string;
  context: string;
  onModeChange: (mode: EditingMode) => void;
}

const ExpressionPreviewSwitch: React.FC<IProps> = ({
  context,
  expression,
  mode,
  onModeChange,
  readOnly,
}) => {
  const [resultEvaluated, setResultEvaluated] = React.useState<
    string | undefined
  >();

  const debouncedEvaluate = useCallback(
    debounce(
      (expression, context) => {
        setResultEvaluated(EvaluateXPath(expression, context));
      },
      200,
      { leading: true },
    ),
    [],
  );

  useEffect(() => {
    if (mode === EditingMode.Expression) {
      debouncedEvaluate(expression, context);
    }

    return () => debouncedEvaluate.cancel();
  }, [context, expression, mode, debouncedEvaluate]);

  const getPreview = () => {
    if (!context) return "No example input provided";

    return resultEvaluated ? resultEvaluated : "No match";
  };

  const onClick = () => {
    onModeChange(
      mode === EditingMode.Literal
        ? EditingMode.Expression
        : EditingMode.Literal,
    );
  };

  return (
    <Chip
      disabled={readOnly}
      icon={
        mode === EditingMode.Literal ? (
          <LiteralIcon className={classes.mediumGrey} />
        ) : (
          <CodeIcon className={classes.mediumGrey} />
        )
      }
      label={
        <React.Fragment>
          <span>
            {mode === EditingMode.Expression && (
              <Tooltip title={getPreview()}>
                <Typography
                  display="inline"
                  variant="caption"
                  className={classes.previewText}
                >
                  {getPreview()}
                </Typography>
              </Tooltip>
            )}
          </span>
          <span>
            <Tooltip title="Toggle XPath Mode">
              <Switch
                aria-label="XPath Mode"
                edge="end"
                checked={mode === EditingMode.Expression}
                color="primary"
                onClick={onClick}
              />
            </Tooltip>
          </span>
        </React.Fragment>
      }
      clickable
      variant="outlined"
      data-testid="expression-preview-chip"
    />
  );
};

export default ExpressionPreviewSwitch;
