import React, { useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";
import { useUnsavedChangesContext } from "../Common/Context/UnsavedChangesContext";

interface BeforeUnloadCheckerLinkProps extends LinkProps {
  children: React.ReactNode;
}

function BeforeUnloadCheckerLink({
  children,
  ...props
}: BeforeUnloadCheckerLinkProps): JSX.Element {
  const { editedIds, clearEditedIds } = useUnsavedChangesContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (editedIds.size > 0) {
        const shouldContinue = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?",
        );

        if (shouldContinue) {
          clearEditedIds();
        } else {
          event.preventDefault();
        }
      }
    },
    [editedIds],
  );

  return (
    <Link {...props} onClick={handleClick}>
      {children}
    </Link>
  );
}

export default BeforeUnloadCheckerLink;
