import React, { useState } from "react";
import {
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import IVersionedTaskDefinition from "../../Common/Interfaces/IVersionedTaskDefinition";
import IWorkflowDefinition from "../../Common/Interfaces/IWorkflowDefinition";
import { IRepository } from "../../Common/Interfaces/IRepository";
import { ApiActionType } from "../../Common/Enums/ApiActionType";
import { DefinitionType } from "../../Common/Enums/DefinitionType";
import { ClinicalSafetyAlert } from "../ClinicalSafetyAlert";
import { styled } from "@mui/material/styles";
import IEventDefinitionGroup from "../../Common/Interfaces/IEventDefinitionGroup";
import { classes } from "../../App.Styles";

interface IProps {
  apiActionType:
    | ApiActionType.Update
    | ApiActionType.Delete
    | ApiActionType.Sync;
  open: boolean;
  repository: IRepository;
  workflowDefinitionsToDelete: IWorkflowDefinition[];
  taskDefinitionsToDelete: IVersionedTaskDefinition[];
  eventDefinitionGroupsToDelete: IEventDefinitionGroup[];
  onClose: () => void;
  action: (repository: IRepository, force: boolean) => Promise<void>;
}

const RepositoryAlert: React.FC<IProps> = ({
  apiActionType,
  open,
  repository,
  workflowDefinitionsToDelete,
  taskDefinitionsToDelete,
  eventDefinitionGroupsToDelete,
  onClose,
  action,
}) => {
  const [deleteWarningRead, setDeleteWarningRead] = useState<boolean>(false);
  const [syncWarningRead, setSyncWarningRead] = useState<boolean>(false);
  const [updateWarningRead, setUpdateWarningRead] = useState<boolean>(false);

  const setWarningRead = (value: boolean) => {
    switch (apiActionType) {
      case ApiActionType.Delete:
        setDeleteWarningRead(value);
        break;
      case ApiActionType.Sync:
        setSyncWarningRead(value);
        break;
      case ApiActionType.Update:
        setUpdateWarningRead(value);
        break;
    }
  };

  const getTitle = (): string => {
    let apiActionTypeString: string;
    switch (apiActionType) {
      case ApiActionType.Delete:
        apiActionTypeString = "delete";
        break;
      case ApiActionType.Sync:
        apiActionTypeString = "sync";
        break;
      case ApiActionType.Update:
        apiActionTypeString = "update";
        break;
    }

    return `Are you sure you want to ${apiActionTypeString} "${repository.name}"?`;
  };

  const getConfirmationText = (): string => {
    switch (apiActionType) {
      case ApiActionType.Delete:
        return `Confirm and delete`;
      case ApiActionType.Sync:
        return `Confirm and sync`;
      case ApiActionType.Update:
        return `Confirm and update`;
    }
  };

  const getConfirmDisabled = (): boolean => {
    switch (apiActionType) {
      case ApiActionType.Delete:
        return !deleteWarningRead;
      case ApiActionType.Sync:
        return !syncWarningRead;
      case ApiActionType.Update:
        return !updateWarningRead;
    }
  };

  const LargeTextTooltip = styled(Tooltip)(() => ({
    ["&.tooltip"]: {
      fontSize: 16,
      maxWidth: "none",
      backgroundColor: "rgba(97, 97, 97, 1)",
    },
    ["&.arrow"]: {
      color: "rgba(97, 97, 97, 1)",
    },
  }));

  const renderDefinitionsToDeleteTooltip = (definitionType: DefinitionType) => {
    let tooltipText: string;
    let definitionsToDelete:
      | IWorkflowDefinition[]
      | IVersionedTaskDefinition[]
      | IEventDefinitionGroup[];
    switch (definitionType) {
      case DefinitionType.Workflow:
        definitionsToDelete = workflowDefinitionsToDelete;
        tooltipText = " workflow definition(s)";
        break;
      case DefinitionType.Task:
        definitionsToDelete = taskDefinitionsToDelete;
        tooltipText = " task definition(s)";
        break;
      case DefinitionType.EventGroup:
        definitionsToDelete = eventDefinitionGroupsToDelete;
        tooltipText = " event definition group(s)";
        break;
    }
    return (
      <LargeTextTooltip
        arrow
        title={
          <>
            <Typography color="inherit">{`${definitionType}(s) to be deleted:`}</Typography>
            {definitionsToDelete.map((definition) => {
              let key: string;
              switch (definitionType) {
                case DefinitionType.Workflow:
                  key = (definition as IWorkflowDefinition).key;
                  break;
                case DefinitionType.Task:
                  key = `${
                    (definition as IVersionedTaskDefinition).taskDefinition
                      .taskKey
                  } (${(definition as IVersionedTaskDefinition).version})`;
                  break;
                case DefinitionType.EventGroup:
                  key = (definition as IEventDefinitionGroup).groupKey;
                  break;
              }

              return (
                <Typography color="inherit" key={key}>
                  - {key}
                </Typography>
              );
            })}
          </>
        }
      >
        <span style={{ borderBottom: "dotted", cursor: "pointer" }}>
          {tooltipText}
        </span>
      </LargeTextTooltip>
    );
  };

  const renderSafetyRiskWarning = () => {
    return (
      <>
        {" "}
        This presents a range of potential risks to patient safety. Please
        contact <a href="mailto:support@airelogic.com">
          support@airelogic.com
        </a>{" "}
        and{" "}
        <a href="mailto:clinical.safety@airelogic.com">
          clinical.safety@airelogic.com
        </a>{" "}
        if you require support with this.
      </>
    );
  };

  const renderCreatedTasksWarning = (workflow: boolean, task: boolean) => {
    let definitionsType = "";

    if (workflow && !task) {
      definitionsType = "workflow ";
    } else if (!workflow && task) {
      definitionsType = "task ";
    } else if (workflow && task) {
      definitionsType = "workflow and task ";
    }

    return (
      <>
        {" "}
        This will prevent any currently created tasks for these{" "}
        {definitionsType}definitions from being changed, and will stop any
        automated tasks for these {definitionsType}definitions from being run.
      </>
    );
  };

  const renderEventGroupsWarning = () => {
    return (
      <>
        {" "}
        The event groups won’t be removed from any task triggers within workflow
        definitions, however any events in these groups will no longer trigger
        those tasks.
      </>
    );
  };

  const renderDefinitionsWarning = () => {
    let actionText: string;
    let warningConfirmation: () => void;
    let warningChecked: boolean;
    switch (apiActionType) {
      case ApiActionType.Update:
        actionText = "Updating";
        warningConfirmation = () => setUpdateWarningRead(!updateWarningRead);
        warningChecked = updateWarningRead;
        break;
      case ApiActionType.Sync:
        actionText = "Syncing";
        warningConfirmation = () => setSyncWarningRead(!syncWarningRead);
        warningChecked = syncWarningRead;
        break;
      case ApiActionType.Delete:
        actionText = "Deleting";
        warningConfirmation = () => setDeleteWarningRead(!deleteWarningRead);
        warningChecked = deleteWarningRead;
        break;
    }
    const beingDeltedSpan = <span> being deleted. </span>;
    const beingDeltedOneOrMoreSpan = (
      <span> being deleted, as well as one or more </span>
    );
    return (
      <>
        <Alert severity="warning" className={classes.lgAlert}>
          <span>{actionText} this repository will result in one or more </span>
          {/* Only workflows to be deleted */}
          {workflowDefinitionsToDelete.length > 0 &&
            taskDefinitionsToDelete.length == 0 &&
            eventDefinitionGroupsToDelete.length == 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Workflow)}
                {beingDeltedSpan}
                {renderCreatedTasksWarning(true, false)}
                {renderSafetyRiskWarning()}
              </>
            )}
          {/* Only tasks to be deleted */}
          {taskDefinitionsToDelete.length > 0 &&
            workflowDefinitionsToDelete.length == 0 &&
            eventDefinitionGroupsToDelete.length == 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Task)}
                {beingDeltedSpan}
                {renderCreatedTasksWarning(false, true)}
                {renderSafetyRiskWarning()}
              </>
            )}
          {/* Only event groups to be deleted */}
          {eventDefinitionGroupsToDelete.length > 0 &&
            workflowDefinitionsToDelete.length == 0 &&
            taskDefinitionsToDelete.length == 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.EventGroup)}
                {beingDeltedSpan}
                {renderEventGroupsWarning()}
                {renderSafetyRiskWarning()}
              </>
            )}
          {/* Workflows and Tasks to be deleted */}
          {taskDefinitionsToDelete.length > 0 &&
            workflowDefinitionsToDelete.length > 0 &&
            eventDefinitionGroupsToDelete.length == 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Workflow)}
                {beingDeltedOneOrMoreSpan}
                {renderDefinitionsToDeleteTooltip(DefinitionType.Task)}
                {beingDeltedSpan}
                {renderCreatedTasksWarning(true, true)}
                {renderSafetyRiskWarning()}
              </>
            )}
          {/* Workflows and Event Groups to be deleted */}
          {workflowDefinitionsToDelete.length > 0 &&
            eventDefinitionGroupsToDelete.length > 0 &&
            taskDefinitionsToDelete.length == 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Workflow)}
                {beingDeltedOneOrMoreSpan}
                {renderDefinitionsToDeleteTooltip(DefinitionType.EventGroup)}
                {beingDeltedSpan}
                {renderCreatedTasksWarning(true, false)}
                {renderEventGroupsWarning()}
                {renderSafetyRiskWarning()}
              </>
            )}
          {/* Tasks and Event Groups to be deleted */}
          {taskDefinitionsToDelete.length > 0 &&
            eventDefinitionGroupsToDelete.length > 0 &&
            workflowDefinitionsToDelete.length == 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Task)}
                {beingDeltedOneOrMoreSpan}
                {renderDefinitionsToDeleteTooltip(DefinitionType.EventGroup)}
                {beingDeltedSpan}
                {renderCreatedTasksWarning(false, true)}
                {renderEventGroupsWarning()}
                {renderSafetyRiskWarning()}
              </>
            )}
          {/* Workflows, Tasks, and Event Groups to be deleted */}
          {taskDefinitionsToDelete.length > 0 &&
            workflowDefinitionsToDelete.length > 0 &&
            eventDefinitionGroupsToDelete.length > 0 && (
              <>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Workflow)}
                <span> being deleted, one or more </span>
                {renderDefinitionsToDeleteTooltip(DefinitionType.Task)}
                {beingDeltedOneOrMoreSpan}
                {renderDefinitionsToDeleteTooltip(DefinitionType.EventGroup)}
                {beingDeltedSpan}
                {renderCreatedTasksWarning(true, true)}
                {renderEventGroupsWarning()}
                {renderSafetyRiskWarning()}
              </>
            )}
        </Alert>
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={warningChecked}
                onChange={warningConfirmation}
                color="primary"
                required
              />
            }
            label="I have read and understood the above warning"
          />
        </span>
      </>
    );
  };

  return (
    <ClinicalSafetyAlert
      open={open}
      title={getTitle()}
      onClose={() => {
        onClose();
        setWarningRead(false);
      }}
      action={() => action(repository, true)}
      body={renderDefinitionsWarning()}
      confirmationText={getConfirmationText()}
      confirmDisabled={getConfirmDisabled()}
    />
  );
};

export default RepositoryAlert;
