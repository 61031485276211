import * as React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const Delta = Quill.import("delta");
const Break = Quill.import("blots/break");
const Embed = Quill.import("blots/embed");

class SmartBreak extends Break {
  length() {
    return 1;
  }
  value() {
    return "\n";
  }

  insertInto(parent: any, ref: any) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

Quill.register(SmartBreak, true);

const modules = {
  clipboard: {
    matchers: [
      [
        "BR",
        () => {
          const newDelta = new Delta();
          newDelta.insert({ break: "" });
          return newDelta;
        },
      ],
    ],
    matchVisual: false,
  },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (range: any) {
          SmartBreak.blotName = "break";
          SmartBreak.tagName = "BR";
          const quill = (this as any).quill;
          const currentLeaf = quill.getLeaf(range.index)[0];
          const nextLeaf = quill.getLeaf(range.index + 1)[0];
          quill.insertEmbed(range.index, "break", true, "user");
          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
            quill.insertEmbed(range.index, "break", true, "user");
          }
          quill.setSelection(range.index + 1, "silent");
        },
      },
    },
  },
};

interface IProps {
  setRef?: (ref: ReactQuill) => void;
  id?: string;
  style?: any;
  className?: string;
  value: string;
  onChange: (code: string) => void;
  onFocus?: () => void;
  onChangeSelection?: (range: any) => void;
}

const QuillWrapper: React.FC<IProps> = ({
  setRef,
  id,
  style,
  className,
  value,
  onChange,
  onFocus,
  onChangeSelection,
}) => {
  return (
    <ReactQuill
      modules={modules}
      id={id}
      theme="snow"
      ref={setRef}
      style={style}
      className={className}
      defaultValue={value}
      onChange={onChange}
      onFocus={onFocus}
      onChangeSelection={onChangeSelection}
    />
  );
};

export default QuillWrapper;
