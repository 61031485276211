import PulseLoader from "react-spinners/PulseLoader";
import * as React from "react";
import theme from "../../App/App.Theme";
import { classes } from "../../App.Styles";

interface IProps {
  visible: boolean;
}

const Loading: React.FC<IProps> = ({ visible }) => {
  return visible ? (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingInnerContainer}>
        <PulseLoader size={30} color={theme.palette.primary.main} />
      </div>
    </div>
  ) : null;
};

export default Loading;
