import * as React from "react";
import {
  IconButton,
  Popper,
  Fade,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CopyToClipboard from "react-copy-to-clipboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from "@mui/icons-material/Close";
import useOnClickOutside from "use-onclickoutside";
import { classes } from "../../App.Styles";

interface IProps {
  text: JSX.Element | string;
  displayIcon?: () => JSX.Element;
  copyableValue?: string;
}

const Help: React.FC<IProps> = ({ text, displayIcon, copyableValue }) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>();

  const ref = React.useRef(null);
  const onClickHelp = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClickOutside = () => {
    setAnchor(undefined);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <React.Fragment>
      <IconButton onClick={onClickHelp}>
        {displayIcon ? (
          displayIcon()
        ) : (
          <HelpIcon className={classes.mediumGrey} />
        )}
      </IconButton>
      <Popper
        style={{ zIndex: 1500 }}
        open={anchor !== undefined}
        anchorEl={anchor}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={ref} className={classes.helpPaper} elevation={6}>
              <div style={{ display: "flex", backgroundColor: "#F0F0F0" }}>
                <Typography style={{ flex: 1 }} />
                {copyableValue && (
                  <CopyToClipboard text={copyableValue}>
                    <Tooltip title="Copy to clipboard">
                      <IconButton size="small">
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                )}
                <Tooltip title="Close window">
                  <IconButton size="small" onClick={handleClickOutside}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.helpText}>{text}</div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default Help;
