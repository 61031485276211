import { Grid2, TextField, Typography } from "@mui/material";
import React from "react";
import { classes } from "../../App.Styles";

interface IProps {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const TaskPropertyItem: React.FC<IProps> = ({
  label,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Grid2 container key={label} width={"100%"}>
      <Grid2
        container
        justifyContent="flex-end"
        alignItems="center"
        size={{
          xs: 3,
          md: 4,
          xl: 5,
        }}
      >
        <Typography className={classes.taskPropertyLabel}>{label}</Typography>
      </Grid2>
      <Grid2
        container
        size={{
          xs: 9,
          md: 8,
          xl: 7,
        }}
      >
        <TextField
          disabled={disabled}
          slotProps={{
            htmlInput: { "aria-label": label },
          }}
          className={classes.taskPropertyField}
          value={value}
          onChange={
            onChange ? (event) => onChange(event.target.value) : undefined
          }
          variant="standard"
        />
      </Grid2>
    </Grid2>
  );
};

export default TaskPropertyItem;
