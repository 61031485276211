export enum AireFrameEventType {
  SubjectCreated = "subjectcreated",
  SubjectUpdated = "subjectupdated",
  SubjectAssignedToStructuralEntity = "subjectassignedtostructuralentity",
  SubjectRemovedFromStructuralEntity = "subjectremovedfromstructuralentity",
  StructuralEntityCreated = "structuralentitycreated",
  StructuralEntityUpdated = "structuralentityupdated",
  StructuralEntityDeleted = "structuralentitydeleted",
}

export const AireFrameEventTypeDisplayNames = {
  [AireFrameEventType.SubjectCreated]: "Subject Created",
  [AireFrameEventType.SubjectUpdated]: "Subject Updated",
  [AireFrameEventType.SubjectAssignedToStructuralEntity]: "Subject Assigned",
  [AireFrameEventType.SubjectRemovedFromStructuralEntity]: "Subject Removed",
  [AireFrameEventType.StructuralEntityCreated]: "Structural Entity Created",
  [AireFrameEventType.StructuralEntityUpdated]: "Structural Entity Updated",
  [AireFrameEventType.StructuralEntityDeleted]: "Structural Entity Deleted",
};
