import { AbilityContext } from "./Authorisation/AbilityContext";
import { useAbility } from "@casl/react";
import DefinitionsList from "./Definitions/DefinitionsList";
import EventDefinitions from "./EventDefinitions/EventDefinitions";
import EventLog from "./EventLog/EventLog";
import WorkflowLogs from "./WorkflowLogs/WorkflowLogs";
import Repositories from "./Repositories/Repositories";
import Metrics from "./Metrics/Metrics";
import EventDefinitionGroups from "./EventDefinitionGroups/EventDefinitionGroups";
import NoRole from "./NoRolePage";
import TaskView from "./TaskView";

const Home = (): JSX.Element => {
  const ability = useAbility(AbilityContext);

  if (ability.can("view", "workflow")) {
    return <DefinitionsList />;
  } else if (ability.can("view", "eventDefinition")) {
    return <EventDefinitions />;
  } else if (ability.can("view", "eventDefinitionGroup")) {
    return <EventDefinitionGroups />;
  } else if (ability.can("view", "repository")) {
    return <Repositories />;
  } else if (ability.can("view", "task")) {
    return <TaskView />;
  } else if (ability.can("view", "metrics")) {
    return <Metrics />;
  } else if (ability.can("view", "event")) {
    return <EventLog />;
  } else if (ability.can("view", "workflowLog")) {
    return <WorkflowLogs />;
  } else {
    return <NoRole />;
  }
};

export default Home;
