import { useClient } from "./useClient";
import { HippityResponse } from "hippity";

export function useFlowTenantService(): {
  get: () => Promise<HippityResponse>;
} {
  const client = useClient();

  async function get(): Promise<HippityResponse> {
    return await client.get("Api/FlowTenant/Current");
  }

  return { get };
}
