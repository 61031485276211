import React from "react";
import { Grid2, MenuItem } from "@mui/material";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import NonMappableSelect from "../Triggers/NonMappableSelect";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  eventKey: string;
  eventDefinitions: IEventDefinition[];
}

const PublishEventActivityProperties: React.FC<IProps> = ({
  onPropertiesChange,
  eventKey,
  eventDefinitions,
  readOnly,
}) => {
  const onChange = (selected: string) => {
    onPropertiesChange({ EventKey: selected });
  };

  const customEventSelectBox = () => {
    return eventDefinitions.map((def, index) => (
      <MenuItem key={`${def.eventKey}_${index}`} value={def.eventKey}>
        {def.name}
      </MenuItem>
    ));
  };

  return (
    <>
      <Grid2 size={{ md: 12, xs: 12 }}>
        <NonMappableSelect
          selectedValue={eventKey}
          label="Published Event"
          helpText="The event this task will publish when it is created"
          selectBox={customEventSelectBox()}
          setSelected={onChange}
          readOnly={readOnly}
        />
      </Grid2>
    </>
  );
};

export default PublishEventActivityProperties;
