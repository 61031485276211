import React from "react";
import { Accordion, AccordionDetails, Grid2 } from "@mui/material";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import AccordionSummaryWithDelete from "../../Delete/AccordionSummaryWithDelete";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import TriggerIcon from "@mui/icons-material/Update";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import TriggerProperties from "./TriggerProperties";
import ITriggeredUpdateDefinition from "../../../Common/Interfaces/ITriggeredUpdateDefinition";
import {
  removeQuotes,
  wrapInQuotes,
} from "../../MappableTextField/MappableTextField";
import { JsonEditor } from "../../Editors";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  triggers: ITriggeredUpdateDefinition[];
  onChange: (triggers: ITriggeredUpdateDefinition[]) => void;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
  workflowDefinitionId: string;
  workflowVersion: number;
  parentTaskKey: string;
}

const TriggeredUpdates: React.FC<IProps> = ({
  triggers,
  onChange,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  workflowDefinitionId,
  workflowVersion,
  parentTaskKey,
  readOnly,
}) => {
  const onAddTrigger = () => {
    triggers.push({
      routingKey: "",
      update: `"${JSON.stringify([{ op: "", path: "", value: "" }], null, 2)}"`,
      selectedEvent: "",
      selectedEventKey: "",
      selectedTaskKey: "",
      selectedTriggerVersion: 0,
    });
    onChange(triggers);
  };

  const onTriggerChange = (
    index: number,
    trigger: ITriggeredUpdateDefinition,
  ) => {
    triggers[index] = trigger;
    onChange(triggers);
  };

  const onDeleteTrigger = (index: number) => {
    triggers.splice(index, 1);
    onChange(triggers);
  };

  const getExtraText = (trigger: ITriggeredUpdateDefinition) => {
    if (trigger.selectedEventKey) {
      return trigger.selectedEventKey;
    }
    return "";
  };

  return (
    <ItemAccordion
      name="Mutations"
      count={triggers.length}
      icon={() => <TriggerIcon />}
      onAdd={onAddTrigger}
      readOnly={readOnly}
    >
      {triggers.map((trigger, index) => (
        <Accordion id={`updateTrigger${index}`} key={index}>
          <AccordionSummaryWithDelete
            name={trigger.routingKey.split(".")[0] ?? ""}
            placeholder="Mutation"
            extraText={getExtraText(trigger)}
            onDelete={() => onDeleteTrigger(index)}
            readOnly={readOnly}
          />
          <AccordionDetails>
            <Grid2
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TriggerProperties
                onTriggerChange={(updated) =>
                  onTriggerChange(index, {
                    routingKey: updated.routingKey,
                    update: triggers[index].update,
                    selectedEvent: updated.selectedEvent,
                    selectedEventKey: updated.selectedEventKey,
                    selectedTaskKey: updated.selectedTaskKey,
                    selectedTriggerVersion: updated.selectedTriggerVersion,
                  })
                }
                triggerDefinition={trigger}
                workflowDefinitions={workflowDefinitions}
                eventDefinitions={eventDefinitions}
                eventDefinitionGroups={eventDefinitionGroups}
                workflowDefinitionId={workflowDefinitionId}
                workflowVersion={workflowVersion}
                parentTaskKey={parentTaskKey}
                readOnly={readOnly}
              />
              <Grid2 size={12}>
                <JsonEditor
                  onChange={(updated) =>
                    onTriggerChange(index, {
                      routingKey: triggers[index].routingKey,
                      update: wrapInQuotes(updated),
                      selectedEvent: triggers[index].selectedEvent,
                      selectedEventKey: triggers[index].selectedEventKey,
                      selectedTaskKey: triggers[index].selectedTaskKey,
                      selectedTriggerVersion:
                        triggers[index].selectedTriggerVersion,
                    })
                  }
                  value={removeQuotes(triggers[index].update)}
                  readOnly={readOnly}
                />
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      ))}
    </ItemAccordion>
  );
};

export default TriggeredUpdates;
