import { HippityResponse } from "hippity";
import { useClient } from "../../Hooks/useClient";
import { IAlertConfig } from "../Interfaces/IAlertConfig";

const apiPath = "Api/Configuration/Alert";

export const useAlertsConfigService = (): {
  get: () => Promise<HippityResponse>;
  update: (config: IAlertConfig) => Promise<HippityResponse>;
  test: () => Promise<HippityResponse>;
} => {
  const client = useClient();

  async function get(): Promise<HippityResponse> {
    return await client.get(apiPath);
  }

  async function update(config: IAlertConfig): Promise<HippityResponse> {
    return await client.put(apiPath, config);
  }

  async function test() {
    return await client.post(apiPath + "/test", {});
  }

  return { get, update, test };
};
