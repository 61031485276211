import React, { ReactNode, useContext, useEffect, useState } from "react";
import { AppService } from "./App.Service";

export interface IAppContext {
  authority: string;
  clientId: string;
  aireFrameCreateFormTaskEnabled: boolean;
  permissionsClaim: string;
  aireVaultEnabled: boolean;
  aireVaultManagementApiUri: string;
  aireGluEnabled: boolean;
  aireGluLogAlertEndpointName: string;
  taskRetryPeriod: number;
}

export const AppContext = React.createContext<IAppContext | undefined>(
  undefined,
);

export const AppContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [appContextState, setAppContextState] = useState<IAppContext>();

  useEffect(() => {
    async function fetchConfiguration() {
      const result = await AppService.Configuration();

      setAppContextState({
        ...result.authenticationConfiguration,
        aireFrameCreateFormTaskEnabled:
          result.aireFrameConfiguration.createFormTaskEnabled ?? false,
        ...result.authorisationConfiguration,
        aireVaultEnabled: result.aireVaultConfiguration.vaultEnabled ?? false,
        aireVaultManagementApiUri:
          result.aireVaultConfiguration.managementApiUri ?? "",
        aireGluEnabled: result.aireGluConfiguration.enabled ?? false,
        aireGluLogAlertEndpointName:
          result.aireGluConfiguration.logAlertEndpointName ?? "",
        taskRetryPeriod: result.taskRetryPeriod,
      });
    }

    fetchConfiguration();
  }, []);

  if (!appContextState) return <p>Loading</p>;

  const appContext: IAppContext = {
    ...appContextState,
  };

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};

export const useAppContext = (): IAppContext => {
  const appContext = useContext(AppContext);

  if (!appContext) throw new Error("App context has not been registered");

  return appContext;
};
