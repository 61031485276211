import * as React from "react";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import Help from "../Help/Help";
import { EditingMode } from "../../Common/Enums/EditingMode";
import ExpressionPreviewSwitch from "./ExpressionPreviewSwitch";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  label: string;
  value: string;
  context: string;
  literalValueOptions?: any;
  placeholder: string;
  helpText: string;
  hidePreviewSwitchAdornment?: boolean;
  helperText?: string;
  required?: boolean;
  multiline?: boolean;
  onChange: (value: string) => void;
  onModeChange?: (mode: EditingMode) => void;
  literalComponent?: (
    value: string,
    onChange: (value: string) => void,
  ) => JSX.Element;
  id?: string;
}

const MappableTextField: React.FC<IProps> = ({
  label,
  value,
  placeholder,
  helpText,
  helperText,
  context,
  literalValueOptions,
  required,
  multiline,
  hidePreviewSwitchAdornment = false,
  onChange,
  onModeChange,
  literalComponent,
  readOnly,
  id,
}) => {
  const [mode, setMode] = React.useState<EditingMode>(
    !value || isQuoted(value) ? EditingMode.Literal : EditingMode.Expression,
  );
  const [error, setError] = React.useState<boolean | undefined>(false);
  const [text, setText] = React.useState<string>("");
  const [skipCount, setSkipCount] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (skipCount) setSkipCount(false);
    if (!skipCount) {
      checkError();
    }
  }, [text]);

  const onChangeMode = (newMode: EditingMode) => {
    setMode(newMode);
    if (newMode === EditingMode.Literal && !isQuoted(value)) {
      onChange(wrapInQuotes(value));
    } else if (newMode === EditingMode.Expression && isQuoted(value)) {
      onChange(removeQuotes(value));
    }
    onModeChange && onModeChange(newMode);
  };

  const onChangeValue = (newValue: string) => {
    onChange(
      mode === EditingMode.Expression ? newValue : wrapInQuotes(newValue),
    );
  };

  const getInterpretedValue = (newValue: string) => {
    return !newValue || mode === EditingMode.Expression
      ? newValue || ""
      : removeQuotes(newValue || "");
  };

  const interpreted = getInterpretedValue(value).toLowerCase();
  const isSelect =
    mode !== EditingMode.Expression &&
    !!literalValueOptions &&
    (!interpreted || Object.keys(literalValueOptions).includes(interpreted));

  if (!!literalComponent && mode === EditingMode.Literal) {
    return (
      <div style={{ position: "relative" }}>
        {literalComponent(getInterpretedValue(value), onChangeValue)}
        <div style={{ position: "absolute", top: 5, right: 14 }}>
          <ExpressionPreviewSwitch
            expression={value}
            context={context}
            mode={mode}
            onModeChange={onChangeMode}
            readOnly={readOnly}
          />
        </div>
      </div>
    );
  }

  const checkError = () => {
    setError(required && !removeQuotes(value) && text.length == 0);
  };

  const handleChange = (event: any) => {
    setText(event.target.value as string);
    onChangeValue(event.target.value as string);
  };

  return (
    <TextField
      disabled={readOnly}
      id={id}
      error={error}
      select={isSelect}
      value={
        isSelect
          ? getInterpretedValue(value).toLowerCase()
          : getInterpretedValue(value)
      }
      label={label}
      required={required}
      helperText={helperText}
      fullWidth
      variant="outlined"
      multiline={multiline}
      placeholder={!readOnly ? placeholder : ""}
      onChange={handleChange}
      slotProps={{
        input: {
          style: {
            fontFamily:
              mode === EditingMode.Expression ? "monospace" : "inherit",
          },
          startAdornment: <Help text={helpText} />,
          endAdornment: hidePreviewSwitchAdornment ? (
            <></>
          ) : (
            <InputAdornment position="end">
              <ExpressionPreviewSwitch
                expression={value}
                context={context}
                mode={mode}
                onModeChange={onChangeMode}
                readOnly={readOnly}
              />
            </InputAdornment>
          ),
        },
        htmlInput: { "aria-label": label },
      }}
    >
      {isSelect &&
        Object.keys(literalValueOptions).map((key, index) => (
          <MenuItem key={index} value={key}>
            {literalValueOptions[key]}
          </MenuItem>
        ))}
    </TextField>
  );
};

export const isQuoted = (value: string): boolean => {
  return !!value && value.startsWith('"') && value.endsWith('"');
};

export const wrapInQuotes = (value: string): string => {
  if (!value) return '""';
  return `"${value}"`;
};

export const removeQuotes = (value: string): string => {
  if (!value) return "";
  return value.substring(1, value.length - 1);
};

export default MappableTextField;
