import { useState } from "react";

interface IResponse {
  body: string;
  status: number;
  message: string;
}

/**
 * Hook to run async actions and handle errors
 */
export function useBackgroundTask(): [
  Error | null,
  (action: () => Promise<void>) => void,
  () => void,
] {
  const [error, setError] = useState<Error | null>(null);

  function run(action: () => Promise<void>): void {
    (async () => {
      setError(null);
      try {
        await action();
      } catch (e) {
        console.error(e);
        const error = e as Error;
        const responseIndex = error.message.indexOf("Response: {");
        if (responseIndex != -1) {
          const responseSub = error.message.substring(
            responseIndex + 10,
            error.message.length,
          );
          const response: IResponse = JSON.parse(responseSub);
          error.message = `${response.status}: ${response.message} - ${response.body}`;
        }
        setError(error);
      }
    })();
  }

  function clearError() {
    setError(null);
  }

  return [error, run, clearError];
}
