import { Typography, Paper } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { classes } from "../App.Styles";

const NoRole = (): JSX.Element => {
  return (
    <Paper
      elevation={4}
      className={classes.table}
      style={{ padding: 20, display: "flex" }}
    >
      <ErrorIcon
        color="secondary"
        style={{
          marginTop: "5px",
          marginRight: "10px",
          verticalAlign: "sub",
        }}
      />
      <Typography variant="h6" aria-label="No Roles">
        You do not have the correct permission(s) assigned to access this page.
        Please contact your system administrator to gain any required access.
      </Typography>
    </Paper>
  );
};

export default NoRole;
