import { User, useAuth as useOidcAuth } from "oidc-react";
import { useCallback } from "react";

export const useAuth = (): {
  signOut: () => void;
  tokenExpired: () => boolean;
  isLoggedIn: () => boolean;
  renewToken: () => Promise<string | undefined>;
  accessToken: string | undefined;
} => {
  const oidcAuth = useOidcAuth();

  const signOut = useCallback(() => {
    oidcAuth!.signOutRedirect({
      id_token_hint: oidcAuth!.userData?.id_token,
    });
  }, [oidcAuth]);

  const tokenExpired = useCallback(() => {
    if (!oidcAuth) return false;

    if (!oidcAuth.userData || oidcAuth.userData.expired) return true;

    return false;
  }, [oidcAuth]);

  const renewToken = useCallback(async () => {
    if (!oidcAuth) return;

    console.log("Renewing token silently...");
    let user: User | null = null;
    try {
      user = await oidcAuth.userManager.signinSilent();
    } catch (e) {
      console.error(
        "An error occured whilst silently signing in. Performing sign-in redirect...",
      );
      console.error(e);
      oidcAuth.userManager.signinRedirect();
      return;
    }

    if (!user) {
      oidcAuth.userManager.signinRedirect();
      return;
    }

    return user.access_token;
  }, [oidcAuth]);

  const isLoggedIn = useCallback(() => {
    return !!oidcAuth?.userData?.access_token;
  }, [oidcAuth]);

  return {
    signOut,
    tokenExpired,
    isLoggedIn,
    renewToken,
    accessToken: oidcAuth?.userData?.access_token,
  };
};
