import { FC, useEffect, useState } from "react";
import { Grid2, Button, Typography } from "@mui/material";
import Filters from "../Filters/Filters";
import MappableTextField from "../../MappableTextField/MappableTextField";
import Tasks from "../Tasks/Tasks";
import ITaskDefinition from "../../../Common/Interfaces/ITaskDefinition";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import ContextInput from "../../ContextInput/ContextInput";
import IWorkflowDefinitionVersion from "../../../Common/Interfaces/IWorkflowDefinitionVersion";
import classNames from "classnames";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import IconInput from "../../Icons/IconInput";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";
import { useUnsavedChangesContext } from "../../../Common/Context/UnsavedChangesContext";

interface IProps extends IReadOnlyComponentProps {
  workflowDefinitionId: string;
  workflowDefinitionVersion: IWorkflowDefinitionVersion;
  workflowDefinition: IWorkflowDefinition;
  onSave: (
    workflowDefinitionId: string,
    workflowDefinitionVersion: IWorkflowDefinitionVersion,
  ) => void;
  onCancel: (workflowDefinitionId: string) => void;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
}

const DefinitionDetail: FC<IProps> = ({
  workflowDefinitionId,
  workflowDefinitionVersion,
  workflowDefinition,
  onSave,
  onCancel,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  readOnly,
}) => {
  const [workflow, setWorkflow] = useState<IWorkflowDefinitionVersion>(
    workflowDefinitionVersion,
  );

  const { addEditedId } = useUnsavedChangesContext();

  useEffect(
    () => setWorkflow(workflowDefinitionVersion),
    [workflowDefinitionVersion],
  );

  const onSubjectIdChange = (value: string) => {
    setWorkflow((workflow) => {
      addEditedId(workflowDefinitionId);
      return {
        ...workflow,
        subjectId: value,
      };
    });
  };

  const onFiltersChange = (filters: string[]) => {
    setWorkflow((workflow) => {
      addEditedId(workflowDefinitionId);
      return {
        ...workflow,
        filters: filters,
      };
    });
  };

  const onExampleContextChange = (context: string) => {
    setWorkflow((workflow) => {
      addEditedId(workflowDefinitionId);
      return {
        ...workflow,
        context: context,
      };
    });
  };

  const onTasksChange = (tasks: ITaskDefinition[]) => {
    setWorkflow((workflow) => {
      addEditedId(workflowDefinitionId);
      return {
        ...workflow,
        tasks: tasks,
      };
    });
  };

  const generateVersionText = () =>
    ` (Version ${workflowDefinitionVersion.version} of 
    ${workflowDefinition.versions.length}, 
    ${workflowDefinitionVersion.enabled ? "Enabled" : "Disabled"})`;

  return (
    <div className={classes.container} id="workflow_definition_detail">
      <Grid2 container>
        <Grid2
          container
          direction="column"
          alignContent="center"
          className={classes.extraPadding}
          size={12}
        >
          <Typography variant="h6">
            {workflowDefinition.name}
            <span className={classes.mediumGrey}>{generateVersionText()}</span>
          </Typography>
        </Grid2>
        <Grid2 className={classes.itemContainer} size={12}>
          <ContextInput
            onChange={onExampleContextChange}
            value={workflow.context}
            icon={() => <IconInput />}
            name="Example Input Context"
            readOnly={readOnly}
          />
        </Grid2>
        <Grid2
          className={classNames(classes.itemContainer, classes.extraPadding)}
          size={12}
        >
          <MappableTextField
            label="Subject ID"
            context={workflow.context}
            value={workflow.subjectId}
            onChange={onSubjectIdChange}
            placeholder="/xml/subject-id/text()"
            helpText="An expression to retrieve the Subject ID from the context can be optionally supplied. This will be overridden if an explicit Subject ID is provided at runtime."
            readOnly={readOnly}
          />
        </Grid2>
        <Grid2 className={classes.itemContainer} size={12}>
          <Tasks
            tasks={workflow.tasks}
            onChange={onTasksChange}
            context={workflow.context}
            eventDefinitions={eventDefinitions}
            eventDefinitionGroups={eventDefinitionGroups}
            workflowDefinitions={workflowDefinitions}
            workflowDefinitionId={workflowDefinitionId}
            workflowDefinitionKey={workflowDefinition.key}
            workflowVersion={workflowDefinitionVersion.version}
            readOnly={readOnly}
          />
        </Grid2>
        <Grid2 className={classes.itemContainer} size={12}>
          <Filters
            filters={workflow.filters}
            onChange={onFiltersChange}
            context={workflow.context}
            readOnly={readOnly}
          />
        </Grid2>
        {!readOnly && (
          <Grid2
            className={classNames(
              classes.itemContainer,
              classes.buttonContainer,
              classes.transparentBorderless,
            )}
            size={12}
          >
            <Button
              className={classNames(classes.button, classes.buttonCancel)}
              onClick={() => onCancel(workflowDefinitionId)}
            >
              Discard Changes
            </Button>
            <Button
              className={classNames(classes.button, classes.buttonSave)}
              onClick={() => onSave(workflowDefinitionId, workflow)}
            >
              Save
            </Button>
          </Grid2>
        )}
      </Grid2>
    </div>
  );
};

export default DefinitionDetail;
