import { Chip, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { classes } from "../../App.Styles";

interface IProps {
  pageSize: number;
  onChange: (pageSize: number) => void;
}

const TaskPageSize: React.FC<IProps> = ({ pageSize, onChange }) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleChange = (value: number) => {
    onChange(value);
    handleClose();
  };
  return (
    <>
      <Tooltip title={"Number of tasks to show"}>
        <Chip
          className={classes.filter}
          icon={<ArrowDropDown />}
          label={`${pageSize} Most Recent`}
          variant={"outlined"}
          clickable
          onClick={handleClick}
          color={"secondary"}
        />
      </Tooltip>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChange(100)}>100</MenuItem>
        <MenuItem onClick={() => handleChange(250)}>250</MenuItem>
        <MenuItem onClick={() => handleChange(500)}>500</MenuItem>
      </Menu>
    </>
  );
};

export default TaskPageSize;
