import { GraphQLClient, Variables } from "graphql-request";
import { useAuthHeaders } from "./useAuthHeaders";

export { gql } from "graphql-request";

export function useGraphQL(): {
  makeQuery: (query: string, variables?: Variables) => Promise<unknown>;
} {
  const { getHeaders } = useAuthHeaders();

  async function makeQuery(
    query: string,
    { variables }: { variables?: Variables } = {},
  ): Promise<unknown> {
    const graphQLClient = new GraphQLClient(`/api/graphql/v2`, {
      headers: await getHeaders(),
    });

    try {
      return await graphQLClient.request(query, variables);
    } catch (error) {
      return error;
    }
  }

  return { makeQuery };
}
