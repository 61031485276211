import { useClient } from "../../Hooks/useClient";
import IEventDefinition from "../Interfaces/IEventDefinition";
import { HippityResponse } from "hippity";

const apiPath = "Api/EventDefinition";

export function useEventDefinitionService(): {
  $get: () => Promise<IEventDefinition[]>;
  $create: (workflowStream: IEventDefinition) => Promise<void>;
  $delete: (id: string) => Promise<void>;
  $update: (workflowStream: IEventDefinition) => Promise<void>;
  update: (workflowStream: IEventDefinition) => Promise<HippityResponse>;
} {
  const client = useClient();

  async function $get(): Promise<IEventDefinition[]> {
    return (await client.$get(apiPath)) as IEventDefinition[];
  }

  async function $create(workflowStream: IEventDefinition): Promise<void> {
    await client.$post(apiPath, workflowStream);
  }

  async function $update(workflowStream: IEventDefinition): Promise<void> {
    await client.$patch(apiPath, workflowStream);
  }

  async function update(
    workflowStream: IEventDefinition,
  ): Promise<HippityResponse> {
    return await client.patch(apiPath, workflowStream);
  }

  async function $delete(id: string): Promise<void> {
    await client.$del([`${apiPath}/{id}`, { id }]);
  }

  return { $get, $create, $delete, $update, update };
}
