import React, { useEffect } from "react";
import IKeyValuePair from "../../../Common/Interfaces/IKeyValuePair";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import StateIcon from "@mui/icons-material/ListAltRounded";
import InitialStatePair from "./InitialStatePair";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  state: any;
  onChange: (state: Record<string, string>) => void;
  context: string;
}

const InitialState: React.FC<IProps> = ({
  state,
  onChange,
  context,
  readOnly,
}) => {
  const [pairs, setPairs] = React.useState<IKeyValuePair[]>([]);

  useEffect(() => {
    if (state) {
      setPairs(
        Object.keys(state).map((key) => {
          return { key: key, value: state[key] };
        }),
      );
    }
  }, [context]);

  const onAddPair = () => {
    setPairs((pairs) => {
      pairs.push({ key: "", value: '""' });
      return pairs;
    });
    onSave();
  };

  const onDeletePair = (index: number) => {
    setPairs((pairs) => {
      pairs.splice(index, 1);
      return pairs;
    });
    onSave();
  };

  const onChangePair = (index: number, setter: () => IKeyValuePair) => {
    setPairs((pairs) => {
      pairs[index] = setter();
      return pairs;
    });
    onSave();
  };

  const onSave = () => {
    const result: any = {};
    for (let index = 0; index < pairs.length; index++) {
      if (pairs[index].key) {
        result[pairs[index].key] = pairs[index].value;
      }
    }
    onChange(result);
  };

  const validate = (pair: IKeyValuePair) => {
    if (pair.key && pairs.filter((p) => p.key == pair.key).length > 0) {
      return "This is a duplicate key.";
    }
    return null;
  };

  return (
    <ItemAccordion
      name="State"
      count={pairs.length}
      icon={() => <StateIcon />}
      onAdd={onAddPair}
      readOnly={readOnly}
    >
      {pairs.map((pair, index) => (
        <div
          key={index}
          className={classes.item}
          style={{ paddingTop: 16, paddingBottom: 16 }}
        >
          <InitialStatePair
            pair={pair}
            key={index}
            state={state}
            index={index}
            onChangePair={onChangePair}
            validate={validate}
            onDelete={() => onDeletePair(index)}
            context={context}
            readOnly={readOnly}
          />
        </div>
      ))}
    </ItemAccordion>
  );
};

export default InitialState;
