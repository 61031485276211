import React, { useEffect } from "react";
import { Grid2, TextField, MenuItem } from "@mui/material";
import IUITaskDefinition from "../../../Common/Interfaces/IUITaskDefinition";
import MappableTextField from "../../MappableTextField/MappableTextField";
import { TaskType } from "../../../Common/Enums/TaskType";
import Help from "../../Help/Help";
import { TaskTypeNameProvider } from "../../../Common/Providers/TaskTypeNameProvider";
import KeyHelper from "../../../Common/Helpers/KeyHelper";
import NonMappableSelect from "../Triggers/NonMappableSelect";
import { useAppContext } from "../../../App/App.Context";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  task: IUITaskDefinition;
  context: string;
  onNameChange: (value: string) => void;
  onTypeChange: (value: TaskType) => void;
  onKeyChange: (value: string) => void;
  onOwnerChange: (value: string) => void;
  onExclusiveChange: (value: boolean) => void;
}

const StandardTaskProperties: React.FC<IProps> = ({
  task,
  context,
  onNameChange,
  onTypeChange,
  onKeyChange,
  onOwnerChange,
  onExclusiveChange,
  readOnly,
}) => {
  const isKeyValid = KeyHelper.ValidateKey(task.taskKey);
  const showKeyErrorMessage = !isKeyValid && !!task.taskKey; // Only show message for non-empty keys
  const [keyManuallyChanged, setKeyManuallyChanged] =
    React.useState<boolean>(false);
  const [taskName, setTaskName] = React.useState<string>(task.name);
  const [taskKey, setTaskKey] = React.useState<string>(task.taskKey);

  const appContext = useAppContext();

  useEffect(() => {
    taskName !== task.name && setTaskName(task.name);
    taskKey !== task.taskKey && setTaskKey(task.taskKey);
  }, [task.name, task.taskKey]);

  const onNameChangeWithKeyGen = (value: string) => {
    // Only generate key if not xpath, task hasn't already been saved, and the key hasn't been manually changed.
    const isQuoted = !!value && value.startsWith('"') && value.endsWith('"');
    if (task.id == "" && isQuoted && !keyManuallyChanged) {
      const taskKey = KeyHelper.GenerateKeyFromValue(value);
      setTaskKey(taskKey);
      onKeyChange(taskKey);
    }

    setTaskName(value);
    onNameChange(value);
  };

  const onKeyChangeWithManualChangeCheck = (value: string) => {
    const generatedTaskKey = KeyHelper.GenerateKeyFromValue(task.name);
    if (generatedTaskKey != value) {
      setKeyManuallyChanged(true);
    }

    setTaskKey(value);
    onKeyChange(value);
  };

  return (
    <>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <TextField
          select
          value={task.taskType}
          label="Task Type"
          fullWidth
          variant="outlined"
          onChange={(e) => onTypeChange(e.target.value as TaskType)}
          disabled={readOnly}
          slotProps={{
            htmlInput: { "aria-label": "Task Type" },
            input: {
              startAdornment: (
                <Help text="Whether the task is manual (to be completed by a person) or automated" />
              ),
            },
          }}
        >
          {Object.values(TaskType)
            .filter(function (taskType) {
              const showTaskType =
                taskType !== TaskType.AireFrameCreateForm ||
                (taskType === TaskType.AireFrameCreateForm &&
                  appContext.aireFrameCreateFormTaskEnabled);
              return showTaskType;
            })
            .map((key, index) => (
              <MenuItem key={index} value={key}>
                {TaskTypeNameProvider(key)}
              </MenuItem>
            ))}
        </TextField>
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <NonMappableSelect
          selectedValue={task.exclusive ? "1" : "0"}
          label={"Exclusive"}
          helpText={
            "Exclusive tasks can only be created once for each unique subject ID/Correlation ID pair."
          }
          selectBox={[
            <MenuItem key={"true"} value={1}>
              True
            </MenuItem>,
            <MenuItem key={"false"} value={0}>
              False
            </MenuItem>,
          ]}
          setSelected={(val) => onExclusiveChange(val === 1)}
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Name"
          context={context}
          value={taskName}
          onChange={onNameChangeWithKeyGen}
          placeholder="/xml/name/text()"
          helpText="An expression to specify the name of the task."
          helperText=" "
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <TextField
          value={taskKey}
          label="Key"
          required
          fullWidth
          disabled={!!task.id || readOnly}
          error={showKeyErrorMessage}
          variant="outlined"
          helperText={KeyHelper.KeyErrorMessage}
          onChange={(e) =>
            onKeyChangeWithManualChangeCheck(e.target.value.replace(/\s/g, "-"))
          }
          placeholder="task-key-123"
          slotProps={{
            input: {
              startAdornment: (
                <Help
                  text={`An immutable identifier for the task, unique across all workflow definitions. ${KeyHelper.KeyErrorMessage}`}
                />
              ),
              style: {
                fontFamily: "monospace",
              },
            },
            htmlInput: { "aria-label": "Key" },
          }}
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Owner"
          context={context}
          value={task.owner}
          onChange={onOwnerChange}
          placeholder="/xml/owner/text()"
          helpText="An (optional) expression to specify the owner of the task."
          readOnly={readOnly}
        />
      </Grid2>
    </>
  );
};

export default StandardTaskProperties;
