import React from "react";
import PopupTextEdit from "../PopupTextEdit/PopupTextEdit";
import FilterBase from "./FilterBase";

interface IProps {
  value?: string;
  name: string;
  tooltip: string;
  onChange: (subject: string | undefined) => void;
}

const TextFilter: React.FC<IProps> = ({ value, name, tooltip, onChange }) => {
  const onDelete = () => {
    onChange("");
  };

  return (
    <PopupTextEdit
      name={tooltip}
      value={value || ""}
      onSave={(newName: string) => {
        onChange(newName);
      }}
      component={(onClick: (event: any) => void) => (
        <FilterBase
          value={value}
          name={name}
          onClick={onClick}
          onDelete={onDelete}
          tooltip={tooltip}
        />
      )}
    />
  );
};

export default TextFilter;
