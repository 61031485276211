import IConfiguration from "../Interfaces/IConfiguration";
import { jsonClient } from "hippity";

export class AppService {
  public static Configuration = async (): Promise<IConfiguration> => {
    return (await jsonClient.$get(
      "/Api/Configuration/Environment",
    )) as IConfiguration;
  };
}
