import IWorkflowDefinition from "../Interfaces/IWorkflowDefinition";
import HippityResponse from "hippity";
import { HttpStatus } from "../Enums/HttpStatus";
import IExportWorkflowDefinition from "../Interfaces/IExportWorkflowDefinition";
import { useClient } from "../../Hooks/useClient";

type T = IWorkflowDefinition;
export type apiFetch = { status: HttpStatus; data: T[] };
const apiPath = "Api/WorkflowDefinition";

export function useWorkflowDefinitionsService(): {
  $create: (workflowStream: T) => Promise<void>;
  $delete: (id: string) => Promise<void>;
  $update: (workflowStream: T) => Promise<void>;
  $export: (id: string) => Promise<IExportWorkflowDefinition>;
  $get: () => Promise<T[]>;
  importWorkflow: (
    workflowStream: T,
  ) => Promise<HippityResponse.HippityResponse>;
  get: () => Promise<apiFetch>;
  $createNewVersion: (id: string) => Promise<IWorkflowDefinition>;
} {
  const client = useClient();

  async function $get(): Promise<T[]> {
    return (await client.$get(apiPath)) as T[];
  }

  async function get(): Promise<apiFetch> {
    try {
      const response = await client.get(apiPath);
      return response.success
        ? { status: HttpStatus.Success, data: response.body as T[] }
        : { status: HttpStatus.Failure, data: [] };
    } catch {
      return { status: HttpStatus.Failure, data: [] };
    }
  }

  async function $export(id: string): Promise<IExportWorkflowDefinition> {
    return (await client.$get([
      `${apiPath}/export/{id}`,
      { id },
    ])) as IExportWorkflowDefinition;
  }

  async function importWorkflow(
    workflowStream: T,
  ): Promise<HippityResponse.HippityResponse> {
    return await client.post(`${apiPath}/import`, workflowStream);
  }

  async function $create(workflowStream: T): Promise<void> {
    await client.$post(apiPath, workflowStream);
  }

  async function $update(workflowStream: T): Promise<void> {
    await client.$patch(apiPath, workflowStream);
  }

  async function $delete(id: string): Promise<void> {
    await client.$del([`${apiPath}/{id}`, { id }]);
  }

  async function $createNewVersion(id: string): Promise<IWorkflowDefinition> {
    return (await client.$patch(
      [`${apiPath}/NewVersion/{id}`, { id }],
      [],
    )) as IWorkflowDefinition;
  }

  return {
    $create,
    $delete,
    $update,
    $export,
    $get,
    importWorkflow,
    get,
    $createNewVersion,
  };
}
