import React from "react";
import { MenuItem, TextField } from "@mui/material";
import Help from "../../Help/Help";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  selectedValue: string;
  label: string;
  helpText: string;
  selectBox: JSX.Element[];
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}

const NonMappableSelect: React.FC<IProps> = ({
  selectedValue,
  label,
  helpText,
  selectBox,
  setSelected,
  readOnly,
}) => (
  <TextField
    select
    value={selectBox.length === 0 || selectedValue === "" ? " " : selectedValue}
    label={label}
    fullWidth
    variant="outlined"
    onChange={(event) => setSelected(event.target.value)}
    slotProps={{
      input: {
        startAdornment: <Help text={helpText} />,
      },
    }}
    disabled={readOnly}
  >
    <MenuItem key="" value=" " disabled={true}>
      {selectBox.length > 0 ? "--Please Select--" : `No ${label}s Available`}
    </MenuItem>
    {selectBox}
  </TextField>
);

export default NonMappableSelect;
