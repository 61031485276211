import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  name: string;
  onAdd: () => void;
}

const ItemAccordionEmptyButton: React.FC<IProps> = ({
  name,
  onAdd,
  readOnly,
}) => {
  const trimmedName =
    name.charAt(name.length - 1) === "s" ? name.slice(0, -1) : name;

  return readOnly ? (
    <Button className={classes.emptyText} disabled>
      There are no {trimmedName?.toLocaleLowerCase()}s
    </Button>
  ) : (
    <Button className={classes.emptyText} onClick={onAdd}>
      Add {trimmedName}
      <AddIcon className={classes.itemAccordionEmptyIcon} />
    </Button>
  );
};

export default ItemAccordionEmptyButton;
