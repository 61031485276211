import React from "react";
import { styled } from "@mui/material/styles";
import { EventStatus } from "../../Common/Enums/EventStatus";
import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutline";
import ReplayOutlined from "@mui/icons-material/ReplayOutlined";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { Tooltip } from "@mui/material";
import theme from "../../App/App.Theme";

const PREFIX = "EventStatusIcon";

const classes = {
  created: `${PREFIX}-created`,
  queued: `${PREFIX}-queued`,
  processed: `${PREFIX}-processed`,
  requeued: `${PREFIX}-requeued`,
  failed: `${PREFIX}-failed`,
  tooltip: `${PREFIX}-tooltip`,
};

const StyledTooltip = styled(Tooltip)(() => ({
  [`& .${classes.created}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.queued}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.processed}`]: {
    color: theme.palette.grey[700],
  },

  [`& .${classes.requeued}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.failed}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.tooltip}`]: {
    cursor: "pointer",
  },
}));

interface IEventStatusIconProps {
  eventStatus: EventStatus;
}

const EventStatusIcon: React.FC<IEventStatusIconProps> = ({ eventStatus }) => {
  const renderIcon = (eventStatus: EventStatus) => {
    switch (eventStatus) {
      case EventStatus.Created:
        return (
          <StyledTooltip
            title={EventStatus.Created}
            className={classes.tooltip}
          >
            <PlayCircleOutline
              titleAccess={EventStatus.Created}
              className={classes.created}
            />
          </StyledTooltip>
        );
      case EventStatus.Queued:
        return (
          <StyledTooltip title={EventStatus.Queued} className={classes.tooltip}>
            <ScheduleIcon
              titleAccess={EventStatus.Queued}
              className={classes.queued}
            />
          </StyledTooltip>
        );
      case EventStatus.Processed:
        return (
          <StyledTooltip
            title={EventStatus.Processed}
            className={classes.tooltip}
          >
            <CheckCircleOutlined
              titleAccess={EventStatus.Processed}
              className={classes.processed}
            />
          </StyledTooltip>
        );
      case EventStatus.Requeued:
        return (
          <StyledTooltip
            title={EventStatus.Requeued}
            className={classes.tooltip}
          >
            <ReplayOutlined
              titleAccess={EventStatus.Requeued}
              className={classes.requeued}
            />
          </StyledTooltip>
        );
      case EventStatus.Failed:
        return (
          <StyledTooltip title={EventStatus.Failed} className={classes.tooltip}>
            <CancelOutlined
              titleAccess={EventStatus.Failed}
              className={classes.failed}
            />
          </StyledTooltip>
        );
      case null:
        return <></>;
      default:
        return <>{eventStatus}</>;
    }
  };

  return renderIcon(eventStatus);
};

export default EventStatusIcon;
