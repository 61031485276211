import AceEditor, { IAceEditorProps } from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";

function GenericEditor(props: IAceEditorProps): JSX.Element {
  return (
    <AceEditor
      theme="tomorrow"
      fontSize={20}
      showPrintMargin={true}
      width="100%"
      showGutter={true}
      highlightActiveLine={false}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        highlightGutterLine: false,
      }}
      {...props}
      style={{ ...props.style, zIndex: 0 }}
    />
  );
}

export function PlainTextEditor(props: IAceEditorProps): JSX.Element {
  return <GenericEditor mode="text" {...props} />;
}

export function XmlEditor(props: IAceEditorProps): JSX.Element {
  return <GenericEditor mode="xml" {...props} />;
}

// This editor is throwing the warning: "Could not load worker TypeError: name.split is not a function", I can't work out why
export function JsonEditor(props: IAceEditorProps): JSX.Element {
  return <GenericEditor mode="json" {...props} />;
}

export function HtmlEditor(props: IAceEditorProps): JSX.Element {
  return <GenericEditor mode="html" {...props} />;
}
