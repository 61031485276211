import React, { useState } from "react";
import { Grid2, IconButton } from "@mui/material";
import MappableTextField from "../../MappableTextField/MappableTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "../../Delete/DeleteConfirmation";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  filter: string;
  context: string;
  index: number;
  onChange: (filter: string) => void;
  onDelete: () => void;
}

const FilterItem: React.FC<IProps> = ({
  filter,
  index,
  onChange,
  onDelete,
  context,
  readOnly,
}) => {
  const [deleting, setDeleting] = useState(false);

  return deleting ? (
    <DeleteConfirmation
      name="Filter"
      onCancel={() => setDeleting(false)}
      onConfirm={() => {
        onDelete();
        setDeleting(false);
      }}
    />
  ) : (
    <Grid2 container style={{ margin: "8px 0px" }} key={index}>
      <Grid2 size={11}>
        <MappableTextField
          label="Filter Expression"
          literalValueOptions={{ true: "True", false: "False" }}
          context={context}
          value={filter}
          onChange={(value) => onChange(value)}
          required
          placeholder={"true()"}
          helpText="Filters are boolean XPath expressions evaluated at runtime against the context, to determine whether the workflow instance should run."
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2
        display="flex"
        justifyContent="center"
        alignItems="center"
        size={1}
      >
        {!readOnly && (
          <IconButton
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => {
              setDeleting(true);
              e.stopPropagation();
            }}
          >
            <DeleteIcon className={classes.mediumGrey} />
          </IconButton>
        )}
      </Grid2>
    </Grid2>
  );
};

export default FilterItem;
