import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import FilterBase from "./FilterBase";
import { TaskType } from "../../Common/Enums/TaskType";
import { useAppContext } from "../../App/App.Context";
import { TaskTypeNameProvider } from "../../Common/Providers/TaskTypeNameProvider";

interface IProps {
  value?: TaskType;
  name: string;
  tooltip: string;
  onChange: (type: TaskType | undefined) => void;
}

const TaskTypeFilter: React.FC<IProps> = ({
  value,
  name,
  tooltip,
  onChange,
}) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const appContext = useAppContext();

  const onDelete = () => {
    onChange(undefined);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleChange = (value: TaskType) => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <FilterBase
        value={value ? TaskTypeNameProvider(value) : undefined}
        name={name}
        onClick={handleClick}
        onDelete={onDelete}
        tooltip={tooltip}
      />
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChange(TaskType.Manual)}>
          {TaskTypeNameProvider(TaskType.Manual)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskType.Email)}>
          {TaskTypeNameProvider(TaskType.Email)}
        </MenuItem>
        <MenuItem onClick={() => handleChange(TaskType.Http)}>
          {TaskTypeNameProvider(TaskType.Http)}
        </MenuItem>
        {appContext.aireFrameCreateFormTaskEnabled && (
          <MenuItem onClick={() => handleChange(TaskType.AireFrameCreateForm)}>
            {TaskTypeNameProvider(TaskType.AireFrameCreateForm)}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleChange(TaskType.PublishEvent)}>
          {TaskTypeNameProvider(TaskType.PublishEvent)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default TaskTypeFilter;
