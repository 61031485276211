import { useClient } from "../../Hooks/useClient";
import IEventLog from "../Interfaces/IEventLog";
import IPaged from "../Interfaces/IPaged";

export function UseEventLogService(): {
  $get: (
    pageSize: number,
    pageNum: number,
    filter: string | undefined,
  ) => Promise<IPaged<IEventLog>>;
  $deleteContexts: () => Promise<void>;
} {
  const client = useClient();

  async function $get(
    pageSize: number,
    pageNum: number,
    filter: string | undefined,
  ): Promise<IPaged<IEventLog>> {
    return (await client.$get(
      filter == undefined
        ? `Api/Event/paged?pageSize=${pageSize}&page=${pageNum}`
        : `Api/Event/paged?pageSize=${pageSize}&page=${pageNum}&filter=${filter}`,
    )) as IPaged<IEventLog>;
  }

  async function $deleteContexts() {
    return (await client.$delete("Api/Event/Contexts")) as void;
  }

  return { $get, $deleteContexts };
}
