import { WorkflowAction } from "./WorkflowAction";
import { DispatchActionType as DispatchActionType } from "./DispatchActionType";

export type AddWorkflowAction = {
  dispatchActionType: DispatchActionType.AddWorkflowAction;
  workflowAction: WorkflowAction;
};

export type RemoveWorkflowAction = {
  dispatchActionType: DispatchActionType.RemoveWorkflowAction;
  workflowDefinitionId: string;
};

export type DispatchAction = AddWorkflowAction | RemoveWorkflowAction;

const initialState: WorkflowAction[] = [];

export const WorkflowActionReducer = (
  state: WorkflowAction[] = initialState,
  dispatchAction: DispatchAction,
): WorkflowAction[] => {
  switch (dispatchAction.dispatchActionType) {
    case DispatchActionType.AddWorkflowAction:
      return [
        ...state.filter(
          (a) =>
            a.workflowDefinitionId !==
            dispatchAction.workflowAction.workflowDefinitionId,
        ),
        dispatchAction.workflowAction,
      ];
    case DispatchActionType.RemoveWorkflowAction:
      return state.filter(
        (item) =>
          item.workflowDefinitionId !== dispatchAction.workflowDefinitionId,
      );
    default:
      return state;
  }
};
