import { useCallback } from "react";
import { useAuth } from "./useAuth";

export function useAuthHeaders(): {
  getHeaders: () => Promise<
    | {
        Authorization: string;
      }
    | {
        "X-Api-Key": string;
      }
  >;
} {
  const { tokenExpired, renewToken, accessToken } = useAuth();

  const getHeaders: () => Promise<
    | {
        Authorization: string;
      }
    | {
        "X-Api-Key": string;
      }
  > = useCallback(async () => {
    let token = accessToken;

    if (tokenExpired()) {
      token = await renewToken();
    }

    return {
      Authorization: "Bearer " + token,
    };
  }, [tokenExpired, renewToken, accessToken]);

  return { getHeaders };
}
