import React from "react";
import FilterItem from "./FilterItem";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import IconFilter from "../../Icons/IconFilter";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  filters: string[];
  context: string;
  onChange: (filters: string[]) => void;
}

const Filters: React.FC<IProps> = ({
  filters,
  onChange,
  context,
  readOnly,
}) => {
  const onAddFilter = () => {
    onChange([...filters, '"true"']);
  };

  const onChangeFilter = (index: number, value: string) => {
    try {
      filters[index] = value;
      onChange(filters);
    } catch {
      //ignore
    }
  };

  const onDeleteFilter = (index: number) => {
    filters.splice(index, 1);
    onChange(filters);
  };

  return (
    <ItemAccordion
      name="Filters"
      count={filters.length}
      icon={() => <IconFilter />}
      onAdd={onAddFilter}
      readOnly={readOnly}
    >
      {filters.map((filter, index) => (
        <div id="filter" className={classes.item} key={index}>
          <FilterItem
            filter={filter}
            index={index}
            onChange={(value) => onChangeFilter(index, value)}
            onDelete={() => onDeleteFilter(index)}
            context={context}
            readOnly={readOnly}
          />
        </div>
      ))}
    </ItemAccordion>
  );
};

export default Filters;
