import { AuthenticationType } from "../../Common/Interfaces/IRepository";

export const VaultSecretKeyWarningMessage = (
  authenticationType: AuthenticationType,
): string => {
  let auth = "";
  switch (authenticationType) {
    case AuthenticationType.Credentials:
      auth = "Passwords";
      break;
    case AuthenticationType.SSHKey:
      auth = "SSH Keys";
      break;
  }
  return `Please note that ${auth} are considered obsolete and the option to use these is no longer available. Please use the Vault Secret Key option for enhanced security and convenience. You will be unable to sync your repository until this is done.`;
};
