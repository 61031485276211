import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const EllipseChip = styled(Chip)(() => ({
  root: {
    maxWidth: "100%",
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
  },
}));
