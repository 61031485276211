import { red, yellow } from "@mui/material/colors";
import { ThemeOptions, createTheme } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#339929",
      contrastText: "#fff",
    },
    secondary: {
      main: "#52266E",
      contrastText: "#fff",
    },
    info: {
      main: "#54B7D4",
      contrastText: "#fff",
    },
    error: {
      main: red[700],
    },
    warning: {
      main: yellow[800],
    },
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&$active": {
            color: "#ffd600",
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
