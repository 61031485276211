export default class KeyHelper {
  public static readonly KeyRegex = /^[a-z][a-z0-9-]*$/;
  public static readonly KeyErrorMessage =
    "Keys must start with a letter and only contain lowercase letters, numbers and dashes, e.g. key-123";

  public static ValidateKey(key: string): boolean {
    return KeyHelper.KeyRegex.test(key);
  }

  public static GenerateKeyFromValue(value: string): string {
    let key = value.toLowerCase();
    // Remove liquid
    key = key.replace(/{{.+?(?=}})}}|{%.+?(?=%})%}/g, "");
    // Replace all whitespace with dashes
    key = key.replace(/\s/g, "-");
    // Remove all chars that aren't digits or lowercase letters.
    key = key.replace(/[^a-z\d-]/g, "");
    // Remove any numbers or dashes at the start of the key
    key = key.replace(/^[0-9|-]+/, "");
    return key;
  }
}
