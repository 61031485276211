import { TaskStatus } from "../../../Common/Enums/TaskStatus";

export type TaskStatusString =
  | "new"
  | "inProgress"
  | "completed"
  | "cancelled"
  | "failed"
  | "blocked";

export type TaskStatusOrTotalString =
  | TaskStatusString
  | "total"
  | "activeTotal";

export const TaskStatusMapping = {
  new: TaskStatus.New,
  inProgress: TaskStatus.InProgress,
  failed: TaskStatus.Failed,
  completed: TaskStatus.Completed,
  cancelled: TaskStatus.Cancelled,
  blocked: TaskStatus.Blocked,
};

export const ActiveTaskStatuses = [
  TaskStatus.New,
  TaskStatus.InProgress,
  TaskStatus.Failed,
  TaskStatus.Blocked,
];

export const AllTaskStatuses = [
  TaskStatus.New,
  TaskStatus.InProgress,
  TaskStatus.Failed,
  TaskStatus.Completed,
  TaskStatus.Cancelled,
  TaskStatus.Blocked,
];
