import { MarkerType, Edge, Node } from "reactflow";
import {
  EventType,
  EventTypeDisplayNames,
  parseRoutingKey,
} from "../../Common/Enums/EventType";
import { TaskType } from "../../Common/Enums/TaskType";
import classNames from "classnames";
import ITriggerDefinition from "../../Common/Interfaces/ITriggerDefinition";
import { getLayoutElements } from "./ChartLayout";
import IRelationshipTask from "../../Common/Interfaces/IRelationshipTask";
import { classes } from "../../App.Styles";
import NodeLabel from "./NodeLabel";

function getClassName(task: IRelationshipTask, parentDefinitionId: string) {
  const selectedClasses: string[] = [];

  if (!task.enabled) {
    selectedClasses.push(classes.disabled);
  }

  if (task.taskType === TaskType.Manual) {
    selectedClasses.push(
      task.workflowDefinitionId === parentDefinitionId
        ? classes.task
        : classes.externalTask,
    );
  } else {
    selectedClasses.push(
      task.workflowDefinitionId === parentDefinitionId
        ? classes.notification
        : classes.externalNotification,
    );
  }

  return classNames(selectedClasses);
}

interface GetNodesAndEdgesResponse {
  Nodes: Node[];
  Edges: Edge[];
}

const eventTypeMap = {
  [EventType.Custom]: {
    title: "Custom Event",
    className: classes.customEvent,
  },
  [EventType.Group]: {
    title: "Event Group",
    className: classes.groupEvent,
  },
  [EventType.AireFrame]: {
    title: "AireFrame Event",
    className: classes.aireframeEvent,
  },
};

function createEventNode(
  encounteredEvents: string[],
  eventKey: string,
  type: EventType.Custom | EventType.Group | EventType.AireFrame,
  nodes: Node[],
): string {
  const id = `${type}_${eventKey}`;

  if (!encounteredEvents.includes(eventKey)) {
    encounteredEvents.push(eventKey);
    nodes.push({
      id,
      data: {
        label: (
          <NodeLabel title={eventTypeMap[type].title} subtitle={eventKey} />
        ),
      },
      className: classNames(classes.node, eventTypeMap[type].className),
      position: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },
    });
  }

  return id;
}

function createEdge(edges: Edge[], edge: Partial<Edge>) {
  edges.push({
    id: `edge_${Math.random()}`,
    source: edge.source ?? "",
    target: edge.target ?? "",
    type: edge.type ?? "floating",
    label: edge.label ?? "",
    style: edge.style ?? {},
    labelStyle: edge.labelStyle ?? {},
    data: edge.data ?? {},
    markerEnd: edge.markerEnd ?? {
      type: MarkerType.ArrowClosed,
    },
  });
}

export function getNodesAndEdges(
  tasks: IRelationshipTask[],
  parentDefinitionId: string,
): GetNodesAndEdgesResponse {
  const encounteredEvents = {
    [EventType.Custom]: [],
    [EventType.Group]: [],
    [EventType.AireFrame]: [],
  };
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  let counter = 0;
  for (const task of tasks) {
    const className = getClassName(task, parentDefinitionId);
    const taskNodeId = `${task.taskKey}_${task.workflowDefinitionVersion}`;
    nodes.push({
      id: taskNodeId,
      data: {
        label: (
          <NodeLabel title={stripXPath(task.name)} subtitle={task.taskKey} />
        ),
      },
      className: classNames(classes.node, className),
      position: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    });

    const allTriggers: ITriggerDefinition[] = [
      ...task.triggers,
      ...task.triggeredUpdates,
    ];

    if (task.publishedEventKey) {
      const eventNodeId = createEventNode(
        encounteredEvents[EventType.Custom],
        task.publishedEventKey,
        EventType.Custom,
        nodes,
      );

      createEdge(edges, { source: taskNodeId, target: eventNodeId });
    }

    for (const trigger of allTriggers) {
      let source: string;
      let edgeName: string;
      const routingKey = parseRoutingKey(trigger.routingKey);
      const taskName = EventTypeDisplayNames[routingKey.type];

      if (
        trigger.selectedEvent === EventType.AireFrame ||
        trigger.selectedEvent === EventType.Group ||
        trigger.selectedEvent === EventType.Custom
      ) {
        const eventNodeId = createEventNode(
          encounteredEvents[trigger.selectedEvent],
          trigger.selectedEventKey,
          trigger.selectedEvent,
          nodes,
        );
        source = eventNodeId;
        edgeName = trigger.selectedEventKey;
      } else {
        source = `${trigger.selectedTaskKey}_${trigger.selectedTriggerVersion}`;
        edgeName = `${taskName}`;
      }

      const isUpdate = Object.prototype.hasOwnProperty.call(trigger, "update");
      const hideLabel =
        trigger.selectedEvent === EventType.AireFrame ||
        trigger.selectedEvent === EventType.Group ||
        trigger.selectedEvent === EventType.Custom;

      if (source !== task.taskKey) {
        const duplicateEdges = edges.filter(
          (e) => e.source === source && e.target === taskNodeId,
        ).length;

        createEdge(edges, {
          source: source,
          target: taskNodeId,
          label: hideLabel ? "" : edgeName + (isUpdate ? "*" : ""),
          style: isUpdate ? { stroke: "#52266E", strokeDasharray: "4" } : {},
          labelStyle: isUpdate ? { fontStyle: "italic" } : {},
          data: {
            labelPosition: duplicateEdges,
          },
        });
      }
    }
  }

  getLayoutElements(nodes, edges);
  return {
    Edges: edges,
    Nodes: nodes,
  } as GetNodesAndEdgesResponse;
}

function stripXPath(part: string): string {
  return part.replace(/"/g, "").replace(/'/g, "");
}
