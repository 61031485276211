import React from "react";
import { Grid2 } from "@mui/material";
import MappableTextField from "../../MappableTextField/MappableTextField";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  correlationId: string; //optional.
  keyProp: string; //called keyProp because key is a reserved word.
  version: string; //should be integer (number).
  subjectId: string; //should be guid.
  context: string;
}

const AireFrameCreateFormActivityProperties: React.FC<IProps> = ({
  onPropertiesChange,
  correlationId,
  keyProp,
  version,
  subjectId,
  context,
  readOnly,
}) => {
  const onChange = (setValue: () => void) => {
    setValue();
    updateProperties();
  };

  const updateProperties = () => {
    onPropertiesChange({
      CorrelationId: correlationId,
      KeyProp: keyProp,
      Version: version,
      SubjectId: subjectId,
    });
  };

  return (
    <>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Key"
          value={keyProp}
          required
          placeholder=""
          context={context}
          onChange={(value) => onChange(() => (keyProp = value))}
          helpText=""
          readOnly={readOnly}
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Version"
          value={version}
          placeholder=""
          context={context}
          onChange={(value) => onChange(() => (version = value))}
          helpText="This value must be a integer."
          readOnly={readOnly}
        />
      </Grid2>
    </>
  );
};

export default AireFrameCreateFormActivityProperties;
