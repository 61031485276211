import { Moment } from "moment";
import { useClient } from "../../Hooks/useClient";
import IMetricsResult from "../Interfaces/IMetricsResult";

export function useMetricsService(): {
  $get: (start: Moment | null, end: Moment | null) => Promise<IMetricsResult>;
} {
  const client = useClient();

  async function $get(
    start: Moment | null,
    end: Moment | null,
  ): Promise<IMetricsResult> {
    const params: any = {};

    if (start) params["start"] = start?.toISOString();
    if (end) params["end"] = end?.toISOString();

    return (await client.$get([`/Api/Metrics`, params])) as IMetricsResult;
  }

  return { $get };
}
