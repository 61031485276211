import React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

interface IProps {
  open?: boolean;
  onClose: () => void;
  colour: AlertColor;
  message: string;
}

// ToDo: Create a context hook to add messages to the snackbar so we don't have to import it as a component everywhere.
export default function SnackbarAlert(props: IProps): JSX.Element {
  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (
      reason === "clickaway" &&
      (props.colour == "error" || props.colour == "warning")
    ) {
      return; // Do nothing if the user clicks outside the Snackbar for warnings and errors
    }

    props.onClose();
  };

  return (
    <Snackbar
      open={props.open ?? true}
      autoHideDuration={
        props.colour == "error" || props.colour == "warning" ? 12000 : 6000
      }
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        severity={props.colour}
        elevation={6}
        variant="filled"
        onClose={props.onClose}
        style={{ whiteSpace: "pre" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
