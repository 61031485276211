import { useClient } from "../../Hooks/useClient";
import IRelationshipTask from "../Interfaces/IRelationshipTask";

export function useRelationshipsService(): {
  $getById: (id: string, version: number) => Promise<IRelationshipTask[]>;
} {
  const client = useClient();

  async function $getById(
    id: string,
    version: number,
  ): Promise<IRelationshipTask[]> {
    return (await client.$get([
      "Api/Relationships/{id}/{version}",
      { id, version },
    ])) as IRelationshipTask[];
  }

  return { $getById };
}
