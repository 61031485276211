export enum EventType {
  TaskInstantiated = "task-instantiated",
  TaskAssigned = "task-assigned",
  TaskStarted = "task-started",
  TaskCompleted = "task-completed",
  TaskFailed = "task-failed",
  TaskCancelled = "task-cancelled",
  TaskBlocked = "task-blocked",
  Custom = "custom",
  Group = "group",
  AireFrame = "aireframe",
}

export const EventTypeDisplayNames = {
  [EventType.TaskInstantiated]: "Task Instantiated",
  [EventType.TaskAssigned]: "Task Assigned",
  [EventType.TaskStarted]: "Task Started",
  [EventType.TaskCompleted]: "Task Completed",
  [EventType.Custom]: "Custom",
  [EventType.TaskFailed]: "Task Failed",
  [EventType.TaskCancelled]: "Task Cancelled",
  [EventType.TaskBlocked]: "Task Blocked",
  [EventType.Group]: "Group",
  [EventType.AireFrame]: "AireFrame",
};

/**
 * Checks whether a string is a value inside an enum
 */
const enumChecker = <T extends string, TEnumValue extends string>(enumType: {
  [key in T]: TEnumValue;
}) => {
  const enumValues = Object.values(enumType);
  return (value: string | number | boolean): value is TEnumValue =>
    enumValues.includes(value);
};

const isEventType = enumChecker(EventType);

export function parseEventType(value: string): EventType {
  if (isEventType(value)) {
    return value;
  } else {
    throw new Error(`Value '${value}' was not found in Enum 'EventType'`);
  }
}

type RoutingKey =
  | {
      type: EventType.Custom | EventType.Group | EventType.AireFrame;
      key: string;
    }
  | {
      type:
        | EventType.TaskAssigned
        | EventType.TaskCompleted
        | EventType.TaskInstantiated
        | EventType.TaskStarted
        | EventType.TaskFailed
        | EventType.TaskBlocked
        | EventType.TaskCancelled;
      taskKey: string;
    };

export function parseRoutingKey(routingKey: string): RoutingKey {
  const parts = routingKey.split(".");
  const type = parseEventType(parts[0]);
  if (
    type === EventType.Custom ||
    type === EventType.Group ||
    type === EventType.AireFrame
  ) {
    return { type, key: parts[1] };
  }

  return { type, taskKey: parts[1] };
}
