import IEventDefinitionGroup from "../Interfaces/IEventDefinitionGroup";
import { HippityResponse } from "hippity";
import IExportEventDefinitionGroup from "../Interfaces/IExportEventDefinitionGroup";
import { useClient } from "../../Hooks/useClient";

type T = IEventDefinitionGroup;
const apiPath = "Api/EventDefinitionGroup";

export function useEventDefinitionGroupsService(): {
  $get: () => Promise<IEventDefinitionGroup[]>;
  $create: (group: IEventDefinitionGroup) => Promise<void>;
  create: (group: IEventDefinitionGroup) => Promise<HippityResponse>;
  $del: (id: string) => Promise<void>;
  del: (id: string) => Promise<HippityResponse>;
  $update: (group: IEventDefinitionGroup) => Promise<void>;
  update: (group: IEventDefinitionGroup) => Promise<HippityResponse>;
  importEventGroup: (eventGroupStream: T) => Promise<HippityResponse>;
  $export: (id: string) => Promise<IExportEventDefinitionGroup>;
} {
  const client = useClient();

  async function $get(): Promise<IEventDefinitionGroup[]> {
    return (await client.$get(apiPath)) as IEventDefinitionGroup[];
  }

  async function $create(group: IEventDefinitionGroup): Promise<void> {
    await client.$post(apiPath, group);
  }

  async function create(
    group: IEventDefinitionGroup,
  ): Promise<HippityResponse> {
    return await client.post(apiPath, group);
  }

  async function $update(group: IEventDefinitionGroup): Promise<void> {
    await client.$patch(apiPath, group);
  }

  async function update(
    group: IEventDefinitionGroup,
  ): Promise<HippityResponse> {
    return await client.patch(apiPath, group);
  }

  async function $del(id: string): Promise<void> {
    await client.$del([`${apiPath}/{id}`, { id }]);
  }

  async function del(id: string): Promise<HippityResponse> {
    return await client.del([`${apiPath}/{id}`, { id }]);
  }

  async function importEventGroup(
    eventGroupStream: T,
  ): Promise<HippityResponse> {
    return await client.post(`${apiPath}/import`, eventGroupStream);
  }

  async function $export(id: string): Promise<IExportEventDefinitionGroup> {
    return (await client.$get([
      `${apiPath}/export/{id}`,
      { id },
    ])) as IExportEventDefinitionGroup;
  }

  return {
    $get,
    $create,
    create,
    $del,
    del,
    $update,
    update,
    importEventGroup,
    $export,
  };
}
