import React, { useEffect, useState } from "react";
import DefinitionExport from "./DefinitionExport";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import { useEventDefinitionGroupsService } from "../../../Common/Hooks/useEventDefinitionGroupsService";

interface IProps {
  definition: IEventDefinitionGroup;
}

const EventDefinitionGroupExport: React.FC<IProps> = ({ definition }) => {
  const [definitionJson, setDefinitionJson] = useState<undefined | string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const service = useEventDefinitionGroupsService();

  useEffect(() => {
    const getExport = async () => {
      try {
        setLoading(true);
        const response = await service.$export(definition.id);
        setDefinitionJson(JSON.stringify(response, null, "\t"));
      } catch {
        setError(`Error loading event group definition export`);
      } finally {
        setLoading(false);
      }
    };

    getExport();
  }, [definition.id]);

  return (
    <DefinitionExport
      definitionName={definition.name}
      definitionJson={definitionJson}
      error={error}
      loading={loading}
      onErrorClose={() => setError(undefined)}
    />
  );
};

export default EventDefinitionGroupExport;
