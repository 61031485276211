import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import theme from "./App/App.Theme";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { appStyles } from "./App.Styles";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={appStyles} />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
);
require("dotenv").config();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
