import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import RetryIcon from "@mui/icons-material/Replay";
import classNames from "classnames";
import { useStatusServicesContext } from "../Services/TaskStatusServiceContext";
import { classes } from "../App.Styles";
import { TaskType } from "../Common/Enums/TaskType";
import { TaskStatus } from "../Common/Enums/TaskStatus";
import {
  TaskStatusColour,
  TaskStatusName,
} from "../Common/Providers/TaskStatusProvider";
import { useTasksService } from "../Hooks/useTasksService";
import { ClinicalSafetyAlert } from "./ClinicalSafetyAlert";
import { TaskTypeNameProvider } from "../Common/Providers/TaskTypeNameProvider";
import theme from "../App/App.Theme";
import { useAppContext } from "../App/App.Context";
import moment, { Moment } from "moment";

interface IProps {
  taskId: string;
  taskStatus: TaskStatus;
  taskType: TaskType;
  lastRun?: Moment;
  onUpdate: (status: string) => Promise<boolean>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskStatusSelect: React.FC<IProps> = ({
  taskId,
  taskStatus,
  taskType,
  lastRun,
  onUpdate,
  setSnackbarMessage,
  setLoading,
}) => {
  const appContext = useAppContext();
  const { retry } = useTasksService();
  const { transitions } = useStatusServicesContext();

  const isTransitionAvailable = (): boolean => {
    if (taskType !== TaskType.Manual || !transitions?.has(originalStatus))
      return false;

    const toStates = transitions?.get(originalStatus);
    return toStates !== undefined && toStates.length > 0;
  };

  const canRetryTask = (): boolean => {
    if (!lastRun) return false;

    const isWithinRetryPeriod =
      moment.utc() <= moment(lastRun).add(appContext.taskRetryPeriod, "hours");
    return (
      isWithinRetryPeriod &&
      taskType !== TaskType.Manual &&
      originalStatus === TaskStatus.Failed &&
      !isRetrying
    );
  };

  const onRetryConfirmClicked = async (): Promise<void> => {
    setLoading(true);
    try {
      const retryResponse = await retry(taskId);
      const { message, body, success } = retryResponse;
      if (success) {
        setSnackbarMessage("Task retry request sent");
        setIsRetrying(true);
      } else if (message && body) {
        setSnackbarMessage(`Error: ${message} - ${body}`);
      } else if (message) {
        setSnackbarMessage(`Error: ${message}`);
      } else if (body) {
        setSnackbarMessage(`Error: ${body}`);
      } else {
        setSnackbarMessage(`Error: an unexpected error occurred`);
      }
    } catch (e) {
      console.error(e);
      setSnackbarMessage(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const originalStatus = taskStatus;
  const [selected, setSelected] = useState(originalStatus);
  const [hoveredStatus, setHoveredStatus] = useState<TaskStatus>();
  const [isRetrying, setIsRetrying] = useState(false);
  const [open, setOpen] = useState(false);
  const [retryDialogOpen, setRetryDialogOpen] = useState(false);
  const [warningChecked, setWarningChecked] = useState(false);

  const onChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    if (!event.target.value) return;
    const newStatus = event.target.value as TaskStatus;
    setSelected(newStatus);
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSelected(originalStatus);
    setOpen(false);
  };

  const onSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const updateSuccessful = await onUpdate(selected);
    if (!updateSuccessful) {
      setSelected(originalStatus);
    }
    setOpen(!updateSuccessful);
  };

  const iconComponentStyles = {
    mr: theme.spacing(1),
    color: `${TaskStatusColour(selected)} !important`,
  };

  const iconComponent = (props: any) => {
    if (canRetryTask()) {
      return (
        <RetryIcon
          onClick={() => setRetryDialogOpen(true)}
          sx={{
            ...iconComponentStyles,
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "rgba(51, 153, 41, 0.04)",
              borderRadius: "15px",
            },
          }}
        />
      );
    }
    return isTransitionAvailable() ? (
      <ExpandIcon
        className={classNames(props.className)}
        sx={iconComponentStyles}
      />
    ) : (
      <span></span>
    );
  };

  const menuProps = {
    PaperProps: {
      sx: {
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(2),
        width: "200pt",
        "& .MuiMenu-list": {
          paddingTop: 0,
          paddingBottom: 0,
          background: "white",
          "& li": {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
            overflow: "hidden",
            color: theme.palette.secondary.main,
            border: `solid 2px white`,
          },
          "& li:hover": {
            background: "white",
            border: `solid 2px ${hoveredStatus ? TaskStatusColour(hoveredStatus) : "white"}`,
          },
          "& li.Mui-selected": {
            color: theme.palette.secondary.main,
            background: "white",
            border: `solid 2px ${TaskStatusColour(selected)}`,
          },
        },
      },
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  const selectStyles = {
    "& .MuiSelect-select": {
      paddingRight: isTransitionAvailable() ? undefined : "0px !important",
      pointerEvents: isTransitionAvailable() ? "all" : "none",
    },
    backgroundColor: "white",
    border: `solid 2px ${TaskStatusColour(selected)}`,
    "& :focus": {
      backgroundColor: "unset !important",
    },
    "&:hover": {
      backgroundColor: isTransitionAvailable()
        ? "rgba(51, 153, 41, 0.04)"
        : "white",
    },
    cursor: isTransitionAvailable() ? "pointer" : "auto",
  };

  return (
    <>
      <Select
        className={classNames(classes.taskStatus, classes.taskStatusColumnItem)}
        value={selected}
        IconComponent={iconComponent}
        onChange={onChange}
        onOpen={() => setOpen(true)}
        onClose={(event: any) => {
          if (event.target.value == undefined) {
            setOpen(false);
            setSelected(originalStatus);
          }
        }}
        open={isTransitionAvailable() && open}
        MenuProps={menuProps}
        variant="standard"
        disableUnderline={true}
        sx={selectStyles}
      >
        <MenuItem value={originalStatus} key={originalStatus}>
          {isRetrying ? "Retrying" : TaskStatusName(originalStatus)}
        </MenuItem>
        {transitions?.get(originalStatus)?.map((s) => (
          <MenuItem value={s} key={s} onMouseEnter={() => setHoveredStatus(s)}>
            {TaskStatusName(s)}
          </MenuItem>
        ))}
        <div className={classes.taskStatusButtonContainer}>
          <Button
            className={classNames(classes.button, classes.buttonCancel)}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={classNames(classes.button, classes.buttonSave)}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </Select>
      <ClinicalSafetyAlert
        open={retryDialogOpen}
        title={`Retry ${TaskTypeNameProvider(taskType)} task?`}
        onClose={() => {
          setRetryDialogOpen(false);
          setWarningChecked(false);
        }}
        action={onRetryConfirmClicked}
        body={
          <>
            <Alert severity="warning" className={classes.lgAlert}>
              Retrying this {TaskTypeNameProvider(taskType)} task presents a
              range of potential risks to patient safety. Please contact{" "}
              <a href="mailto:support@airelogic.com">support@airelogic.com</a>{" "}
              and{" "}
              <a href="mailto:clinical.safety@airelogic.com">
                clinical.safety@airelogic.com
              </a>{" "}
              if you require support with this.
            </Alert>
            <span>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={warningChecked}
                    onChange={() => setWarningChecked(!warningChecked)}
                    color="primary"
                    required
                  />
                }
                label="I have read and understood the above warning"
              />
            </span>
          </>
        }
        confirmationText={`Retry ${TaskTypeNameProvider(taskType)} Task`}
        confirmDisabled={!warningChecked}
      />
    </>
  );
};

export default TaskStatusSelect;
