import {
  Avatar,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Grid2,
  Switch,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ITableEventDefinition } from "../../Common/Interfaces/ITableEventDefinition";
import { useEventDefinitionService } from "../../Common/Hooks/useEventDefinitionService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Cancel";
import EventDefinitionDetail from "./EventDefinitionDetail";
import { DataTable, Column } from "../Tables/DataTable";
import { DialogAlert } from "../DialogAlert";
import "../../Common/Helpers/Extensions/string.extensions";
import WindowHelpers from "../../Common/Helpers/WindowHelpers";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import { useBackgroundTask } from "../../Common/Hooks/UseBackgroundTask";
import PopupTextEdit from "../PopupTextEdit/PopupTextEdit";
import SnackbarAlert from "../SnackbarAlert";
import Loading from "../Loading/Loading";
import { classes } from "../../App.Styles";

const EventDefinitions = (): JSX.Element => {
  const service = useEventDefinitionService();
  const [backgroundError, run, clearBackgroundError] = useBackgroundTask();
  const [definitions, setDefinitions] = React.useState<ITableEventDefinition[]>(
    [],
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();

  const [selectedItem, setSelectedItem] =
    React.useState<ITableEventDefinition>();
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [eventDefinitionsFetchStatus, setEventDefinitionsFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);

  useEffect(() => {
    getDefinitions();

    // Cleanup
    return () => {
      setDefinitions([]);
      setEventDefinitionsFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, []);

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  useEffect(() => {
    setSkipPageReset(false);
  }, [definitions]);

  // When error is thrown by useBackgroundTask, set loading to false
  useEffect(() => {
    setLoading(false);
    setError(backgroundError?.message);
  }, [backgroundError]);

  const getDefinitions = async () => {
    setLoading(true);
    let httpStatus = HttpStatus.Initial;
    try {
      const response = await service.$get();
      setDefinitions(response);
      httpStatus = HttpStatus.Success;
    } catch (e) {
      httpStatus = HttpStatus.Failure;
    }
    setEventDefinitionsFetchStatus(httpStatus);
    setLoading(false);
  };

  const getEmptyDataSourceMessage = (): string => {
    switch (eventDefinitionsFetchStatus) {
      case HttpStatus.Success:
        return "No event definitions to display. Select the + button to add a new event definition.";
      case HttpStatus.Failure:
        return "Error fetching event definitions, please try again.";
      default:
        return "";
    }
  };

  const onRowAdd = async (
    item: ITableEventDefinition,
    resetAddRow: () => void,
  ): Promise<void> => {
    run(async () => {
      setLoading(true);
      await service.$create(item);
      await getDefinitions();
      setSuccessMessage("Event Definition Created");
      setLoading(false);
      resetAddRow();
    });
  };

  const onRowUpdate = async (item: ITableEventDefinition) => {
    run(async () => {
      setLoading(true);
      await service.$update(item);
      await getDefinitions();
      setSelectedItem(undefined);
      setSuccessMessage("Event Definition Updated");
      setLoading(false);
    });
  };

  const onRowDelete = async (item: ITableEventDefinition) => {
    run(async () => {
      setLoading(true);
      await service.$delete(item.id as string);
      await getDefinitions();
      setSelectedItem(undefined);
      setSuccessMessage("Event Definition Deleted");
      setLoading(false);
    });
  };

  const isEventNameValid = (value: string) => !!value;
  const isEventKeyValid = (value: string) =>
    !!value && /^[a-z0-9_-]*$/.test(value);

  const handleOnAddEvent = async (model: any, resetAddRow: () => void) => {
    await onRowAdd(model, resetAddRow);
  };

  const validate = (model: any) =>
    isEventNameValid(model.name) && isEventKeyValid(model.eventKey);

  const { width } = WindowHelpers.UseWindowDimensions();
  const columns: Column<ITableEventDefinition>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: "name",
      minWidth: width > 1300 ? 0 : 230,
      Cell: ({ value, row }) => (
        <PopupTextEdit
          name="Edit Name"
          value={value}
          validate={(newValue: string) => isEventNameValid(newValue)}
          onSave={async (newName: any) => {
            const item = row.original;
            item.name = newName;
            if (item.id !== "") await onRowUpdate(item);
          }}
          required
          component={(onClick: any) => (
            <Tooltip title="Edit Name">
              <Chip
                avatar={
                  <Avatar className={classes.nameEditAvatar}>
                    <EditIcon fontSize="inherit" />
                  </Avatar>
                }
                label={width > 900 ? value.ellipsis(45) : value.ellipsis(25)}
                variant="outlined"
                clickable
                onClick={onClick}
              />
            </Tooltip>
          )}
        />
      ),
    },
    {
      Header: "Key",
      id: "eventKey",
      accessor: "eventKey",
      Cell: ({ value }) => (
        <code style={{ backgroundColor: "#d3d3d3", padding: "10px" }}>
          {value}
        </code>
      ),
    },
    {
      Header: "Debug",
      id: "debug",
      accessor: "debug",
      Cell: ({ value, row }) =>
        row.original.id !== "" ? (
          <Typography component="div">
            <Grid2 component="label" container alignItems="center" spacing={1}>
              <Grid2>
                <Switch
                  size="medium"
                  checked={value}
                  name={`checked-${row.index}`}
                  className={classes.debugSwitch}
                  onChange={async (e) => {
                    const item = row.original;
                    item.debug = e.target.checked;
                    await onRowUpdate(item);
                  }}
                />
              </Grid2>
            </Grid2>
          </Typography>
        ) : (
          <></>
        ),
    },
    {
      Header: "Actions",
      id: "actions",
      width: 60,
      Cell: ({ row }: { row: any }) => (
        <span>
          <IconButton
            className={classes.mediumGrey}
            title="Delete"
            onClick={() => {
              setSelectedItem(row.original);
              setDeleteOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
          <span>
            <DialogAlert
              open={deleteOpen}
              title={`Delete ${selectedItem?.name}?`}
              message={undefined}
              onClose={() => setDeleteOpen(false)}
              action={() => {
                selectedItem && onRowDelete(selectedItem);
              }}
              body={
                <Alert severity="warning">
                  Deleting this event definition won’t remove it from any Event
                  Definition Groups or Task Triggers within Workflow
                  Definitions.
                </Alert>
              }
              confirmationText="Delete"
              confirmationColour="error"
            />
          </span>
          <span {...row.getToggleRowExpandedProps()}>
            <IconButton className={classes.mediumGrey}>
              {row.isExpanded ? (
                <CloseIcon id="test_event_close" />
              ) : (
                <PlayIcon id="test_event_open" />
              )}
            </IconButton>
          </span>
        </span>
      ),
    },
  ];

  const renderDetail = (row: ITableEventDefinition) => {
    return <EventDefinitionDetail eventDefinition={row} />;
  };

  return (
    <React.Fragment>
      <Paper elevation={4} className={classes.table}>
        <DataTable
          aria-label="Event Definitions"
          data={definitions ?? []}
          columns={columns}
          skipPageReset={skipPageReset}
          initialState={{ hiddenColumns: [] }}
          renderDetail={renderDetail}
          name="Event Definitions"
          total={definitions ? definitions.length : 0}
          addProps={{
            allowed: true,
            validate: validate,
            onSave: handleOnAddEvent,
            identifier: "add_event",
            rowFields: [
              { columnId: "name", placeholderText: "Name" },
              { columnId: "eventKey", placeholderText: "Key" },
            ],
          }}
          emptyDataSourceMessage={getEmptyDataSourceMessage()}
        />
      </Paper>
      {error && (
        <SnackbarAlert
          open={true}
          onClose={() => {
            clearBackgroundError();
            setError(undefined);
          }}
          colour="error"
          message={error}
        />
      )}
      {successMessage && (
        <SnackbarAlert
          open={true}
          onClose={() => {
            setSuccessMessage(undefined);
          }}
          colour="success"
          message={successMessage}
        />
      )}
      <Loading visible={loading} />
    </React.Fragment>
  );
};

export default EventDefinitions;
