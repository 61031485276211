import { Button } from "@mui/material";
import { useEffect } from "react";
import { Row } from "react-table";
import { TaskStatus } from "../../Common/Enums/TaskStatus";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";
import { ISummaryTableRow } from "../../Interfaces/ISummaryTableRow";
import { IWorkflowTaskStatuses } from "../../Interfaces/IWorkflowTaskStatuses";
import {
  TaskStatusOrTotalString,
  ActiveTaskStatuses,
  AllTaskStatuses,
  TaskStatusMapping,
  TaskStatusString,
} from "./Utils/MetricsUtils";

const ExpandedRowCell: React.FC<{
  showOverdueTasksView: boolean;
  taskStatusOrTotal: TaskStatusOrTotalString;
  value: string;
  row: Row<ISummaryTableRow>;
  selectedWorkflowTaskStatuses: IWorkflowTaskStatuses[];
  setSelectedWorkflowTaskStatuses: (
    updatedTaskStatuses: IWorkflowTaskStatuses[],
  ) => void;
}> = ({
  showOverdueTasksView,
  taskStatusOrTotal,
  value,
  row,
  selectedWorkflowTaskStatuses,
  setSelectedWorkflowTaskStatuses,
}) => {
  const existingSelectedTaskStatuses = selectedWorkflowTaskStatuses.find(
    (wts) =>
      wts.workflowKey === row.original.key &&
      wts.workflowVersion === row.original.version,
  )?.taskStatuses;

  useEffect(() => {
    if (!existingSelectedTaskStatuses) {
      setSelectedWorkflowTaskStatuses([
        ...selectedWorkflowTaskStatuses,
        {
          workflowKey: row.original.key,
          workflowVersion: row.original.version,
          taskStatuses: showOverdueTasksView
            ? ActiveTaskStatuses
            : AllTaskStatuses,
        },
      ]);
    }
  }, [existingSelectedTaskStatuses, row.original.key, row.original.version]);

  let taskStatuses: TaskStatus[] = existingSelectedTaskStatuses || [];

  const isActiveTotalSelected = ActiveTaskStatuses.every((status) =>
    taskStatuses.includes(status),
  );

  const isTotalCell = taskStatusOrTotal === "total";
  const isActiveTotalCell = taskStatusOrTotal === "activeTotal";

  const isStatusSelected =
    !isTotalCell &&
    !isActiveTotalCell &&
    taskStatuses.includes(TaskStatusMapping[taskStatusOrTotal]);

  const isActiveStatus = isStatusSelected
    ? ActiveTaskStatuses.includes(TaskStatusMapping[taskStatusOrTotal])
    : false;

  const contained = "contained";
  const outlined = "outlined";
  let variant: "contained" | "outlined";

  const isTotalSelected = AllTaskStatuses.length === taskStatuses.length;
  if (isTotalSelected) {
    variant = isTotalCell ? contained : outlined;
  } else if (isActiveTotalSelected) {
    variant =
      isActiveTotalCell || (!isActiveStatus && isStatusSelected)
        ? contained
        : outlined;
  } else {
    variant = isStatusSelected ? contained : outlined;
  }

  const toggleTaskStatusSelection = (
    workflowKey: string,
    workflowVersion: string,
    taskStatusOrTotal: TaskStatusOrTotalString,
  ) => {
    const existingEntry = selectedWorkflowTaskStatuses.find(
      (wts) =>
        wts.workflowKey === workflowKey &&
        wts.workflowVersion === workflowVersion,
    );

    let updatedTaskStatuses: TaskStatus[];
    const totalButtonClicked = taskStatusOrTotal === "total";
    const activeTotalButtonClicked = taskStatusOrTotal === "activeTotal";

    if (totalButtonClicked) {
      updatedTaskStatuses = AllTaskStatuses;
    } else if (activeTotalButtonClicked) {
      updatedTaskStatuses = handleActiveTotalButtonClicked(existingEntry);
    } else {
      updatedTaskStatuses = handleStatusClicked(
        existingEntry,
        taskStatusOrTotal,
      );
    }

    setSelectedWorkflowTaskStatuses(
      selectedWorkflowTaskStatuses.map((wts) =>
        wts.workflowKey === row.original.key &&
        wts.workflowVersion === row.original.version
          ? { ...wts, taskStatuses: updatedTaskStatuses }
          : wts,
      ),
    );
  };

  const handleActiveTotalButtonClicked = (
    existingEntry: IWorkflowTaskStatuses | undefined,
  ): TaskStatus[] => {
    if (!existingEntry || showOverdueTasksView) {
      return ActiveTaskStatuses;
    }

    const { taskStatuses } = existingEntry;

    if (taskStatuses.length === AllTaskStatuses.length) {
      return ActiveTaskStatuses;
    }

    const isOnlyActiveTotalSelected =
      taskStatuses.length === ActiveTaskStatuses.length &&
      taskStatuses.every((status) => ActiveTaskStatuses.includes(status));
    if (isOnlyActiveTotalSelected) {
      return AllTaskStatuses;
    }

    const onlyActiveTaskStatusesSelected =
      taskStatuses.filter((status) => {
        return !ActiveTaskStatuses.includes(status);
      }).length === 0;

    if (onlyActiveTaskStatusesSelected) {
      return ActiveTaskStatuses;
    }

    const isActiveTotalAlreadySelected = ActiveTaskStatuses.every((status) =>
      taskStatuses.includes(status),
    );
    if (isActiveTotalAlreadySelected) {
      return taskStatuses.filter(
        (status) => !ActiveTaskStatuses.includes(status),
      );
    }

    return [
      ...taskStatuses.filter((status) => !ActiveTaskStatuses.includes(status)),
      ...ActiveTaskStatuses,
    ];
  };

  const handleStatusClicked = (
    existingEntry: IWorkflowTaskStatuses | undefined,
    taskStatusString: TaskStatusString,
  ): TaskStatus[] => {
    const taskStatus = TaskStatusMapping[taskStatusString];

    if (
      !existingEntry ||
      existingEntry.taskStatuses.length === AllTaskStatuses.length
    ) {
      return [taskStatus];
    }

    const { taskStatuses } = existingEntry;
    const isActiveStatus = ActiveTaskStatuses.includes(taskStatus);
    const existingEntryActiveTotalSelected = ActiveTaskStatuses.every(
      (status) => taskStatuses.includes(status),
    );

    if (existingEntryActiveTotalSelected && isActiveStatus) {
      return taskStatuses.filter(
        (status) =>
          !ActiveTaskStatuses.includes(status) || status === taskStatus,
      );
    }

    const statusAlreadySelected = taskStatuses.includes(taskStatus);

    if (existingEntryActiveTotalSelected) {
      return statusAlreadySelected
        ? taskStatuses.filter((status) => status !== taskStatus)
        : [...taskStatuses, taskStatus];
    }

    if (statusAlreadySelected && taskStatuses.length === 1) {
      return showOverdueTasksView ? ActiveTaskStatuses : AllTaskStatuses;
    }

    if (statusAlreadySelected) {
      return taskStatuses.filter((status) => status !== taskStatus);
    }

    return [...taskStatuses, taskStatus];
  };

  return (
    <Button
      variant={variant}
      color={"secondary"}
      onClick={() =>
        toggleTaskStatusSelection(
          row.original.key,
          row.original.version,
          taskStatusOrTotal,
        )
      }
      fullWidth
    >
      {Wrapped(value)}
    </Button>
  );
};

export default ExpandedRowCell;
