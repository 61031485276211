import IEventDefinitionGroup from "./IEventDefinitionGroup";
import IWorkflowDefinition from "./IWorkflowDefinition";

export interface IRepository {
  id: string;
  url: string;
  errorEmail: string;
  username?: string;
  branch: string;
  creator: string;
  authenticationType: AuthenticationType;
  created: string;
  updated: string;
  synced: string;
  name: string;
  vaultSecretKey?: string;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
}

export interface IRepositoryRequest {
  url: string;
  errorEmail: string;
  branch: string;
  authenticationType: AuthenticationType;
  username?: string;
  vaultSecretKey?: string;
}

export enum AuthenticationType {
  Public = "Public",
  SSHKey = "Ssh",
  Credentials = "Credentials",
}
