import React, { useState, useEffect } from "react";
import { Alert, Paper } from "@mui/material";
import useWorkflowLogService from "../../Common/Hooks/useWorkflowLogService";
import { DataTable, Column } from "../Tables/DataTable";
import { XmlEditor } from "../Editors";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";
import IPaged from "../../Common/Interfaces/IPaged";
import IWorkflowLog from "../../Common/Interfaces/IWorkflowLog";
import IPagination from "../../Common/Interfaces/IPagination";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import Loading from "../Loading/Loading";
import { classes } from "../../App.Styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ConfigureLogAlertsModal from "./ConfigureLogAlertsModal";
import { useAlertsConfigService } from "../../Common/Hooks/useAlertsConfigService";
import { IAlertConfig } from "../../Common/Interfaces/IAlertConfig";
import { formatUIDate } from "../../Common/Helpers/DateHelper";

interface WorkflowLogRow {
  id: string;
  logLevel: string;
  date: string;
  logMessage: string;
  workflowKey: string;
  taskKey: string;
  subjectId?: string;
  correlationId?: string;
  context: string;
}

const columns: Column<WorkflowLogRow>[] = [
  {
    Header: "Log Level",
    accessor: "logLevel",
    disableSortBy: true,
    maxWidth: 100,
  },
  {
    Header: "Log Message",
    accessor: "logMessage",
    disableSortBy: true,
    minWidth: 200,
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Task Key",
    accessor: "taskKey",
    disableSortBy: true,
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Workflow Key",
    accessor: "workflowKey",
    disableSortBy: true,
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Subject ID",
    accessor: "subjectId",
    disableSortBy: true,
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Correlation ID",
    accessor: "correlationId",
    disableSortBy: true,
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Timestamp",
    accessor: "date",
    disableSortBy: true,
    Cell: ({ value }) => <span>{formatUIDate(value)}</span>,
  },
];

const WorkflowLog = (): JSX.Element => {
  const initialPagination = (
    page: number,
    pageSize: number,
    filter: string | undefined,
  ): IPagination => {
    return {
      page,
      pageSize,
      filter,
    };
  };

  const [pagination, setPagination] = useState<IPagination>(
    initialPagination(1, 10, undefined),
  );

  const logService = useWorkflowLogService();
  const service = useAlertsConfigService();
  const [logs, setLogs] = useState<IPaged<IWorkflowLog>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [warning, setWarning] = React.useState<string>();

  const [workflowLogsFetchStatus, setWorkflowLogsFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);

  async function fetchLogs(
    pageNum: number,
    pageSize: number,
    filter: string | undefined,
  ) {
    let httpStatus = HttpStatus.Initial;
    try {
      const response = await logService.$get(pageNum, pageSize, filter);
      setLogs(response);
      httpStatus = HttpStatus.Success;
    } catch (e) {
      httpStatus = HttpStatus.Failure;
    }
    setWorkflowLogsFetchStatus(httpStatus);
  }

  async function warningCheck() {
    try {
      const response = await service.get();
      if (response.status === 200) {
        const config = response.body as IAlertConfig;
        if (config.enabled) {
          setWarning(undefined);
        } else {
          setWarning(
            'Workflow log alerts are currently disabled. To start receiving email alerts for workflow logs, please click the "Configure Alerts" button below to enable them',
          );
        }
      } else if (response.status === 404) {
        setWarning(
          'Workflow log alerts are currently not configured. To start receiving email alerts for workflow logs, please click the "Configure Alerts" button below to set it up',
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  const getEmptyDataSourceMessage = (): string => {
    switch (workflowLogsFetchStatus) {
      case HttpStatus.Success:
        return "No workflow logs to display.";
      case HttpStatus.Failure:
        return "Error fetching workflow logs, please try again.";
      default:
        return "";
    }
  };

  const mapLogs = (logs: IWorkflowLog[]): WorkflowLogRow[] => {
    return logs.map((item) => {
      const workflowKey = item.workflowKey
        ? `${item.workflowKey} (v${item.workflowDefinitionVersion})`
        : "";
      return {
        id: item.id,
        logLevel: item.logLevel,
        date: item.date,
        logMessage: item.logMessage,
        workflowKey: workflowKey,
        workflowVersion: item.workflowDefinitionVersion,
        subjectId: item.subjectId,
        correlationId: item.correlationId,
        context: item.context,
        taskKey: item.taskKey,
      };
    });
  };

  const renderDetail = (row: WorkflowLogRow) => {
    return <XmlEditor readOnly value={row.context} />;
  };

  const renderConfigureLogAlertsModal = (onClose: () => void) => {
    return (
      <ConfigureLogAlertsModal
        onClose={async () => {
          onClose();
          try {
            setLoading(true);
            await warningCheck();
          } finally {
            setLoading(false);
          }
        }}
      />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          fetchLogs(pagination.page, pagination.pageSize, pagination.filter),
          warningCheck(),
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup
    return () => {
      setLogs(undefined);
      setWorkflowLogsFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, [pagination]);

  return (
    <Paper elevation={4} className={classes.table}>
      {warning && <Alert severity="warning">{warning}</Alert>}
      <Loading visible={loading} />
      <DataTable
        data={logs ? mapLogs(logs.items) : []}
        total={logs ? logs.totalCount : 0}
        columns={columns}
        skipPageReset={null}
        renderDetail={renderDetail}
        pagination={pagination}
        setPagination={setPagination}
        name="Workflow Logs"
        aria-label="Workflow Logs"
        emptyDataSourceMessage={getEmptyDataSourceMessage()}
        customProps={{
          icon: NotificationsIcon,
          identifier: "configure-workflow-log-alerts",
          label: "Configure Alerts",
        }}
        renderCustomModal={renderConfigureLogAlertsModal}
      />
    </Paper>
  );
};

export default WorkflowLog;
