import React from "react";
import { classes } from "../../App.Styles";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";

interface IProps {
  title: string;
  subtitle?: string;
}

const NodeLabel: React.FC<IProps> = ({ title, subtitle }) => {
  return (
    <>
      <div>{title}</div>
      <code className={classes.mediumGrey}>{Wrapped(subtitle)}</code>
    </>
  );
};

export default NodeLabel;
