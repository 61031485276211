import { FC, useMemo } from "react";
import { EdgeProps, useStore, ReactFlowState, EdgeText } from "reactflow";
import {
  getEdgeParams,
  setBezierEdgeCenter,
  setBezierPath,
} from "../../Common/Helpers/EdgeParams";

const nodeSelector = (s: ReactFlowState) => s.nodeInternals;

const FloatingEdge: FC<EdgeProps> = ({
  id,
  source,
  target,
  style,
  data,
  markerEnd,
  label,
  labelStyle,
}) => {
  const nodeInternals = useStore(nodeSelector);

  const sourceNode = useMemo(
    () => nodeInternals.get(source),
    [source, nodeInternals],
  );
  const targetNode = useMemo(
    () => nodeInternals.get(target),
    [target, nodeInternals],
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const edgeParams = getEdgeParams(sourceNode, targetNode);
  const edgePath = setBezierPath(edgeParams);
  const edgeCentre = setBezierEdgeCenter(edgeParams);

  function realignText(yValue: number): number {
    switch (data.labelPosition) {
      case 1: {
        return yValue - 15;
      }
      case 2: {
        return yValue + 15;
      }
      case 3: {
        return yValue - 30;
      }
      case 4: {
        return yValue + 30;
      }
      case 5: {
        return yValue - 45;
      }
      case 6: {
        return yValue + 45;
      }
      default: {
        return yValue;
      }
    }
  }

  return (
    <g>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeText
        x={edgeCentre[0]}
        y={realignText(edgeCentre[1])}
        label={label}
        labelBgStyle={labelStyle}
        labelBgPadding={[2, 4]}
        labelBgBorderRadius={2}
      />
    </g>
  );
};

export default FloatingEdge;
