import { useTheme } from "@mui/material";
import React from "react";

interface IProps {
  className?: string;
}

const IconFilter: React.FC<IProps> = ({ className }) => {
  const theme = useTheme();
  return (
    <>
      <svg
        className={className}
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={theme.palette.primary.main}
          fill="none"
          d="M2 2L10 10V20L14 22V10L22 2H2Z"
        ></path>
      </svg>
    </>
  );
};

export default IconFilter;
