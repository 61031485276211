import { AppAbilities } from "./AppAbilities";

export function getAbilityFromString(abilityString: string): AppAbilities {
  if (abilityString.includes(":")) {
    const action = abilityString.split(":")[0];
    const subject = abilityString.split(":")[1];
    return [action, subject] as AppAbilities;
  }
  throw new Error(`invalid permission string: ${abilityString}`);
}
