import { useClient } from "../../Hooks/useClient";
import { HttpStatus } from "../Enums/HttpStatus";
import IPreviewDueExpressionRequestBody from "../Interfaces/IPreviewDueExpressionRequestBody";

export type apiFetch = { status: HttpStatus; data: string };
const apiPath = "Api/Expression";

export function useExpressionService(): {
  preview: (requestBody: IPreviewDueExpressionRequestBody) => Promise<apiFetch>;
} {
  const client = useClient();

  async function previewDue(
    requestBody: IPreviewDueExpressionRequestBody,
  ): Promise<apiFetch> {
    try {
      const response = await client.post(`${apiPath}/preview/due`, requestBody);
      return response.success
        ? { status: HttpStatus.Success, data: response.body as string }
        : { status: HttpStatus.Failure, data: "" };
    } catch (e) {
      console.error(e);
      return { status: HttpStatus.Failure, data: "" };
    }
  }

  return {
    preview: previewDue,
  };
}
