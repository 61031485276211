import { alpha } from "@mui/material";
import { blue, orange, red, yellow } from "@mui/material/colors";
import theme from "../App/App.Theme";

const alphaValue = 0.7;

export class TaskStatusColours {
  static readonly New = alpha(blue[600], alphaValue);
  static readonly InProgress = alpha(theme.palette.secondary.main, alphaValue);
  static readonly Failed = alpha(red[900], alphaValue);
  static readonly Blocked = alpha(orange[800], alphaValue);
  static readonly Cancelled = alpha(yellow[700], alphaValue);
  static readonly Completed = alpha(theme.palette.primary.main, alphaValue);
}
