import * as React from "react";
import {
  Popper,
  Fade,
  Paper,
  Typography,
  Button,
  Chip,
  Grid2,
} from "@mui/material";
import useOnClickOutside from "use-onclickoutside";
import classNames from "classnames";
import { classes } from "../../App.Styles";
import Icon from "@mui/icons-material/CalendarMonth";
import { DateTimePicker, PickersDay } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { styled } from "@mui/material/styles";
import theme from "../../App/App.Theme";

const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  "&:hover, &:focus": {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  "&.MuiPickersDay-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
  },
  "&.MuiPickersDay-today": {
    borderColor: theme.palette.secondary.main,
  },
}));

const actionBarStyles = {
  "& .MuiButton-root": {
    color: theme.palette.secondary.main,
  },
};

const popperStyles = {
  "& .MuiMultiSectionDigitalClockSection-item.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiMultiSectionDigitalClockSection-item.Mui-selected:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
};

interface IProps {
  onSave: (start: Moment | null, end: Moment | null) => void;
}

// 7 days = 6 days before the start of today
const sevenDaysDate = moment().startOf("day").subtract(6, "days");

const PopupTimeRangeSelect: React.FC<IProps> = ({ onSave }) => {
  const [anchor, setAnchor] = React.useState<HTMLElement>();
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [start, setStart] = React.useState<Moment | null>(sevenDaysDate);
  const [end, setEnd] = React.useState<Moment | null>(null);
  const [editedStart, setEditedStart] = React.useState<Moment | null>(
    sevenDaysDate,
  );
  const [editedEnd, setEditedEnd] = React.useState<Moment | null>(null);
  const [label, setLabel] = React.useState<string>("");

  const ref = React.useRef(null);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClickOutside = () => {
    if (calendarOpen) return;
    setAnchor(undefined);
  };

  const onCancelClick = () => {
    setAnchor(undefined);
    setEditedStart(start);
    setEditedEnd(end);
  };

  const onSaveClick = () => {
    setAnchor(undefined);
    setStart(editedStart);
    setEnd(editedEnd);
    onSave(editedStart, editedEnd);
  };

  const onPresetClick = (start: Moment | null) => {
    setEditedStart(start);
    setEditedEnd(null);
  };

  useOnClickOutside(ref, handleClickOutside);

  React.useEffect(() => {
    if (!start && !end) {
      setLabel("All Time");
      return;
    }

    const startLabel = start ? start.format("DD-MMM-YYYY HH:mm") : "Before";
    const separator = start ? " → " : " ";
    const endLabel = end ? end.format("DD-MMM-YYYY HH:mm") : "Now";
    setLabel(startLabel + separator + endLabel);
  }, [start, end]);

  return (
    <React.Fragment>
      <Chip
        avatar={<Icon fontSize="inherit" />}
        label={label}
        variant="outlined"
        clickable
        onClick={onClick}
      />
      <Popper
        style={{ zIndex: 1000 }}
        open={anchor !== undefined}
        anchorEl={anchor}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={ref} className={classes.popupPaper} elevation={6}>
              <Grid2 container spacing={2}>
                <Grid2 size={12}>
                  <Typography variant="h6" color="secondary">
                    Filter by Date Range
                  </Typography>
                </Grid2>
                <Grid2 size={12}>
                  <DateTimePicker
                    onOpen={() => setCalendarOpen(true)}
                    onClose={() => setCalendarOpen(false)}
                    onChange={(val) => setEditedStart(val)}
                    value={editedStart}
                    label="Start Date"
                    maxDate={moment()}
                    slotProps={{
                      textField: { fullWidth: true, color: "secondary" },
                      actionBar: {
                        actions: ["clear", "today", "accept"],
                        sx: actionBarStyles,
                      },
                      popper: {
                        sx: popperStyles,
                      },
                    }}
                    slots={{
                      day: StyledPickersDay,
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <DateTimePicker
                    onOpen={() => setCalendarOpen(true)}
                    onClose={() => setCalendarOpen(false)}
                    onChange={(val) => setEditedEnd(val)}
                    value={editedEnd}
                    label="End Date"
                    maxDate={moment()}
                    slotProps={{
                      textField: { fullWidth: true, color: "secondary" },
                      actionBar: {
                        actions: ["clear", "today", "accept"],
                        sx: actionBarStyles,
                      },
                      popper: {
                        sx: popperStyles,
                      },
                    }}
                    slots={{
                      day: StyledPickersDay,
                    }}
                  />
                </Grid2>
                <Grid2 container spacing={0}>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() =>
                        onPresetClick(moment().subtract(15, "minutes"))
                      }
                    >
                      15 Minutes
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() =>
                        onPresetClick(moment().subtract(1, "hour"))
                      }
                    >
                      1 Hour
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() =>
                        onPresetClick(moment().subtract(6, "hours"))
                      }
                    >
                      6 Hours
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() =>
                        onPresetClick(moment().subtract(24, "hours"))
                      }
                    >
                      24 Hours
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() => onPresetClick(sevenDaysDate)}
                    >
                      7 Days
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() =>
                        onPresetClick(
                          moment().startOf("day").subtract(29, "days"),
                        )
                      }
                    >
                      30 Days
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() => onPresetClick(moment().startOf("day"))}
                    >
                      Today
                    </Button>
                  </Grid2>
                  <Grid2 size={6}>
                    <Button
                      color="secondary"
                      onClick={() => onPresetClick(null)}
                    >
                      All Time
                    </Button>
                  </Grid2>
                </Grid2>
                <Grid2 size={12}>
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={onCancelClick}
                      className={classNames(
                        classes.button,
                        classes.buttonCancel,
                      )}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={onSaveClick}
                      className={classNames(classes.button, classes.buttonSave)}
                    >
                      Filter
                    </Button>
                  </div>
                </Grid2>
              </Grid2>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default PopupTimeRangeSelect;
