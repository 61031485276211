import * as React from "react";
import { Tooltip, Avatar } from "@mui/material";
import KeyIcon from "@mui/icons-material/VpnKey";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import { EllipseChip } from "../EllipseChip/EllipseChip";
import { FC } from "react";
import { blueGrey } from "@mui/material/colors";

interface IProps {
  workflow: IWorkflowDefinition;
}

const WorkflowKeyManager: FC<IProps> = ({ workflow }) => {
  const title = workflow.isGitImported
    ? "Imported from Git Repository"
    : "The Key cannot be changed";
  return (
    <React.Fragment>
      <Tooltip title={title}>
        <EllipseChip
          avatar={
            <Avatar sx={{ background: blueGrey[100] }}>
              <KeyIcon fontSize="inherit" />
            </Avatar>
          }
          label={workflow.key}
        />
      </Tooltip>
    </React.Fragment>
  );
};

export default WorkflowKeyManager;
