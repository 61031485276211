import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid2,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TextFilter from "./TextFilter";
import TaskStatusFilter from "./TaskStatusFilter";
import IconSubject from "@mui/icons-material/Person";
import IconAssignee from "@mui/icons-material/PersonOutline";
import IconStatus from "@mui/icons-material/PlaylistAddCheck";
import IconType from "@mui/icons-material/CategoryOutlined";
import IconCorrelationId from "@mui/icons-material/PolylineOutlined";
import TaskPageSize from "../Paging/TaskPageSize";
import { classes } from "../../App.Styles";
import TaskTypeFilter from "./TaskTypeFilter";
import { ITaskFilters } from "../../Interfaces/ITaskFIlters";
import { TaskType } from "../../Common/Enums/TaskType";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { TaskStatus } from "../../Common/Enums/TaskStatus";

interface IProps {
  pageSize: number;
  filters: ITaskFilters;
  onFiltersChange: (filters: ITaskFilters) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const TaskFilterBar: React.FC<IProps> = ({
  pageSize,
  filters,
  onFiltersChange,
  onPageSizeChange,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const onChangeSubject = (subject: string | undefined) => {
    onFiltersChange({
      ...filters,
      subjectId: subject,
    });
  };

  const onChangeCorrelationId = (correlationId: string | undefined) => {
    onFiltersChange({
      ...filters,
      correlationId,
    });
  };

  const onChangeAssignee = (assignee: string | undefined) => {
    onFiltersChange({
      ...filters,
      assigneeId: assignee,
    });
  };

  const onChangeStatus = (taskStatus: TaskStatus | undefined) => {
    onFiltersChange({
      ...filters,
      taskStatuses: taskStatus ? [taskStatus] : undefined,
    });
  };

  const onChangeType = (type: TaskType | undefined) => {
    onFiltersChange({
      ...filters,
      taskType: type,
    });
  };

  const getFiltersCount = (): number => {
    let count = 0;
    const keys: Array<keyof ITaskFilters> = Object.keys(filters) as Array<
      keyof ITaskFilters
    >;
    for (const key of keys) {
      if (filters[key]) {
        count++;
      }
    }
    return count;
  };

  const filtersLabel = () => {
    const filtersCount = getFiltersCount();
    return (
      <Grid2 container>
        <Grid2 display={"flex"} alignItems={"center"}>
          <Box sx={{ pr: "5px" }}>Filters</Box>
          {filtersCount > 0 ? (
            <Box aria-label="filters-count">{`(${filtersCount})`}</Box>
          ) : (
            <></>
          )}
        </Grid2>
      </Grid2>
    );
  };

  const renderFilterOptions = () => {
    const labelGridSize = 5;
    const filterGridSize = 7;
    return (
      <Grid2 container direction="row" spacing={1}>
        <Grid2 className={classes.filterOptionLabelItem} size={labelGridSize}>
          <IconStatus className={classes.filterOptionIcon} />
          <FormLabel>Status</FormLabel>
        </Grid2>
        <Grid2 size={filterGridSize}>
          <TaskStatusFilter
            name="Select Status"
            tooltip="Filter by Task Status"
            taskStatus={
              filters.taskStatuses ? filters.taskStatuses[0] : undefined
            }
            onChange={onChangeStatus}
          />
        </Grid2>
        <Grid2 className={classes.filterOptionLabelItem} size={labelGridSize}>
          <IconType className={classes.filterOptionIcon} />
          <FormLabel>Type</FormLabel>
        </Grid2>
        <Grid2 size={filterGridSize}>
          <TaskTypeFilter
            name="Select Type"
            tooltip="Filter by Task Type"
            value={filters.taskType}
            onChange={onChangeType}
          />
        </Grid2>
        <Grid2 className={classes.filterOptionLabelItem} size={labelGridSize}>
          <IconAssignee className={classes.filterOptionIcon} />
          <FormLabel>Assignee</FormLabel>
        </Grid2>
        <Grid2 size={filterGridSize}>
          <TextFilter
            name="Enter Assignee ID"
            tooltip="Filter by Assignee ID"
            value={filters.assigneeId}
            onChange={onChangeAssignee}
          />
        </Grid2>
        <Grid2 className={classes.filterOptionLabelItem} size={labelGridSize}>
          <IconSubject className={classes.filterOptionIcon} />
          <FormLabel>Subject</FormLabel>
        </Grid2>
        <Grid2 size={filterGridSize}>
          <TextFilter
            name="Enter Subject ID"
            tooltip="Filter by Subject ID"
            value={filters.subjectId}
            onChange={onChangeSubject}
          />
        </Grid2>
        <Grid2 className={classes.filterOptionLabelItem} size={labelGridSize}>
          <IconCorrelationId className={classes.filterOptionIcon} />
          <FormLabel>Correlation ID</FormLabel>
        </Grid2>
        <Grid2 size={filterGridSize}>
          <TextFilter
            name="Enter Correlation ID"
            tooltip="Filter by Correlation ID"
            value={filters.correlationId}
            onChange={onChangeCorrelationId}
          />
        </Grid2>
      </Grid2>
    );
  };

  return (
    <>
      <Grid2 container className={classes.filterBar}>
        <Grid2 size={2}>
          <Typography
            variant="h6"
            aria-label="Tasks"
            className={classes.tasksTitle}
          >
            Tasks
          </Typography>
        </Grid2>
        <Grid2 container direction="row" justifyContent="flex-end" size={10}>
          <TaskPageSize pageSize={pageSize} onChange={onPageSizeChange} />
          <Tooltip title="Filters">
            <Chip
              className={classes.filter}
              icon={
                getFiltersCount() > 0 ? <FilterAltIcon /> : <FilterAltOffIcon />
              }
              label={filtersLabel()}
              variant={"outlined"}
              clickable
              onClick={() => setFiltersOpen(true)}
              color={getFiltersCount() > 0 ? "secondary" : "default"}
            />
          </Tooltip>
        </Grid2>
      </Grid2>
      <Dialog
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        PaperProps={{
          elevation: 1,
        }}
        maxWidth={"xs"}
      >
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <div>{renderFilterOptions()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFiltersOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskFilterBar;
