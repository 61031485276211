import React from "react";
import ItemAccordion from "../Definitions/DefinitionDetail/ItemAccordion";
import { XmlEditor } from "../Editors";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  onChange: (context: string) => void;
  value: string;
  name: string;
  icon?: () => JSX.Element;
}

const ContextInput: React.FC<IProps> = ({
  onChange,
  value,
  name,
  icon,
  readOnly,
}) => {
  return (
    <ItemAccordion name={name} icon={icon}>
      <XmlEditor onChange={onChange} value={value} readOnly={readOnly} />
    </ItemAccordion>
  );
};

export default ContextInput;
