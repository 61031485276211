import { PropsWithChildren } from "react";
import { AppAbilities } from "./Common/Authorisation/AppAbilities";
import { useAbility } from "./Components/Authorisation/AbilityContext";
import NoRole from "./Components/NoRolePage";

type Props = {
  appAbilities: AppAbilities;
};

export const ProtectedRoute = ({
  appAbilities,
  children,
}: PropsWithChildren<Props>) => {
  const ability = useAbility();

  if (ability.cannot(appAbilities[0], appAbilities[1])) {
    return <NoRole />;
  }

  return children;
};
