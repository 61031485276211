import { useState } from "react";
import IUITaskDefinition from "../../../Common/Interfaces/IUITaskDefinition";
import ITaskDefinition from "../../../Common/Interfaces/ITaskDefinition";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import TaskIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { TaskType } from "../../../Common/Enums/TaskType";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import DefinitionDeleteAlert from "../DefinitionDeleteAlert/DefinitionDeleteAlert";
import { DefinitionType } from "../../../Common/Enums/DefinitionType";
import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import DraggableTask from "./DraggableTask";

interface IProps extends IReadOnlyComponentProps {
  tasks: IUITaskDefinition[];
  context: string;
  onChange: (tasks: ITaskDefinition[]) => void;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
  workflowDefinitionId: string;
  workflowDefinitionKey: string;
  workflowVersion: number;
}

const Tasks: React.FC<IProps> = ({
  tasks,
  onChange,
  context,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  workflowDefinitionId,
  workflowDefinitionKey,
  workflowVersion,
  readOnly,
}) => {
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<IUITaskDefinition>();
  const [expandedAccordions, setExpandedAccordions] = useState(
    new Array(tasks.length).fill(false),
  );
  const [accordionClicked, setAccordionClicked] = useState(false);

  const onAddTask = () => {
    const task = {
      id: "",
      taskType: TaskType.Manual,
      activityContext: {
        Due: '"{{ "now" | plus_time: days: 1 }}"',
      },
      description: "",
      assigneeId: "",
      assigneeType: "",
      owner: "",
      filters: [],
      triggers: [],
      triggeredUpdates: [],
      name: "",
      emittedContext: "",
      state: {},
      retryOnFailure: undefined,
      taskKey: "",
      exclusive: false,
      tempId: `temp-id-${Math.random()}`,
    } as IUITaskDefinition;
    onChange([...tasks, task]);
    setExpandedAccordions([...expandedAccordions, false]);
  };

  const onDeleteTask = async (id: string) => {
    onChange(tasks.filter((t) => t.id !== id && t.tempId !== id));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const movedTask = tasks[startIndex];
    const newTasks = [...tasks];
    newTasks.splice(startIndex, 1);
    newTasks.splice(endIndex, 0, movedTask);
    onChange(newTasks);

    const newExpandedAccordions = [...expandedAccordions];
    const valueToMove = newExpandedAccordions.splice(startIndex, 1)[0];
    newExpandedAccordions.splice(endIndex, 0, valueToMove);
    setAccordionClicked(false);
    setExpandedAccordions(newExpandedAccordions);
  };

  return (
    <>
      <ItemAccordion
        name="Tasks"
        count={tasks.length}
        icon={() => <TaskIcon />}
        onAdd={onAddTask}
        readOnly={readOnly}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {tasks.map((task, index) => {
                  return (
                    <DraggableTask
                      context={context}
                      eventDefinitionGroups={eventDefinitionGroups}
                      eventDefinitions={eventDefinitions}
                      index={index}
                      task={task}
                      tasks={tasks}
                      workflowDefinitionId={workflowDefinitionId}
                      workflowDefinitionKey={workflowDefinitionKey}
                      workflowDefinitions={workflowDefinitions}
                      workflowVersion={workflowVersion}
                      accordionClicked={accordionClicked}
                      expandedAccordions={expandedAccordions}
                      onChange={onChange}
                      setDeleteOpen={setDeleteOpen}
                      setSelectedTask={setSelectedTask}
                      setAccordionClicked={setAccordionClicked}
                      setExpandedAccordions={setExpandedAccordions}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ItemAccordion>
      {selectedTask && (
        <span>
          <DefinitionDeleteAlert
            definition={selectedTask}
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            action={() => onDeleteTask(selectedTask.tempId ?? selectedTask.id)}
            definitionType={DefinitionType.Task}
            context={context}
          />
        </span>
      )}
    </>
  );
};

export default Tasks;
