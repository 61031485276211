export interface IDueDateProperties {
  Months: number;
  Weeks: number;
  Days: number;
  Hours: number;
  Minutes: number;
  RoundToNext: string[];
  IsNow: boolean;
  DateInput: string;
}

export const IsEqual = (
  obj1: IDueDateProperties,
  obj2: IDueDateProperties,
): boolean => {
  return (
    obj1.Months === obj2.Months &&
    obj1.Weeks === obj2.Weeks &&
    obj1.Days === obj2.Days &&
    obj1.Hours === obj2.Hours &&
    obj1.Minutes === obj2.Minutes &&
    arraysEqual(obj1.RoundToNext, obj2.RoundToNext) &&
    obj1.IsNow === obj2.IsNow &&
    obj1.DateInput === obj2.DateInput
  );
};

const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
  return (
    arr1.length === arr2.length &&
    arr1.every((value, index) => value === arr2[index])
  );
};
