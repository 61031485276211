import React, { useState } from "react";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Popper,
  Switch,
  Typography,
  Grid2,
} from "@mui/material";
import ITriggeredTask from "../../../Common/Interfaces/ITriggeredTask";
import classNames from "classnames";
import { classes } from "../../../App.Styles";

interface IProps {
  triggeredTasks: ITriggeredTask[];
  version: number;
  confirmChangeVersionEnabled: () => void;
  enabled?: boolean;
  disableControl?: boolean;
}

const VersionEnabledSwitch: React.FC<IProps> = ({
  triggeredTasks,
  version,
  confirmChangeVersionEnabled,
  enabled,
  disableControl,
}) => {
  const [anchor, setAnchor] = useState<HTMLElement>();

  const onChange = (event: React.ChangeEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (enabled && triggeredTasks.length > 0) {
      setAnchor(event.currentTarget);
    } else {
      confirmChangeVersionEnabled();
    }
  };

  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              disabled={disableControl}
              checked={enabled}
              onChange={(e) => onChange(e)}
              aria-label={`version-${version}-switch`}
              color="secondary"
            />
          }
          label="Enabled"
          labelPlacement="start"
          style={{ marginRight: 0 }}
        />
      </FormGroup>
      <Popper
        style={{ zIndex: 1000 }}
        open={anchor !== undefined}
        anchorEl={anchor}
      >
        <Paper
          className={classes.versionEnabledPaper}
          elevation={6}
          aria-label={`version-${version}-paper-warning`}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <Typography variant="h6" color="secondary">
                The following tasks are triggered by version {version}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              {triggeredTasks.map((task, index) => {
                return (
                  <Typography key={`${task.taskName}_${index}`}>
                    <strong>Task:</strong> {task.taskName}{" "}
                    <strong>Workflow:</strong> {task.workflowName}
                  </Typography>
                );
              })}
            </Grid2>
            <Grid2 size={12}>
              <Typography variant="h6">
                Do you still want to disable this version?
              </Typography>
            </Grid2>
            <Grid2 className={classes.buttonContainer} size={12}>
              <Button
                className={classNames(classes.button, classes.buttonCancel)}
                onClick={() => setAnchor(undefined)}
              >
                No
              </Button>
              <Button
                className={classNames(classes.button, classes.buttonSave)}
                onClick={() => confirmChangeVersionEnabled()}
              >
                Yes
              </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Popper>
    </React.Fragment>
  );
};

export default VersionEnabledSwitch;
