import { Navigate, Routes, Route } from "react-router-dom";
import { AuthenticatedRoute } from "./Components/AuthenticatedRoute/AuthenticatedRoute";
import DefinitionsList from "./Components/Definitions/DefinitionsList";
import IFrame from "./Components/IFrame/IFrame";
import EventDefinitions from "./Components/EventDefinitions/EventDefinitions";
import EventLog from "./Components/EventLog/EventLog";
import WorkflowLogs from "./Components/WorkflowLogs/WorkflowLogs";
import Repositories from "./Components/Repositories/Repositories";
import Home from "./Components/Home";
import EventDefinitionGroups from "./Components/EventDefinitionGroups/EventDefinitionGroups";
import { ProtectedRoute } from "./ProtectedRoute";
import { AbilityContextProvider } from "./Components/Authorisation/AbilityContext";
import { useAppContext } from "./App/App.Context";
import Metrics from "./Components/Metrics/Metrics";
import TaskView from "./Components/TaskView";

const Routing = (): JSX.Element => {
  const { permissionsClaim } = useAppContext();

  return (
    <AbilityContextProvider permissionsClaim={permissionsClaim}>
      <Routes>
        <Route path="/" element={<AuthenticatedRoute />}>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route
            path="/WorkflowDefinitions"
            element={
              <ProtectedRoute appAbilities={["view", "workflow"]}>
                <DefinitionsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EventDefinitions"
            element={
              <ProtectedRoute appAbilities={["view", "eventDefinition"]}>
                <EventDefinitions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EventDefinitionGroups"
            element={
              <ProtectedRoute appAbilities={["view", "eventDefinitionGroup"]}>
                <EventDefinitionGroups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Tasks"
            element={
              <ProtectedRoute appAbilities={["view", "task"]}>
                <TaskView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Metrics"
            element={
              <ProtectedRoute appAbilities={["view", "metrics"]}>
                <Metrics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EventLog"
            element={
              <ProtectedRoute appAbilities={["view", "event"]}>
                <EventLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="/WorkflowLogs"
            element={
              <ProtectedRoute appAbilities={["view", "workflowLog"]}>
                <WorkflowLogs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/RepoManagement"
            element={
              <ProtectedRoute appAbilities={["view", "repository"]}>
                <Repositories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Api-Documentation"
            element={
              <IFrame
                url={`/swagger-documentation/index.html`}
                queryString={""}
              />
            }
          />
        </Route>
      </Routes>
    </AbilityContextProvider>
  );
};

export default Routing;
