import {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  useCallback,
} from "react";

interface IUnsavedChangesContext {
  editedIds: Set<string>;
  addEditedId: (id: string) => void;
  removeEditedId: (id: string) => void;
  clearEditedIds: () => void;
}

const UnsavedChangesContext = createContext<IUnsavedChangesContext | undefined>(
  undefined,
);

export const UnsavedChangesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [editedIds, setEditedIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (editedIds.size > 0) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // For most browsers
        return message; // For some older versions
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editedIds]);

  const addEditedId = useCallback((id: string) => {
    setEditedIds((value) => {
      if (value.has(id)) return value;
      return new Set([...value, id]);
    });
  }, []);

  const removeEditedId = useCallback((id: string) => {
    setEditedIds((value) => {
      if (!value.has(id)) return value;
      value.delete(id);
      return new Set(value);
    });
  }, []);

  const clearEditedIds = useCallback(() => {
    setEditedIds((value) => {
      if (value.size === 0) return value;
      return new Set();
    });
  }, []);

  return (
    <UnsavedChangesContext.Provider
      value={{ editedIds, addEditedId, removeEditedId, clearEditedIds }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChangesContext = () => {
  const context = useContext(UnsavedChangesContext);

  if (!context)
    throw new Error("Unsaved changes context has not been registered");

  return context;
};
