import * as React from "react";

interface IProps {
  url: string;
  queryString?: string;
}

const styles = {
  frame: {
    border: "none",
    width: "100%",
    height: window.outerHeight,
  },
};

const IFrame: React.FC<IProps> = ({ url, queryString }) => {
  url = new URL(url, window.location.href).toString();
  const initialize = () => {
    const iframe = document.getElementById("frame") as HTMLIFrameElement;
    iframe?.contentWindow?.postMessage("aireflow", url);
  };

  const getSrc = () => {
    if (queryString) {
      return `${url}?${queryString}`;
    }
    return url;
  };

  return (
    <iframe
      src={getSrc()}
      style={styles.frame}
      onLoad={initialize}
      id="frame"
      title="frame"
    />
  );
};

export default IFrame;
