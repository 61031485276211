import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "./DeleteConfirmation";
import ItemAccordionSummary from "../Definitions/DefinitionDetail/ItemAccordionSummary";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  name: string;
  onDelete: () => void;
  placeholder?: string;
  extraText?: string;
}

const AccordionSummaryWithDelete: React.FC<IProps> = ({
  name,
  placeholder,
  onDelete,
  extraText,
  readOnly,
}) => {
  const [deleting, setDeleting] = useState(false);

  return deleting ? (
    <DeleteConfirmation
      name={name ? name : (placeholder ?? "")}
      onCancel={() => setDeleting(false)}
      onConfirm={() => {
        onDelete();
        setDeleting(false);
      }}
    />
  ) : (
    <ItemAccordionSummary
      name={name}
      placeholder={placeholder}
      onAction={() => setDeleting(true)}
      actionIcon={() => <DeleteIcon />}
      extraText={extraText}
      readOnly={readOnly}
    />
  );
};

export default AccordionSummaryWithDelete;
