import * as React from "react";
import { jsonClient } from "hippity";
import { useEffect, useState } from "react";
import { TaskStatus } from "../Common/Enums/TaskStatus";

export interface IStatusServicesContext {
  transitions: Map<TaskStatus, TaskStatus[]>;
}

export const statusServicesContext =
  React.createContext<IStatusServicesContext>({
    transitions: new Map<TaskStatus, TaskStatus[]>(),
  });

export const useStatusServicesContext = (): IStatusServicesContext =>
  React.useContext(statusServicesContext);

export const StatusServicesContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [transitions, setTransitions] = useState<Map<TaskStatus, TaskStatus[]>>(
    new Map<TaskStatus, TaskStatus[]>(),
  );

  useEffect(() => {
    (async function () {
      setTransitions(await GetTransitions());
    })();
  }, []);

  const GetTransitions = async (): Promise<Map<TaskStatus, TaskStatus[]>> => {
    const response = (await jsonClient.$get(
      "/Api/TaskStatus/Transitions",
    )) as Record<string, TaskStatus[]>;
    const transitionMap = new Map<TaskStatus, TaskStatus[]>();

    for (const [key, value] of Object.entries(response)) {
      const statusKey: TaskStatus = TaskStatus[key as keyof typeof TaskStatus];
      transitionMap.set(statusKey, value);
    }

    return transitionMap;
  };

  return (
    <statusServicesContext.Provider value={{ transitions }}>
      {children}
    </statusServicesContext.Provider>
  );
};
