import { AuthProvider, AuthProviderProps } from "oidc-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { useAppContext } from "../../App/App.Context";
import { useAuth } from "../../Hooks/useAuth";

const AuthWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return <JWTWrapper>{children}</JWTWrapper>;
};

const JWTWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { authority, clientId } = useAppContext();
  const navigate = useNavigate();

  const oidcConfig: AuthProviderProps = {
    onBeforeSignIn: () => {
      return window.location.pathname;
    },
    onSignIn: (user) => {
      const path =
        user && typeof user.state === "string"
          ? user.state
          : window.location.pathname;
      navigate(path); // This strips the auth code and redirects user to onBeforeSignIn location
    },
    authority: authority,
    clientId: clientId,
    responseType: "code",
    scope: "openid AireFlowManagementApi profile email",
    redirectUri: new URL("/", window.location.href).toString(),
    postLogoutRedirectUri: new URL("/", window.location.origin).toString(),
  };

  return (
    <AuthProvider {...oidcConfig}>
      <ExpiredTokenWrapper>{children}</ExpiredTokenWrapper>
    </AuthProvider>
  );
};

const ExpiredTokenWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { tokenExpired, signOut } = useAuth();

  if (tokenExpired()) {
    return (
      <Button onClick={signOut} sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
        Click here if you are not redirected
      </Button>
    );
  } else {
    return <>{children}</>;
  }
};

export default AuthWrapper;
