import React from "react";
import {
  Grid2,
  Button,
  TextField,
  Typography,
  MenuItem,
  Box,
} from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { ITableRepositoryData } from "../../Common/Interfaces/ITableRepositoryData";
import { AuthenticationType } from "../../Common/Interfaces/IRepository";
import Help from "../Help/Help";
import { useAppContext } from "../../App/App.Context";
import { classes } from "../../App.Styles";

interface IProps {
  repo: ITableRepositoryData;
  resetAddRow?: () => void;
  onSave?: (model: ITableRepositoryData, resetAddRow: () => void) => void;
  validate?: (model: any) => boolean;
  rowProps?: any;
}

interface IUrlPrefixValidation {
  valid: boolean;
  message: string;
}

const validateUrlPrefix = (
  url: string,
  authenticationType: AuthenticationType,
): IUrlPrefixValidation => {
  const http = "http://";
  const https = "https://";
  const git = "git@";

  if (url.length == 0) {
    return { valid: true, message: "" } as IUrlPrefixValidation;
  }
  const prefix =
    authenticationType == AuthenticationType.Credentials
      ? `${http} or ${https}`
      : authenticationType == AuthenticationType.SSHKey
        ? `${git}`
        : null;
  if (
    (authenticationType == AuthenticationType.Credentials &&
      !url.startsWith(http) &&
      !url.startsWith(https)) ||
    (authenticationType == AuthenticationType.SSHKey && !url.startsWith(git))
  ) {
    return {
      valid: false,
      message: `The URL prefix must begin with ${prefix} for Authentication Type ${authenticationType}.`,
    } as IUrlPrefixValidation;
  }
  return { valid: true, message: "" } as IUrlPrefixValidation;
};

const RepoDetail: React.FC<IProps> = ({
  repo,
  onSave,
  resetAddRow,
  validate,
  rowProps,
}) => {
  const appContext = useAppContext();
  const [repository, setRepository] = useState<ITableRepositoryData>(repo);
  const [valid, setValid] = useState<boolean>(
    (validate ? validate(repo) : null) ?? false,
  );
  const [urlPrefixValidation, setUrlPrefixValidation] =
    useState<IUrlPrefixValidation>(
      validateUrlPrefix(repository.url, repository.authenticationType),
    );

  const tableRowProps = rowProps ? rowProps : () => undefined;

  const handleOnChange = (key: string, value: any) => {
    const newState = { ...repository, [key]: value };
    setRepository(newState);
    validateModel(newState);
  };

  const handleResetAddRow = () =>
    resetAddRow ? resetAddRow() : () => undefined;

  const handleOnAuthenticationTypeChange = (event: any) => {
    const authenticationType = event.target.value as AuthenticationType;
    const newState = {
      ...repository,
      authenticationType: authenticationType,
      username: undefined,
      vaultSecretKey: undefined,
    };

    setRepository(newState);
    validateModel(newState);
  };

  const validateModel = (state: ITableRepositoryData) => {
    setValid((validate ? validate(state) : null) ?? false);
    const urlPrefixValidation = validateUrlPrefix(
      state.url,
      state.authenticationType,
    );
    setUrlPrefixValidation(urlPrefixValidation);
  };

  return (
    <div className={classes.container} id="repository_detail">
      <Grid2 container>
        <Grid2
          container
          spacing={2}
          className={classNames(classes.itemContainer, classes.extraPadding)}
          size={12}
        >
          <Grid2 size={12}>
            <Typography variant="h6">Repository Details</Typography>
          </Grid2>
          <Grid2 size={12}>
            <TextField
              fullWidth
              variant="outlined"
              required
              value={repository.url}
              onChange={(e) => handleOnChange("url", e.target.value)}
              label="Repository URL"
              type="text"
              error={!urlPrefixValidation.valid}
              helperText={urlPrefixValidation.message}
              slotProps={{
                input: {
                  startAdornment: (
                    <Help text="If Authentication Type is set as Credentials your URL must begin with http:// or https:// (https://bitbucket.org/aireflow-test-public/aireflow-test-public.git). If it is set as SSH Key then your URL must begin with git@ (git@bitbucket.org:xmlsolutions/aireflow-test-public.git)." />
                  ),
                },
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              value={repository.branch}
              onChange={(e) => handleOnChange("branch", e.target.value)}
              label="Repository Branch"
              type="text"
              slotProps={{
                input: {
                  startAdornment: (
                    <Help text="The branch within the repository it will import from. e.g. 'master'" />
                  ),
                },
              }}
            />
          </Grid2>
          <Grid2 size={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              value={repository.errorEmail}
              onChange={(e) => handleOnChange("errorEmail", e.target.value)}
              label="Error email address"
              type="email"
              slotProps={{
                input: {
                  startAdornment: (
                    <Help text="An email address where you will be notified of errors." />
                  ),
                },
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Box mt={2}>
              <Typography variant="h6">Authentication Details</Typography>
            </Box>
          </Grid2>
          <Grid2 size={12}>
            <TextField
              select
              fullWidth
              label="Authentication Type"
              variant="outlined"
              placeholder={"Authentication Type"}
              value={repository.authenticationType}
              onChange={handleOnAuthenticationTypeChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <Help text="Sets the type of authentication for the repository. You can only have one type of authentication for the repo." />
                  ),
                },
              }}
            >
              <MenuItem value={AuthenticationType.Public}>Public</MenuItem>
              {appContext.aireVaultEnabled && (
                <MenuItem value={AuthenticationType.Credentials}>
                  Credentials
                </MenuItem>
              )}
              {appContext.aireVaultEnabled && (
                <MenuItem value={AuthenticationType.SSHKey}>SSH Key</MenuItem>
              )}
            </TextField>
          </Grid2>
          {repository.authenticationType == AuthenticationType.Credentials && (
            <>
              <Grid2 size={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Username"
                  value={repository.username}
                  onChange={(e) => handleOnChange("username", e.target.value)}
                />
              </Grid2>
            </>
          )}
          {repository.authenticationType != AuthenticationType.Public && (
            <Grid2 size={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Vault Secret Key"
                type="vaultSecretKey"
                value={repository.vaultSecretKey}
                onChange={(e) =>
                  handleOnChange("vaultSecretKey", e.target.value)
                }
                slotProps={{
                  input: {
                    startAdornment: (
                      <Help
                        text={`The key of a secret stored in AireVault to use as the repository ${
                          repository.authenticationType ==
                          AuthenticationType.SSHKey
                            ? "SSH Key"
                            : "Password"
                        }`}
                      />
                    ),
                  },
                }}
              />
            </Grid2>
          )}
        </Grid2>
      </Grid2>
      <Grid2
        className={classNames(
          classes.itemContainer,
          classes.buttonContainer,
          classes.transparentBorderless,
        )}
        size={12}
      >
        <span {...tableRowProps()}>
          <Button
            className={classNames(classes.button, classes.buttonCancel)}
            onClick={resetAddRow}
          >
            Cancel
          </Button>
        </span>
        <Button
          className={classNames(classes.button, classes.buttonSave)}
          disabled={!valid}
          onClick={() => {
            onSave ? onSave(repository, handleResetAddRow) : null;
          }}
        >
          Save
        </Button>
      </Grid2>
    </div>
  );
};

export default RepoDetail;
