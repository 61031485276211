import React, { useEffect, useState } from "react";
import { useTasksService } from "../Hooks/useTasksService";
import { ISubjectTasks } from "../Interfaces/ISubjectTasks";
import { TableTaskHelper } from "../Helpers/TableTaskHelper";
import SubjectView from "./SubjectView";
import Loading from "./Loading/Loading";
import { StatusServicesContextProvider } from "../Services/TaskStatusServiceContext";
import SnackbarAlert from "./SnackbarAlert";
import { ITaskFilters } from "../Interfaces/ITaskFIlters";
import TaskFilterBar from "./Filters/TaskFilterBar";
import { TaskType } from "../Common/Enums/TaskType";
import { Paper } from "@mui/material";
import classNames from "classnames";
import { classes } from "../App.Styles";

const TaskView: React.FC = () => {
  const [instances, setInstances] = useState<ISubjectTasks[]>([]);
  const [filters, setFilters] = useState<ITaskFilters>({
    taskType: TaskType.Manual,
  });
  const [pageSize, setPageSize] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(true);

  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    getInstances();
  }, [pageSize, filters]);

  const { get } = useTasksService();

  const getInstances = async () => {
    setLoading(true);
    const gqlQueryResult = await get(pageSize, filters);
    if (gqlQueryResult.tasks) {
      const tasks = gqlQueryResult.tasks.nodes;
      const processed = await TableTaskHelper.convert(tasks);
      setInstances(processed);
    } else if (
      gqlQueryResult.response?.errors.filter((e) => e.extensions.message)
    ) {
      setError(
        gqlQueryResult.response?.errors
          .map((e) => e.extensions.message)
          .join("\n"),
      );
    } else if (gqlQueryResult.response?.errors) {
      setError(gqlQueryResult.response.errors.map((e) => e.message).join("\n"));
    } else {
      setError("An unknown error ocurred while loading tasks");
    }

    setLoading(false);
  };

  return (
    <Paper
      elevation={4}
      className={classNames(classes.table, classes.tasksWrapper)}
    >
      <div id="task_view">
        {error && (
          <SnackbarAlert
            onClose={() => setError(undefined)}
            colour="error"
            message={error || ""}
          />
        )}
        <Loading visible={loading} />
        <TaskFilterBar
          filters={filters}
          pageSize={pageSize}
          onFiltersChange={setFilters}
          onPageSizeChange={setPageSize}
        />
        <StatusServicesContextProvider>
          {instances.map((row) => (
            <SubjectView
              key={row.subjectId}
              subjectTasks={row}
              onChange={getInstances}
            />
          ))}
        </StatusServicesContextProvider>
      </div>
    </Paper>
  );
};

export default TaskView;
