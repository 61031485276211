import { evaluateXPath } from "fontoxpath";

export const EvaluateXPath = (path: string, data: string): string => {
  let xml: Document = new DOMParser().parseFromString(data, "text/xml");
  if (xml.getElementsByTagName("parsererror").length > 0) {
    xml = new DOMParser().parseFromString("<xml/>", "text/xml");
  }

  let result;
  try {
    result = !path ? "" : evaluateXPath(path, xml);
  } catch {
    //ignore
  }

  try {
    return new XMLSerializer().serializeToString(result);
  } catch {
    return typeof result === "string"
      ? result
      : Array.isArray(result) && result.length === 0
        ? ""
        : JSON.stringify(result);
  }
};
