import React, { useEffect } from "react";
import { Chip, TextField, Tooltip, Autocomplete } from "@mui/material";
import { useState } from "react";
import IEventDefinition from "../../Common/Interfaces/IEventDefinition";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../App.Styles";

interface IProps extends IReadOnlyComponentProps {
  eventDefinitions: IEventDefinition[];
  selectedEvents: string[];
  onChange: (events: string[]) => void;
}

const EventMultiSelect: React.FC<IProps> = ({
  eventDefinitions,
  selectedEvents,
  readOnly,
  onChange,
}) => {
  const [eventMap, setEventMap] = useState<{
    [eventKey: string]: IEventDefinition;
  }>({});
  useEffect(() => {
    const newEventMap: { [eventKey: string]: IEventDefinition } = {};
    eventDefinitions.forEach((eventDefinition) => {
      newEventMap[eventDefinition.eventKey] = eventDefinition;
    });
    setEventMap(newEventMap);
  }, [eventDefinitions]);

  const findEvent = (eventKey: string): IEventDefinition =>
    eventMap[eventKey] ?? { name: "", eventKey: eventKey };

  const handleChange = (_event: any, value: string[]) => {
    onChange(value);
  };

  const eventComponent = (event: IEventDefinition, props?: any) => {
    return event.name ? (
      <div {...props} key={event.eventKey}>
        <span style={{ marginRight: "0.3em" }}>{event.name}</span>
        <span
          style={{ fontFamily: "monospace" }}
          className={classes.mediumGrey}
        >
          {" "}
          ({event.eventKey})
        </span>
      </div>
    ) : (
      <div {...props} key={event.eventKey}>
        <span style={{ fontFamily: "monospace" }}>{event.eventKey}</span>
      </div>
    );
  };

  return (
    <Autocomplete
      id="event-multi-select"
      multiple
      options={eventDefinitions.map((def) => def.eventKey)}
      freeSolo
      disableCloseOnSelect
      filterSelectedOptions
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const event = findEvent(option);
          return (
            <Tooltip
              key={`${event.eventKey}-tooltip`}
              title={event.name ? "Event Definition" : "Event Key"}
            >
              <Chip
                variant="outlined"
                label={eventComponent(event)}
                {...getTagProps({ index })}
                key={`${event.eventKey}-chip`}
              />
            </Tooltip>
          );
        })
      }
      value={selectedEvents}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Events" />
      )}
      renderOption={(props, option) => eventComponent(findEvent(option), props)}
      onChange={handleChange}
      disabled={readOnly}
    />
  );
};

export default EventMultiSelect;
