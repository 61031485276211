import { Chip, Tooltip, styled } from "@mui/material/";
import React from "react";

interface IProps {
  value?: string;
  name: string;
  tooltip: string;
  onClick: (event: any) => void;
  onDelete: (event: any) => void;
}

const StyledChip = styled(Chip)(() => ({
  width: "100%",
  position: "relative",
  "& .MuiChip-deleteIcon": {
    position: "absolute",
    right: 0,
  },
  "& .MuiChip-label": {
    paddingLeft: "25px",
    paddingRight: "25px",
  },
}));

const FilterBase: React.FC<IProps> = ({
  value,
  name,
  tooltip,
  onClick,
  onDelete,
}) => {
  return (
    <Tooltip title={tooltip}>
      <StyledChip
        label={value || <em>{name}</em>}
        variant={"outlined"}
        clickable
        onClick={onClick}
        color={value ? "secondary" : "default"}
        onDelete={value ? onDelete : undefined}
      />
    </Tooltip>
  );
};

export default FilterBase;
