import React from "react";
import { Accordion, AccordionDetails, Grid2 } from "@mui/material";
import ITriggerDefinition from "../../../Common/Interfaces/ITriggerDefinition";
import IUITriggerDefinition from "../../../Common/Interfaces/IUITriggerDefinition";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import AccordionSummaryWithDelete from "../../Delete/AccordionSummaryWithDelete";
import ItemAccordion from "../DefinitionDetail/ItemAccordion";
import TriggerIcon from "@mui/icons-material/PlayCircleOutline";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import TriggerProperties from "./TriggerProperties";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  triggers: IUITriggerDefinition[];
  context: string;
  onChange: (triggers: ITriggerDefinition[]) => void;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
  workflowDefinitionId: string;
  workflowVersion: number;
  parentTaskKey: string;
}

const Triggers: React.FC<IProps> = ({
  triggers,
  onChange,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  workflowDefinitionId,
  workflowVersion,
  parentTaskKey,
  readOnly,
}) => {
  const onAddTrigger = () => {
    triggers.push({
      routingKey: "",
      selectedEvent: "",
      selectedEventKey: "",
      selectedTaskKey: "",
      selectedTriggerVersion: 0,
    });
    onChange(triggers);
  };

  const onTriggerChange = (index: number, trigger: ITriggerDefinition) => {
    triggers[index] = trigger;
    onChange(triggers);
  };

  const onDeleteTrigger = (index: number) => {
    triggers.splice(index, 1);
    onChange(triggers);
  };

  const getExtraText = (trigger: ITriggerDefinition) => {
    if (trigger.selectedEventKey) {
      return trigger.selectedEventKey;
    }
    return "";
  };

  return (
    <ItemAccordion
      name="Triggers"
      count={triggers.length}
      icon={() => <TriggerIcon />}
      onAdd={onAddTrigger}
      readOnly={readOnly}
    >
      {triggers.map((trigger, index) => (
        <Accordion id="trigger" key={index}>
          <AccordionSummaryWithDelete
            name={trigger.routingKey.split(".")[0] ?? ""}
            placeholder="Trigger"
            extraText={getExtraText(trigger)}
            onDelete={() => onDeleteTrigger(index)}
            readOnly={readOnly}
          />
          <AccordionDetails>
            <Grid2
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TriggerProperties
                onTriggerChange={(updated) => onTriggerChange(index, updated)}
                triggerDefinition={trigger}
                workflowDefinitions={workflowDefinitions}
                eventDefinitions={eventDefinitions}
                eventDefinitionGroups={eventDefinitionGroups}
                workflowDefinitionId={workflowDefinitionId}
                workflowVersion={workflowVersion}
                parentTaskKey={parentTaskKey}
                readOnly={readOnly}
              />
            </Grid2>
          </AccordionDetails>
        </Accordion>
      ))}
    </ItemAccordion>
  );
};

export default Triggers;
