import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { HeaderGroup, ColumnInstance } from "react-table";
import { Box, IconButton, TableHead, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Cancel";

export interface IProps {
  display: boolean;
  headerGroups: HeaderGroup<any>[];
  headers: ColumnInstance<any>[];
  fields?: {
    columnId: any;
    placeholderText?: string;
    helperText?: string;
    onChange?: (
      value: string,
      addModel: Record<string, string>,
    ) => Record<string, string>;
  }[];
  validate?: (model: any) => boolean;
  onSave?: (model: any, resetAddRow: () => void) => void;
  onCancel?: (resetAddRow: () => void) => void;
  renderAddDetail?: (
    model: any,
    resetAddRow: () => void,
    validate?: (model: any) => boolean,
  ) => JSX.Element;
  setAddRowDisplay: (value: boolean) => void;
  isExpanded?: boolean;
}
export const TableAddRow: React.FC<IProps> = ({
  display,
  headerGroups,
  fields,
  headers,
  isExpanded,
  onSave,
  onCancel,
  setAddRowDisplay,
  validate,
  renderAddDetail,
}: IProps) => {
  const [addModel, setAddModel] = useState<Record<string, string>>({});
  const [addRowValid, setAddRowValid] = useState<boolean>(false);

  const resetAddRow = () => {
    //Reset the model/display and valid.
    setAddRowDisplay(false);
    setAddRowValid(false);
    const currentState = addModel;
    Object.keys(currentState).forEach((key) => {
      currentState[key] = "";
    });
    setAddModel(currentState);
  };

  //Get only the Id's we need for our inputs.
  const fieldIds = fields?.map((f) => f.columnId) ?? [];

  const onChangeAdd = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    columnId: string,
  ) => {
    let newState: React.SetStateAction<Record<string, string>>;

    const onChange = fields?.find((f) => f.columnId == columnId)?.onChange;
    if (onChange) {
      newState = onChange(event.target.value, addModel);
    } else {
      newState = { ...addModel, [columnId]: event.target.value };
    }

    setAddModel(newState);
    handleValidate(newState);
  };

  const handleValidate = (newState: any) => {
    if (!isExpanded) {
      setAddRowValid((validate ? validate(newState) : null) ?? false);
    }
  };

  const handleOnSave = () => {
    if (onSave) {
      return onSave(addModel, resetAddRow);
    }
  };

  const handleOnCancel = () => {
    if (onCancel) {
      return onCancel(resetAddRow);
    }

    return resetAddRow();
  };

  useEffect(() => {
    //This is done to build up the object model for the values so we can map our input against it.
    const model = {} as any;
    fieldIds.forEach((id) => {
      model[id] = "";
    });
    setAddModel(model);
    handleValidate(model);
  }, []);

  return display ? (
    <TableHead>
      {fieldIds.length > 0 &&
        headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
          //To get the styling for the add field row, we need to create another TableHead and loop through headerGroup to get access to the function of getHeaderProps().
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            style={{
              ...headerGroup.getHeaderGroupProps().style,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
              alignItems: "flex-start",
            }}
            key={`table-add-hg-row-${headerGroupIndex}`}
          >
            {headers.map((column, headerIndex: number) => {
              //Get the placeholder text for the field. Default to empty if not passed in.
              const placeholder =
                fields?.find((f) => f.columnId == column.id)?.placeholderText ??
                "";

              const helperText =
                fields?.find((f) => f.columnId == column.id)?.helperText ?? "";

              return fieldIds.includes(column.id) ? (
                //If the current field we are in, is within the addFieldId's then display input box.
                <TableCell
                  {...column.getHeaderProps()}
                  style={{
                    ...column.getHeaderProps().style,
                    borderBottom: "unset",
                  }}
                  key={`table-add-hg-row-${headerGroupIndex}-cell-${headerIndex}`}
                >
                  <Box display="flex" alignItems="center" height="100%">
                    <TextField
                      id={`TableAddRow_${column.id}`}
                      autoFocus={headerIndex == 0}
                      value={addModel[column.id] || ""}
                      onChange={(event) => onChangeAdd(event, column.id)}
                      placeholder={placeholder}
                      helperText={helperText}
                      variant="standard"
                    />
                  </Box>
                </TableCell>
              ) : headerIndex !== headers.length - 1 ? (
                //If the column isn't the last one, display a empty table cell.
                <TableCell
                  {...column.getHeaderProps()}
                  style={{
                    ...column.getHeaderProps().style,
                    borderBottom: "unset",
                  }}
                  key={`table-add-hg-row-${headerGroupIndex}-cell-${headerIndex}`}
                >
                  <Box display="flex" alignItems="center" height="100%"></Box>
                </TableCell>
              ) : (
                //Otherwise if the column is the last one, use this one as the "actions". This could be improved later so we can pass in the specific Id.
                <TableCell
                  {...column.getHeaderProps()}
                  style={{
                    ...column.getHeaderProps().style,
                    borderBottom: "unset",
                  }}
                  key={`table-add-hg-row-${headerGroupIndex}-cell-${headerIndex}`}
                >
                  <Box display="flex" alignItems="center" height="100%">
                    {!isExpanded && (
                      <span>
                        <span id="check">
                          <IconButton
                            aria-label="Confirm save"
                            onClick={handleOnSave}
                            disabled={!addRowValid}
                          >
                            <DoneIcon
                              style={{
                                color: addRowValid ? green[700] : grey[400],
                              }}
                            />
                          </IconButton>
                        </span>
                        <span>
                          <IconButton
                            onClick={handleOnCancel}
                            aria-label="Cancel save"
                          >
                            <ClearIcon color="error" />
                          </IconButton>
                        </span>
                      </span>
                    )}
                    {isExpanded && fieldIds.length > 0 && (
                      <span>
                        <IconButton
                          onClick={handleOnCancel}
                          aria-label="Cancel save"
                        >
                          <CloseIcon id="test_event_close" />
                        </IconButton>
                      </span>
                    )}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      {isExpanded && !!renderAddDetail ? (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={999}>
            {renderAddDetail(addModel, resetAddRow, validate)}
          </TableCell>
        </TableRow>
      ) : null}
    </TableHead>
  ) : (
    <></>
  );
};
